<template>
    <div class="main2" >
        <div class="rank_title2">
             <!-- <el-col :span="24" class="rank_title_left2">{{data.title}}</el-col> -->
             <el-row>
                <el-col :span="24" class="rank_title_left2">{{data.title}}</el-col>
            </el-row>
        </div>
        <div class="rank_name">
            <el-row>
                <el-col :span="4">
                    <div class="user_type">Web User<div class="square  ycolor"></div></div>
                </el-col>
                <el-col :span="7">
                    <div class="rank_name_title">The total user</div>
                    <div class="total">
                        <countTo :startVal='0' :endVal='data.business.total?data.business.total:0' :duration='1000' separator=","></countTo>
                    </div>
                </el-col>
                <el-col :span="2">&nbsp;</el-col>
                
                <el-col :span="4">
                    <div class="user_type">App User<div class="square  bcolor"></div></div>
                </el-col>
                <el-col :span="7">
                    <div class="rank_name_title">The total user</div>
                    <div class="total">
                        <countTo :startVal='0' :endVal='data.app.total?data.app.total:0' :duration='1000' separator=","></countTo>
                    </div>
                </el-col>
                <!-- <el-col :span="10">
                    <div class="rank_name_title">Monthly increase</div>
                    <div class="total">
                        <countTo :startVal='0' :endVal='data.business.increase?data.business.increase:0' :duration='1000' separator=","></countTo>
                    </div>
                </el-col> -->
                <!-- <el-col :span="6">
                    <div class="rank_name_title">More than</div>
                    <div class="total">
                        <span v-if="data.business.percentage != '-'"><countTo :startVal='0' :endVal='data.business.percentage?data.business.percentage:0' :duration='1000' separator="," :decimals="2" suffix=" %"></countTo>
                        </span>
                        <span v-else>
                            {{data.business.percentage}}
                        </span>
                    </div>
                </el-col> -->
            </el-row>
            <el-row style="margin-top:0.0625rem">
                <!-- 原位置 -->
                <!-- <el-col :span="10">
                    <div class="rank_name_title">Monthly increase</div>
                    <div class="total">
                        <countTo :startVal='0' :endVal='data.app.increase?data.app.increase:0' :duration='1000' separator=","></countTo>
                    </div>
                </el-col> -->
                <!-- <el-col :span="6">
                    <div class="rank_name_title">More than</div>
                    <div class="total">
                        <span v-if="data.app.percentage != '-'">
                            <countTo :startVal='0' :endVal='data.app.percentage?data.app.percentage:0' :duration='1000' separator="," :decimals="2" suffix=" %"></countTo>
                        </span>
                        <span v-else>
                            {{data.app.percentage}}
                        </span>
                    </div>
                </el-col> -->
            </el-row>
        </div>
        <div class="rank_main">
            <el-col :span="24">
                <div id="cylindrical"></div>
            </el-col>
        </div>
        <div class="top2"></div>
        <div class="bottom2"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import { registerLoading } from 'echarts';
import countTo from 'vue-count-to';
export default {
    components: { countTo },
    props:{
        cylindricalData:{
            type:Object,
            default() {
                return {}
            }
        } 
    },
    watch:{
        'cylindricalData':{
            handler: function (newValue, oldValue) {
               this.cylindricalData = newValue;
                this.mapData()
            },
            deep: true
        }
    },
    data() {
        return {
            data:{
                title:" User 用戶",
                app:{
                    total:0,
                    increase:0,
                    percentage:0
                },
                business:{
                    total:0,
                    increase:0,
                    percentage:0
                }
            },
            businessUser:[],
            businessUserXdata:[],
            appUser:[],
            oldData:{},
            oldincrease:0,
            appOldincrease:0,
            myFold:null
        }
    },
    mounted() {
        this.mapData()
    },
    methods:{
        initFold() {
            let that = this;
            let data1 = JSON.parse(JSON.stringify(this.businessUser))
            let data2 = JSON.parse(JSON.stringify(this.appUser))
            var chartFold = document.getElementById('cylindrical');
            if (this.myFold != null && this.myFold != "" && this.myFold != undefined){
                this.myFold.dispose();
            }
            this.myFold  = echarts.init(chartFold);
            var option;
            option = {
                tooltip:{},
                // legend:{
                //     type:'plain',
                //     show:true,
                //     top:'5%',
                //     right:0,
                //     align:'right',
                //     data: ['App User', 'Web User'],
                //     textStyle:{
                //         color:'#fff',
                //         fontSize:10
                //     }
                // },
                grid: {
                    left: '0%',
                    right: '0',
                    bottom: '14%',
                    top:'8%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: that.businessUserXdata,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                    axisLabel:{
                        interval: 0
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:true,
                        lineStyle:{
                            color:'#FFFFFF',
                            opacity:'0.1'
                        },
                    },
                },
                series: [
                    {
                        //[50, 200, 150, 80],
                        name:'App User',
                        type: 'bar',
                        large:true,
                        barWidth : 18,
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: '#28b1ff' // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: '#0072ff' // 0% 处的颜色
                                },
                                // {
                                //     offset: 0.9,
                                //     color: '#000' // 0% 处的颜色
                                // },
                                // {
                                //     offset: 1,
                                //     color: '#000' // 100% 处的颜色
                                // }
                            ],
                            global: false // 缺省为 false
                        },
                        data: data2,
                    },
                    {
                        //[120, 100, 150, 80],
                        type: 'bar',
                        name:'Web User',
                        large:true,
                        barWidth : 18,
                        itemStyle:{
                            opacity: 0.9,
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                {
                                    offset: 0,
                                    color: '#fed701' // 0% 处的颜色
                                },
                                // {
                                //     offset: 0.5,
                                //     color: '#fed701' // 100% 处的颜色
                                // },
                                {
                                    offset: 1,
                                    color: '#e78801', // 0% 处的颜色,
                                },
                                // {
                                //     offset: 1,
                                //     color: '#000' // 100% 处的颜色
                                // }
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        data: data1,
                    }
                ]
            };
            option && this.myFold.setOption(option);
        },
        mapData() {
            this.businessUser = [];
            this.businessUserXdata = [];
            this.appUser = [];
            if(this.cylindricalData.userTotal.data && this.cylindricalData.userTotal.data.length>0) {
                this.cylindricalData.userTotal.data.map(item => {
                    this.businessUser.push(item.userCount)
                    
                })
            }
            if(this.cylindricalData.appuserTotal.data && this.cylindricalData.appuserTotal.data.length>0) {
                this.cylindricalData.appuserTotal.data.map(item => {
                    this.appUser.push(item.userNum)
                    this.businessUserXdata.push(item.date)
                   
                })
            }
            this.appUser = this.fomartData(this.appUser)
            this.businessUser = this.fomartData(this.businessUser)
            this.data.app.total = this.cylindricalData.appuserTotal.userTotal;
            this.data.business.total = this.cylindricalData.userTotal.totalUser;
            setTimeout(()=> {
                this.initFold()
            },1200)
        },
        fomartData(ary){
            let list = []
            for(let i = 0;i<ary.length;i++){
                if(i == 0) {
                    list.push(ary[i])
                }else {
                    list.push(ary[i]+list[i-1])
                }
            }
            return list;
        }
    }
}
</script>
<style scoped>
    .top2{
        top: 0.65rem;
    }
    .main {
        position: relative;
        width: 100%;
        padding: 0.25rem .1125rem .15rem .225rem;
        background-color: rgba(0,0,0,0.4);
        color: #fff;
        border-radius: .1875rem;
        z-index: 10;
        box-sizing: border-box;
    }
    .rank_title{
        font-size: .25rem;
        font-weight: bold;
        line-height: .25rem;
        height: .25rem;
        text-align: left;
    }
    .rank_main {
        padding: 0 .325rem 0.325rem .325rem;
        text-align: left;
        position: relative;
        background-color: rgba(0,0,0,0.2);
        height: 2.65rem;
    }
    .rank_name {
        text-align: center;
        font-size: .25rem;
        background-color: rgba(0,0,0,0.2);
        margin-top: -2px;
        padding: .45rem .325rem 0 .305rem;
    }
    .rank_name_title {
        font-size: .1625rem;
    }
    #cylindrical{
        height: 3rem;
    }
    .total {
        font-size: .1625rem;
    }
    .user_type {
        font-size: .15rem;
        position: relative;
        text-align: left;
    }
    .square {
        width: .15rem;
        height: .15rem;
        position: absolute;
        top: .0125rem;
        right: 0;
    }
    .ycolor{
        background: #FFAF08;
    }
    .bcolor{
        background: #28B1FF;
    }
</style>