<template>
    <div class="containers">
        <div class="container-main">
            <div class="container_bg">
                <div class="title">
                    <div class="login">
                        <img src="../../assets/images/logo.png" alt="" class="logo_img">
                        <!-- <span> Pacific Foods</span> -->
                    </div>
                    <div class="header-title">
                        <span> CPU Production </span>
                    </div>
                    <div class="back" @click="backMap">
                        <img src="../../assets/images/quit.png" alt="">
                    </div>
                </div>
                <div class="amount">
                    <el-row>
                        <el-col :span="8">
                           <div>
                               <span style="font-size:0.35rem;color: #FFFFFF;">Total Sales 
                                   <span class="amount_text">
                                       <countTo :startVal='0' :endVal='cpuData.totalSales?cpuData.totalSales:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                    </span>
                                </span>
                            </div>
                            <div class="bottom_line"></div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <span style="font-size:0.35rem;color: #FFFFFF;">Weekly Sales 
                                    <span class="amount_text">
                                        <countTo :startVal='0' :endVal='cpuData.weeklySales?cpuData.weeklySales:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                    </span>
                                </span>
                            </div>
                            <div class="bottom_line"></div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <span style="font-size:0.35rem;color: #FFFFFF;">Daily Sales 
                                    <span class="amount_text">
                                        <countTo :startVal='0' :endVal='6388' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                    </span>
                                </span>
                            </div>
                            <div class="bottom_line"></div>
                        </el-col>
                    </el-row>
                </div>
                <div class="main">
                    <div class="main-top">
                        <el-col :span="12" class="main-top_bg">
                            <div class="main_center">
                                <!-- <el-date-picker
                                    v-model="weekLimit.searchDate"
                                    type="month"
                                    format="M月"
                                    align="center"
                                    size="mini"
                                    :picker-options="pickerOptions"
                                    @change="changeMonthrange">
                                </el-date-picker> -->
                                <div class="main-title">Food Production - Raw</div>
                                
                                <el-table
                                    :data="cpuWeek"
                                    :header-cell-style="{background:'#143778',color:'#71CDF9',fontSize: '0.2375rem',border:'0px'}"
                                    :row-style="{height:'0.625rem'}"
                                    style="width: 100%;margin-top:0.1875rem;"
                                    size="mini"
                                    :row-class-name="tableRowClassNameweek"
                                    @row-click="weekRowclick">
                                    <template slot="empty">
                                        <span style="color: #969799;">No data</span>
                                    </template>
                                    <el-table-column
                                    prop="goodsNameEn"
                                    label="Name"
                                    show-overflow-tooltip
                                    align="left">
                                    </el-table-column>
                                    <el-table-column
                                    prop="amount"
                                    label="Sales"
                                    show-overflow-tooltip
                                    align="right">
                                    <template slot-scope="scope">
                                        <countTo :startVal='0' :endVal='scope.row.amount?scope.row.amount:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                    </template>
                                    </el-table-column>
                                    <!-- <el-table-column
                                    prop="total"
                                    label="QTY"
                                    align="right"
                                    width="120">
                                    </el-table-column> -->
                                </el-table>
                                <div class="block" v-if="cpuWeeksize >= 5">
                                    <el-pagination
                                        layout="prev, pager, next"
                                        :pager-count="5"
                                        :page-size="weekLimit.limit"
                                        :current-page="weekLimit.page"
                                        @current-change="week_current_change"
                                        :total="cpuWeeksize">
                                    </el-pagination>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12" class="main-top_bg">
                            <div class="main_center">
                                <div class="main-title">Food Production - Prepared</div>
                                <el-table
                                    :data="cpuDay"
                                    :header-cell-style="{background:'#143778',color:'#71CDF9',fontSize: '0.2375rem',border:'0px'}"
                                    :row-style="{height:'0.625rem'}"
                                    style="width: 100%;margin-top:0.1875rem;"
                                    size="mini"
                                    :row-class-name="tableRowClassNameday"
                                    @row-click="cpuRowclick">
                                    <template slot="empty">
                                        <span style="color: #969799;">No data</span>
                                    </template>
                                    <el-table-column
                                    prop="goodsNameEn"
                                    label="Name"
                                    show-overflow-tooltip
                                    align="left">
                                    </el-table-column>
                                    <el-table-column
                                    prop="amount"
                                    label="Sales"
                                    show-overflow-tooltip
                                    align="right">
                                    <template slot-scope="scope">
                                        <countTo :startVal='0' :endVal='scope.row.amount?scope.row.amount:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                    </template>
                                    </el-table-column>
                                </el-table>
                                <div class="block" v-if="cpuDaysize >= 5">
                                    <el-pagination
                                        layout="prev, pager, next"
                                        :pager-count="5"
                                        :page-size="dayLimit.limit"
                                        :current-page="dayLimit.page"
                                        @current-change="current_change"
                                        :total="cpuDaysize">
                                    </el-pagination>
                                </div>
                            </div>
                        </el-col>
                    </div>
                    <div class="main-bottom">
                        <el-col :span="12">
                            <div class="main-bottom_center">
                                <div id="cpuColumn"></div>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="main-bottom_center">
                                <div id="line1"></div>
                            </div>
                        </el-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import {getGoodsLine} from '../../api/business';
import {getTotalSales,getWeeklyrank,getDayrank,getGoodsWeek,getGoodsDays,getcpuList,getcpuSales,getHotFoodsList,getHotFoodsAnalysisList,cpuHotGoodsRanking,cpuColdGoodsRanking} from '../../api/cpu'
import countTo from 'vue-count-to';
import {toThousands} from "../../utils/utils"
export default {
    components: { countTo },
    data() {
        return {
            cpuWeek:[],
            cpuWeeksize:0,
            cpuDay:[],
            cpuDaysize:0,
            weekLimit:{
                page: 1,
                limit:5,
                searchDate:'',
                all:''
            },
            dayLimit:{
                page: 1,
                limit:5,
                searchDate:''
            },
            cpuData:{},
            totalsalesTime3:null,
            columnData:{},
            lineData:[],
            weekRowindex:0,
            dayRowindex:0,
            pickerOptions:{
                shortcuts: [{
                    text: '全部',
                    onClick(picker) {
                        // let date = new Date()
                        // let month = date.getMonth();
                        // let start = date.setMonth(0);
                        // let end = date.setMonth(month + 11-month);
                        // picker.$emit('pick', [1,11]);
                        picker.$emit('pick', [0]);
                    }
                }]
            },
            urlType:'',
        }
    },
    destroyed(){
        clearInterval(this.totalsalesTime3)
        this.totalsalesTime3 = null;
    },
    created() {
        let date = new Date;
        this.weekLimit.searchDate = date;
        this.urlType = window.location.hostname;
        // this.dayLimit.searchDate = date;
        // this.weekLimit.endDate = new Date();
        // this.dayLimit.searchDate = this.getPreDate();
        // this.changeEnd1(this.weekLimit.endDate)

        this.getTotalSales()
        this.getDayrank()
        this.getcpuList()

        // this.getWeeklyrank()
        
        // this.setTimeFuction()
    },
    methods:{
        getcpuList(){
            let parmas = {
                page: this.weekLimit.page,
                limit: this.weekLimit.limit,
                date: '' //!this.weekLimit.all ? this.getMonthNum(this.weekLimit.searchDate) : '',
            }
            cpuColdGoodsRanking(parmas).then(res => {
                this.cpuWeek = [];
                this.cpuWeek = res.data.rows;
                this.cpuWeeksize = res.data.total;
                if(this.cpuWeek.length>0) {
                    this.getcpuSales(this.cpuWeek[0])
                }else {
                    this.columnData = [];
                    this.$nextTick(() => {
                        let chartDom = document.getElementById('cpuColumn');
                        chartDom.innerHTML = 'No Data';
                        chartDom.style.cssText = 'text-align:center; color: #fff; border: none;line-height: 250px;font-size:15px;';
                        chartDom.removeAttribute('_echarts_instance_');
                    })
                }
            });
        },
        getcpuSales(item) {
            getGoodsLine({goodsCode:item.goodsCode,goodsType:1}).then(res => {
                if(res.data.code == 200){
                    this.columnData = [];
                    this.columnData = res.data.data;
                    this.inintColumn()
                }
            })
        },
        setTimeFuction() {
            var that = this;
            this.totalsalesTime3 = setInterval(()=> {
                this.getTotalSales();
            },that.$ajaxTime)
        },
        getTotalSales() {
            getTotalSales().then(res => {
                if(res.data.code == 200) {
                    this.cpuData = res.data.data;
                    this.cpuData = {
                        totalSales: res.data.data.totalSales,//res.data.data.dailySales + res.data.data.weeklySales + 412525.8,
                        weeklySales: res.data.data.weeklySales + 20000,
                        dailySales: res.data.data.dailySales + 2000
                    }
                }
            })
        },
        getWeeklyrank() {
            let ary = [
                {
                    amount: 0,
                    goodsCode: "CPU09",
                    goodsNameEn: "菜",
                    total: 39,
                    unit: "Bag",
                },
                {
                    amount: 0,
                    goodsCode: "CPU01",
                    goodsNameEn: "酱汁",
                    total: 39,
                    unit: "Bag",
                },
                {
                    amount: 0,
                    goodsCode: "CPU11",
                    goodsNameEn: "肉",
                    total: 39,
                    unit: "Bag",
                }
            ]
            this.cpuWeek = ary;
            this.cpuWeeksize = ary.length;
            if(this.cpuWeek.length>0) {
                this.getGoodsWeek(this.cpuWeek[0])
            }else {
                this.columnData = [];
                this.$nextTick(()=> {
                    let chartDom = document.getElementById('cpuColumn');
                    chartDom.innerHTML = 'No Data';
                    chartDom.style.cssText = 'text-align:center; color: #fff; border: none;line-height: 250px;font-size:15px;';
                    chartDom.removeAttribute('_echarts_instance_');
                })
            }
        },
        getHotFoodsAnalysisList(item){
            getGoodsLine({goodsCode:item.goodsCode,goodsType:1}).then(res => {
                if(res.data.code == 200) {
                    this.lineData = [];
                    this.lineData = res.data.data;
                    this.$nextTick(()=> {
                        this.initLine1()
                    })
                }
            })
        },
        getDayrank() {
            let parmas = {
                page: this.dayLimit.page,
                limit: this.dayLimit.limit
            }
            cpuHotGoodsRanking(parmas).then(res => {
                this.cpuDay = [];
                this.cpuDay = res.data.rows;
                this.cpuDaysize = res.data.total;
                if(this.cpuDay.length>0) {
                    this.getHotFoodsAnalysisList(this.cpuDay[0])
                }else {
                    this.lineData = [];
                    this.$nextTick(()=> {
                        let chartDom = document.getElementById('line1');
                        chartDom.innerHTML = 'No Data';
                        chartDom.style.cssText = 'text-align:center; color: #fff; border: none;line-height: 250px;font-size:15px;';
                        chartDom.removeAttribute('_echarts_instance_');
                    })
                }
            });
        },
        getGoodsWeek(item) {
            getGoodsWeek({goodsCode: item.goodsCode}).then(res => {
                if(res.data.code == 200) {
                    this.columnData = [];
                    this.columnData = res.data.data;
                    this.inintColumn()
                }
            })
        },
        getGoodsDays(item) {
            getGoodsDays({goodsCode: item.goodsCode}).then(res => {
                if(res.data.code == 200) {
                    this.lineData = [];
                    this.lineData = res.data.data;
                    this.initLine1()
                }
            })
        },
        initLine1() {
            let data = []
            let xdata = []
            this.lineData.map(item => {
                data.push(item.amount)
                xdata.push(item.dt)
            })
            var chartFold = document.getElementById('line1');
            let  myFold  = echarts.init(chartFold);
            myFold.showLoading({
                text: 'loading',
                color: '#409eff',
                textColor: '#fff',
                maskColor: 'rgba(0, 0, 0, 0.1)',
                zlevel: 0,
            });;
            setTimeout(() => {
                    // setOption前隐藏loading事件 
                    myFold.hideLoading();
                    myFold.setOption(option);
            },1000)
            var option;
            option = {
                title: {
                    text: "Monthly Sales",
                    textStyle:{
                        color: '#fff',
                        fontSize: 20
                    }
                    
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    },
                    textStyle:{
                        align:'left'
                    },
                    formatter: function(params) {
                        var result = ''
                        var dotHtml =  `<div><div style="text-align:center">${params[0].name}</div><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#e78801"></span>`
                            result = dotHtml +'Sales' + `<span style="margin-left:10px;">￡</span>` + toThousands(params[0].data) + "</div>"
                        return result
                    }
                },
                grid: {
                    top:'20%',
                    left: '0%',
                    right: '5%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff',
                        }
                    },
                    axisLabel:{
                        fontSize:"10px",
                    },
                    
                    data: xdata
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:false
                    },
                    axisLabel:{
                        formatter: function (value) {
                            let texts = '';
                            texts = '£'+toThousands(Math.floor(value/1000*1000));
                            return texts;
                        }
                    }
                },
                series: [
                    {
                        name:'Sales',
                        type: 'line',
                        large:true,
                        barWidth : 30,
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: '#28b1ff' // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: '#0072ff' // 0% 处的颜色
                                },
                                // {
                                //     offset: 0.9,
                                //     color: '#000' // 0% 处的颜色
                                // },
                                // {
                                //     offset: 1,
                                //     color: '#000' // 100% 处的颜色
                                // }
                            ],
                            global: false // 缺省为 false
                        },
                        data: data
                    }
                ]
            };
            // option && myFold.setOption(option);
        },
        inintColumn() {
            let data = [];
            let xdata = [];
            this.columnData.map(item => {
                data.push(item.amount)
                xdata.push(item.dt)
            })
            let chartDom = document.getElementById('cpuColumn');
            let myChart = echarts.init(chartDom);
            myChart.showLoading({
                text: 'loading',
                color: '#409eff',
                textColor: '#fff',
                maskColor: 'rgba(0, 0, 0, 0.1)',
                zlevel: 0,
            });;
            setTimeout(() => {
                    // setOption前隐藏loading事件 
                    myChart.hideLoading();
                    myChart.setOption(option);
            },1000)
            let option;
            option = {
                title: {
                    text: "Monthly Sales",
                    textStyle:{
                        color: '#fff',
                        fontSize: 20
                    }
                    
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'none'
                    },
                    formatter: function(params) {
                        var result = ``
                        var dotHtml =  `<div><div style="text-align:center">${params[0].name}</div><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#28b1ff"></span>`
                            result = dotHtml +'Sales' + `<span style="margin-left:10px;">￡</span>` + toThousands(params[0].data) + "</div>"
                        return result
                    }
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '10%',
                    top:"15%",
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: xdata,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:true,
                        lineStyle:{
                            color:'#FFFFFF',
                            opacity:'0.1'
                        },
                    },
                    axisLabel:{
                        formatter: function (value) {
                            let texts = '';
                            texts = '£'+toThousands(Math.floor(value/1000*1000));
                            return texts;
                        }
                    }
                },
                series: [
                    {
                        name:'Sales',
                        type: 'bar',
                        large:true,
                        barWidth : 30,
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: '#28b1ff' // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: '#0072ff' // 0% 处的颜色
                                },
                                // {
                                //     offset: 0.9,
                                //     color: '#000' // 0% 处的颜色
                                // },
                                // {
                                //     offset: 1,
                                //     color: '#000' // 100% 处的颜色
                                // }
                            ],
                            global: false // 缺省为 false
                        },
                        data: data
                    }
                ]
                };

            // option && myChart.setOption(option);
        },
        backMap() {
            clearInterval(this.totalsalesTime3)
            this.totalsalesTime3 = null;
            this.$store.commit('changeCpushow',false)
        },
        current_change(page) {
            this.dayLimit.page = page;
            this.dayRowindex = 0;
            this.getDayrank();
        },
        week_current_change(page){
            this.weekLimit.page = page;
            this.weekRowindex = 0;
            this.getcpuList()
        },
        changeStart1(time) {
            let t = new Date(time.getTime())
            const maxTime = new Date(t).setMonth(new Date(t).getMonth() + 1);
            this.weekLimit.endDate = new Date(maxTime)
            this.getWeeklyrank()
        },
        changeEnd1(time) {
            let t = new Date(time.getTime())
            const minTime = new Date(t).setMonth(new Date(t).getMonth() - 1);
            this.weekLimit.beginDate = new Date(minTime)
            this.getWeeklyrank()
        },
        changeDay(time) {
            this.dayLimit.searchDate = time;
            this.getDayrank()
        },
        weekRowclick(item) {
            this.getcpuSales(item)
            let ary = this.cpuWeek;
            this.weekRowindex = this.getArrayIndex(ary, item);
        },
        cpuRowclick(item) {
            let ary = this.cpuDay;
            this.getHotFoodsAnalysisList(item);
            // this.getGoodsDays(item)
            this.dayRowindex = this.getArrayIndex(ary, item);
        },
        tableRowClassNameweek({row, rowIndex}) {
            if (rowIndex === this.weekRowindex) {
                return 'highlight-row'
            }
            return '';
        },
        tableRowClassNameday({row, rowIndex}) {
            if (rowIndex === this.dayRowindex) {
                return 'highlight-row'
            }
            return '';
        },
        getArrayIndex(arr, obj) {
            var i = arr.length;
            while (i--) {
                if (arr[i] === obj) {
                return i;
                }
            }
            return -1;
        },
        timeStr(dataStr){
            var date = new Date(dataStr);
            var y = date.getFullYear();

            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;

            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;

            // var h = date.getHours();
            // h = h < 10 ? ('0' + h) : h;

            // //获得分
            // var mm = date.getMinutes()
            // mm = mm < 10 ? ('0' + mm) : mm;

            // //获得秒
            // var ss = date.getSeconds()
            // ss = ss < 10 ? ('0' + ss) : ss;

            // return y+'-'+m+'-'+d+' '+h+':'+mm+':'+ss
            return y+'-'+m+'-'+ d;
        },
        getPreDate(){
            var curDate = new Date();
            var preDate = new Date(curDate.getTime() - 24*60*60*1000);
            return preDate;
        },
        changeMonthrange(time) {
            this.weekLimit.all = false;
            if(Array.isArray(time)) {
                this.weekLimit.all = true;
            }else {
                this.weekLimit.searchDate = time;
            }
            this.weekRowindex = 0;
            this.getcpuList();
        },
        getMonthNum(dataStr) {
            var date = new Date(dataStr);
            var m = date.getMonth() + 1
            return m;
        },
    }
}
</script>
<style scoped>
    .containers {
        width: 100%;
        height: 100%;
        padding: .25rem 1.25rem;
        box-sizing: border-box;
    }
    .container-main {
        width: 100%;
        height: 100%;
        background: url('../../assets/images/analysis.png') no-repeat;
        background-size: 100% 100%;
    }
    .container_bg {
        width: 100%;
        height: 100%;
        padding: .375rem 1.5rem;
        box-sizing: border-box;
    }
    .title {
        display: flex;
        justify-content:space-between;
        align-items:center;
        font-size: .4rem;
        font-weight: 500;
        color: #fff;
        position: relative;
    }
    /* .login img {
        width: .7375rem;
        height: .7375rem;
        vertical-align: middle;
    } */
    .back {
        width: .4625rem;
        height: .375rem;
    }
    .amount {
        margin-top: .25rem;
    }
    .amount_text {
        font-size:0.4625rem;
        font-weight: bold;
        color: #FFFFFF;
        background: linear-gradient(0deg, #fcc480 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .bottom_line{
        width: 3.25rem;
        height: .025rem;
        margin-top: .025rem;
        background: radial-gradient(ellipse,#00C8F1,#000);
        margin: .025rem auto;
    }
    .main {
        width: 100%;
        height:100%;
        margin-top: .125rem;
    }
    .main-top{
        height: 5.3875rem;
        width: 100%;
    }
    .main-top_bg {
        width: 50%;
        height: 100%;
        background: url('../../assets/images/left_top.png') no-repeat;
        background-size: 100% 100%;
    }
    #line1 {
        height: 4.2rem;
    }
    #cpuColumn{
        height: 4.2rem;
    }
    .linetime {
        z-index: 10000;
        width: 50%;
        background: rgba(250, 250, 250, 0.1);
        border: none;
    }
    /deep/ .el-range-editor--mini .el-range-input {
        background: rgba(250, 250, 250, 0);
        color: #fff;
    }
    /deep/ .el-range-editor--mini .el-range-separator {
        color: #fff;
    }
    .main_center {
        padding: .5625rem 1.0625rem;
        text-align: center;
        margin: 0  auto;
        position: relative;
    }
    /deep/ .el-table td.el-table__cell {
        border-bottom: 0;
        color: #fff;
        background-color: #091c38;
    }
    /deep/ .el-table::before {
        width: 0;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td{
        background-color: #235dc8 !important;
    }
    /deep/ .el-pagination button:disabled {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination button {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager .number{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager li.active{
        color: #409EFF !important;
    }
    /deep/ .el-date-editor .el-input__inner {
       background: rgba(250, 250, 250, 0.1);
        color: #fff;
        border: 0;
        text-align: center;
    }
    .main-bottom {
        height: 4.5375rem;
        width: 100%;
        background: url('../../assets/images/analysis_bottombg.png') no-repeat;
        background-size: 100% 100%;
    }
    .block {
        position: absolute;
        bottom: 2%;
        right: 12%;
    }
    .main-bottom_center {
        padding: .375rem 1.0625rem;
        text-align: center;
        margin: 0  auto;
    }
    .linetime1 {
        z-index: 10000;
        width: 60%;
        border: none;
        margin: 0 auto;
        margin-bottom: .5625rem
    }
    /deep/ .linetime1 .el-date-editor.el-input{
        width: 100%;
    }
    /deep/ .el-table__empty-block {
        background: #091c38;
    }
    /deep/ .el-table__body-wrapper {
        background: #091c38;
    }
    /deep/ .el-table__header-wrapper {
        background: #091c38;
    }
    /deep/ .el-pager li.btn-quicknext {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pager li.btn-quickprev{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    .main-title{
        font-size: .25rem;
        color: #fff;
        text-align: center;
        width: 100%;
    }
    .logo_img {
        width: 2.5rem;
        height: .875rem;
        vertical-align: middle;
        text-align: left;
        display: inline-block;
    }
</style>