<template>
    <div>
        <div class="container-main">
            <div class="container_bg">
                <div class="title">
                    <div class="login">
                        <img src="../../assets/images/logo.png" alt="" class="logo_img">
                    </div>
                    <div class="back" @click="backMap">
                        <img src="../../assets/images/quit.png" alt="">
                    </div>
                </div>
                <div class="main">
                    <div class="main-top1">
                        <el-col :span="24">
                            <div class="main_center">
                                <el-table
                                    ref="table3"
                                    :data="businessWeek"
                                    :header-cell-style="{background:'#143778',color:'#71CDF9',fontSize: '0.2375rem',border:'0px'}"
                                    :row-style="{height:'0.625rem'}"
                                    style="width: 100%;margin-top:0.1875rem;"
                                    @row-click="weekRowclick"
                                    height="3.76rem"
                                    :row-class-name="tableRowClassNameweek"
                                    :default-sort="{prop: 'sales', order: 'descending'}"
                                    @sort-change="sortFun"
                                    size="mini">
                                    <template slot="empty">
                                        <span style="color: #969799;">No data</span>
                                    </template>
                                    <el-table-column
                                    prop="name"
                                    label="Customer Name"
                                    show-overflow-tooltip
                                    align="left"
                                    min-width="35%">
                                    </el-table-column>
                                    <el-table-column
                                    prop="sales"
                                    label="Sales"
                                    show-overflow-tooltip
                                    align="right"
                                    sortable="custom"
                                    min-width="35%">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.sales != '--'">
                                            <countTo :startVal='0' :endVal='scope.row.sales?scope.row.sales:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                        </div>
                                        <div v-else></div>
                                        
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    prop="zipcode"
                                    label="Postcode"
                                    align="right"
                                    min-width="30%">
                                    </el-table-column>
                                </el-table>
                                <div class="block" v-if="businessWeeksize >= 5">
                                    <el-pagination
                                        layout="prev, pager, next"
                                        :pager-count="5"
                                        :page-size="weekLimit.limit"
                                        :current-page="weekLimit.page"
                                        @current-change="week_current_change"
                                        :total="businessWeeksize">
                                    </el-pagination>
                                </div>
                            </div>
                        </el-col>
                    </div>
                    <div class="main-bottom">
                        <el-col :span="24">
                            <div class="main-bottom_center">
                                <div id="cpuColumn"></div>
                            </div>
                        </el-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';

import {getCosmoSales,getCosmoSixMonth} from '../../api/business'
import countTo from 'vue-count-to';
import {toThousands} from "../../utils/utils"
export default {
    components: { countTo },
    data() {
        return {
            businessWeek:[],
            businessWeeksize:0,
            weekLimit:{
                page: 1,
                limit:5,
                userCode:'',
                sortSales: 2
            },
            columnData:{},
            storeAmount:{
                sum7Day:"",
                sumAll:""
            },
            weekRowindex:0,
            myChart:null,
            domCos:''
        }
    },
    mounted(){
        
        this.initFunction()
        let chartDom = document.getElementById('cpuColumn');
        this.myChart = echarts.init(chartDom);
        // window.addEventListener('resize',() => {this.resize()})
    },
    methods:{
        tableScrollCosmo(){
            // 获取需要绑定的table
            this.domCos = this.$refs.table3.bodyWrapper
            this.domCos.addEventListener('scroll', () => {
                // 表格滚动条滚动的距离
                let scrollTop = this.domCos.scrollTop;
                let tabHeight = 0;
                for (let i = 0; i < this.businessWeek.length; i++) {
                    this.businessWeek[i].top = tabHeight;
                    tabHeight = tabHeight + document.getElementsByClassName('anchorN'+this.businessWeek[i].pageNum+this.businessWeek[i].counts)[0].offsetHeight;
                    this.businessWeek[i].bottom = tabHeight;
                }
                let scrollTops = scrollTop+(document.getElementsByClassName('anchorN'+this.businessWeek[0].pageNum+this.businessWeek[0].counts)[0].offsetHeight*3);
                
                for (let j = 0; j < this.businessWeek.length; j++) {
                    if (scrollTops > this.businessWeek[j].top && scrollTops < this.businessWeek[j].bottom) {
                        this.weekLimit.page = this.businessWeek[j].pageNum;
                    }
                }
            })
        },
        initFunction() {
            this.getCosmoSales();
        },
        //business
        getCosmoSales() {
            getCosmoSales(this.weekLimit).then(res => {
                if(res.data.code ==1) {

                    let datas = res.data.data;
                    let pages = 0;
                    for(let i =0;i<datas.length;i++){
                        if(i%5 == 0){
                            pages++;
                        }
                        datas[i].pageNum = pages;
                        datas[i].counts = i%5;
                    }
                    

                    this.businessWeek = datas;
                    this.businessWeeksize = datas.length;
                    let countNum = this.businessWeek[this.businessWeek.length-1].counts+1;
                    for(let n = 0;n<5-countNum;n++){
                        this.businessWeek.push({name:'',sales:'--',zipcode:'',pageNum:pages,counts:countNum+n});
                    }
                    this.tableScrollCosmo();
                    if(this.businessWeek.length>0) {
                        this.getCosmoSixMonth(this.businessWeek[0])
                    }else{
                        this.columnData = [];
                        this.$nextTick(()=> {
                            let chartDom = document.getElementById('cpuColumn');
                            chartDom.innerHTML = 'No Data';
                            chartDom.style.cssText = 'text-align:center; color: #fff; border: none;line-height: 250px;font-size:15px;';
                            chartDom.removeAttribute('_echarts_instance_');
                        })
                    }
                }
            })  
        },
        sortFun(item){
            if(item.order == "descending"){
                this.weekLimit.sortSales = 2;
            }else{
                this.weekLimit.sortSales = 1;
            }
            this.domCos.scrollTop = 0;
            this.weekLimit.page = 1;
            this.weekRowindex = 0;
            this.getCosmoSales();
        },
        weekRowclick(item) {
            this.getCosmoSixMonth(item)
            let ary = this.businessWeek.slice((this.weekLimit.page-1)*this.weekLimit.limit,this.weekLimit.page*this.weekLimit.limit)
            this.weekRowindex = this.getArrayIndex(ary, item);
        },
        getCosmoSixMonth(item) {
            getCosmoSixMonth({companyId: item.companyId}).then(res => {
                if(res.data.code == 1) {
                    this.columnData = [];
                    this.columnData = res.data.data;
                    this.inintColumn()
                }
            })
        },
        week_current_change(page){
            this.weekLimit.page = page;
            let PageId = document.querySelector('.anchorN' + page +'0');
            setTimeout(() => {
                this.domCos.scrollTop = PageId.offsetTop;
            },10);
            for(let i =0;i<this.businessWeek.length;i++){
                if(page == this.businessWeek[i].pageNum && this.businessWeek[i].counts == 0){
                    this.weekRowindex = i;
                }
            }
            this.getCosmoSixMonth(this.businessWeek[this.weekRowindex])
        },
        inintColumn() {
            let data = [];
            let xdata = [];
            this.columnData.map(item => {
                if(item.sales){
                    data.push(item.sales)
                    xdata.push(item.months)
                }
            })
            this.myChart.showLoading({
                text: 'loading',
                color: '#409eff',
                textColor: '#fff',
                maskColor: 'rgba(0, 0, 0, 0.1)',
                zlevel: 0,
            });;
            setTimeout(() => {
                    // setOption前隐藏loading事件 
                   this. myChart.hideLoading();
                    this.myChart.setOption(option);
            },1000)
            let option;
            option = {
                title: {
                    text: "Monthly Sales",
                    textStyle:{
                        color: '#fff',
                        fontSize: 20
                    }
                    
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'none'
                    },
                    formatter: function(params) {
                        var result = ``
                        var dotHtml =  `<div><div style="text-align:center">${params[0].name}</div><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#28b1ff"></span>`
                            result = dotHtml +'Sales' + `<span style="margin-left:10px;">￡</span>` + toThousands(params[0].data) + "</div>"
                        return result
                    }
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '10%',
                    top:"15%",
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: xdata,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:true,
                        lineStyle:{
                            color:'#FFFFFF',
                            opacity:'0.1'
                        },
                    },
                    axisLabel:{
                        formatter: function (value) {
                            let texts = '';
                            texts = '£'+toThousands(Math.floor(value/1000*1000));
                            return texts;
                        }
                    }
                },
                series: [
                    {
                        name:'Sales',
                        type: 'bar',
                        large:true,
                        barWidth : 30,
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: '#28b1ff' // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: '#0072ff' // 0% 处的颜色
                                },
                                // {
                                //     offset: 0.9,
                                //     color: '#000' // 0% 处的颜色
                                // },
                                // {
                                //     offset: 1,
                                //     color: '#000' // 100% 处的颜色
                                // }
                            ],
                            global: false // 缺省为 false
                        },
                        data: data
                    }
                ]
                };

            // option && myChart.setOption(option);
        },
        //返回
        backMap() {
            this.$emit("ParentType",'main');
        },
        timeStr(dataStr){
            var date = new Date(dataStr);
            var y = date.getFullYear();

            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;

            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;

            // var h = date.getHours();
            // h = h < 10 ? ('0' + h) : h;

            // //获得分
            // var mm = date.getMinutes()
            // mm = mm < 10 ? ('0' + mm) : mm;

            // //获得秒
            // var ss = date.getSeconds()
            // ss = ss < 10 ? ('0' + ss) : ss;

            // return y+'-'+m+'-'+d+' '+h+':'+mm+':'+ss
            return y+'-'+m+'-'+ d;
        },
        tableRowClassNameweek({row, rowIndex}) {
            if (rowIndex === this.weekRowindex) {
                return 'highlight-row'+ ' '+ 'anchorN'+ row.pageNum + row.counts
            }
            if(row.sales == "--"){
                return 'blank_sty'+ ' '+ 'anchorN'+ row.pageNum + row.counts
            }
            return 'anchorN'+ row.pageNum + row.counts;
        },
        getArrayIndex(arr, obj) {
            var i = arr.length;
            while (i--) {
                if (arr[i] === obj) {
                return i;
                }
            }
            return -1;
        },
        resize() {
            this.myChart.resize({height:'4.2rem'})
        }
    }
}
</script>
<style scoped>
    .containers {
        width: 100%;
        height: 100%;
        padding: .25rem 1.25rem;
        box-sizing: border-box;
    }
    .container-main {
        width: 100%;
        height: 100%;
        background: url('../../assets/images/analysis.png') no-repeat;
        background-size: 100% 100%;
    }
    .container_bg {
        width: 100%;
        height: 100%;
        padding: .375rem 1.5rem;
        box-sizing: border-box;
    }
    .title {
        display: flex;
        justify-content:space-between;
        align-items:center;
        font-size: .4rem;
        font-weight: 500;
        color: #fff;
    }
    /* .login img {
        width: .7375rem;
        height: .7375rem;
        vertical-align: middle;
    } */
    .back {
        width: .4625rem;
        height: .375rem;
    }
    .amount {
        margin-top: .25rem;
    }
    .amount_text {
        font-size:0.4625rem;
        font-weight: bold;
        color: #FFFFFF;
        background: linear-gradient(0deg, #fcc480 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .bottom_line{
        width: 3.25rem;
        height: .025rem;
        margin-top: .025rem;
        background: radial-gradient(ellipse,#00C8F1,#000);
        margin: .025rem auto;
    }
    .main {
        width: 100%;
        height:100%;
        margin-top: .125rem;
    }
    .main-top{
        height: 5.3875rem;
        width: 100%;
    }
    .main-top1 {
        height: 5.3875rem;
        width: 100%;
        background: url('../../assets/images/analysis_bottombg.png') no-repeat;
        background-size: 100% 100%;
    }
    .main-top_bg {
        width: 50%;
        height: 100%;
        background: url('../../assets/images/left_top.png') no-repeat;
        background-size: 100% 100%;
    }
    #line1 {
        height: 4.2rem;
    }
    #cpuColumn{
        height: 4.2rem;
    }
    .linetime {
        z-index: 10000;
        width: 50%;
        background: rgba(250, 250, 250, 0.1);
        border: none;
    }
    /deep/ .el-range-editor--mini .el-range-input {
        background: rgba(250, 250, 250, 0);
        color: #fff;
    }
    /deep/ .el-range-editor--mini .el-range-separator {
        color: #fff;
    }
    .main_center {
        padding: .5625rem 1.0625rem;
        text-align: center;
        margin: 0  auto;
        position: relative;
    }
    /deep/ .el-table td.el-table__cell {
        border-bottom: 0;
        color: #fff;
        background-color: #091c38;
    }
    /deep/ .el-table::before {
        width: 0;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td{
        background-color: #235dc8;
    }
    /deep/ .el-table--enable-row-hover .el-table__body .blank_sty:hover>td{
        background-color: #091c38;
    }
    /deep/ .el-pagination button:disabled {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination button {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager .number{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager li.active{
        color: #409EFF !important;
    }
    /deep/ .el-date-editor .el-input__inner {
       background: rgba(250, 250, 250, 0.1);
        color: #fff;
        border: 0;
        text-align: center;
    }
    .main-bottom {
        height: 4.5375rem;
        width: 100%;
        background: url('../../assets/images/analysis_bottombg.png') no-repeat;
        background-size: 100% 100%;
    }
    .block {
        position: absolute;
        bottom: 0;
        right: 12%;
    }
    .main-bottom_center {
        padding: .375rem 1.0625rem;
        text-align: center;
        margin: 0  auto;
    }
    .linetime1 {
        z-index: 10000;
        width: 60%;
        border: none;
        margin: 0 auto;
        margin-bottom: .5625rem
    }
    /deep/ .linetime1 .el-date-editor.el-input{
        width: 100%;
    }
    /deep/ .el-table__empty-block {
        background: #091c38;
    }
    /deep/ .el-table__body-wrapper {
        background: #091c38;
    }
    /deep/ .el-table__header-wrapper {
        background: #091c38;
    }
    /deep/ .el-pager li.btn-quicknext {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pager li.btn-quickprev{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    .logo_img {
        width: 2.5rem;
        height: .875rem;
        vertical-align: middle;
        text-align: left;
        display: inline-block;
    }
</style>