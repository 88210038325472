<template>
    <div class="main2" >
        <div class="rank_title2">
            <el-row>
                <el-col :span="24" class="rank_title_left2">{{data.title}}</el-col>
            </el-row>
        </div>
        <div class="rank_main2">
            <el-col :span="12">
                <div id="fold"></div>
            </el-col>
            <el-col :span="12">
                <div id="pie"></div>
            </el-col>
            <div class="top2"></div>
            <div class="bottom2"></div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import { color } from 'echarts';
export default {
    props:{
        costStatistics:{
            type:Object,
            default() {
                return {}
            }
        }
    },
    watch:{
        'costStatistics':{
            handler: function (newValue, oldValue) {
               this.costStatistics = newValue;
                this.initFold()
                this.initPie()
            },
            deep: true
        }
    },
    data() {
        return {
            data:{
                title:"Takeaway Yield 門店收益",
                myPie:null,
                myFold:null
            },
            urlType:''
        }
    },
    created(){
        this.urlType = window.location.hostname;
        if(this.urlType != 'www.foodo2o.com') {
            this.data.title  = 'ANNALYSIS OF FRANCHIS TAKEAWAY';
        }
    },
    mounted() {
        setTimeout(() => {
            this.initFold()
            this.initPie()
        },1000)
    },
    methods:{
        initFold() {
            var chartFold = document.getElementById('fold');
            if (this.myFold != null && this.myFold != "" && this.myFold != undefined){
                this.myFold.dispose();
            }
            this.myFold  = echarts.init(chartFold);
            var option;
            option = {
                title: {
                    text: 'Financial',
                    textStyle:{
                        color: '#fff',
                        fontSize: 14
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                grid: {
                    left: '3%',
                    right: '8%',
                    bottom: '2%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff',
                        }
                    },
                    axisLabel:{
                        fontSize:"0.125rem",
                        interval: 0
                    },
                    data: ['Week 1', 'Week 2', 'Week 3', 'Week 4']
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:false
                    },
                },
                dataZoom:{
                    type:'inside'
                },
                series: [
                    {
                        data: this.costStatistics.ebithaData,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2, 
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#e78801' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#e78801', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"ebitda",
                        // stack: 'Total',
                        showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#e78801' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#e78801', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        
                    },
                    {
                        data: this.costStatistics.labourData,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2,
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#28b1ff' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#0072ff', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"labour",
                        // stack: 'Total',
                        showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#28b1ff' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#0072ff', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        }
                    },
                    {
                        data:  this.costStatistics.foodData,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2,
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#28b1ff' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#0072ff', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"food",
                        // stack: 'Total',
                        showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#2C6BF8' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#6B12CC', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        }
                    }
                ]
            };
            option && this.myFold.setOption(option);
        },
        initPie() {
            var chartPie = document.getElementById('pie');
            if (this.myPie != null && this.myPie != "" && this.myPie != undefined){
                this.myPie.dispose();
            }
            this.myPie  = echarts.init(chartPie);
            var option;
            option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '1%',
                    left: 'center',
                    itemWidth:5,
                    itemHeight:5,
                    itemStyle:{
                        borderWidth:0,
                    },
                    textStyle:{
                        color:'#fff',
                        fontSize:10
                    }
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        left: '15%',
                        boottom:0,
                        top:'20%',
                        // width:'1.875rem',
                        // height:'1.875rem',
                        // itemStyle: {
                        //     borderRadius: 10,
                        //     borderColor: '#fff',
                        //     borderWidth: 1
                        // },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        // emphasis: {
                        //     label: {
                        //         show: true,
                        //         fontSize: '20',
                        //         fontWeight: 'bold'
                        //     }
                        // },
                        labelLine: {
                            show: false,
                            length: 15,
                            length2: 0,
                            maxSurfaceAngle: 80
                        },
                        data: [
                            { value: this.costStatistics.foodCostTotal, name: 'The food cost' ,
                                itemStyle: {
                                    normal: {//颜色渐变
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0, color: '#2C6BF8' // 0% 处的颜色
                                            }, {
                                                offset: 1, color: '#6B12CC' // 100% 处的颜色
                                            }],
                                            global: false // 缺省为 false
                                        }
                                    }
                                }
                            },
                            { value: this.costStatistics.labourTotal, name: 'Labour' ,
                                itemStyle: {
                                    normal: {//颜色渐变
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0, color: '#2B8AFF' // 0% 处的颜色
                                            }, {
                                                offset: 1, color: '#28B1FF' // 100% 处的颜色
                                            }],
                                            global: false // 缺省为 false
                                        }
                                    }
                                }
                            },
                            { value: this.costStatistics.ebithaTotal, name: 'EBITDA' ,
                                itemStyle: {
                                    normal: {//颜色渐变
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0, color: '#FED701' // 0% 处的颜色
                                            }, {
                                                offset: 1, color: '#E78801' // 100% 处的颜色
                                            }],
                                            global: false // 缺省为 false
                                        }
                                    }
                                }
                            },
                        ]
                    }
                ]
            };
            option && this.myPie.setOption(option);
        }
    }
}
</script>
<style scoped>
    .rank_main2{
        margin-top: 0 !important;
        text-align: left;
        min-height: 2.55rem;
    }
    .main {
        position: relative;
        width: 100%;
        padding: .25rem .1125rem .2rem .225rem;
        background-color: rgba(0,0,0,0.4);
        color: #fff;
        border-radius: .1875rem;
        z-index: 10;
        box-sizing: border-box;
    }
    .rank_title{
        font-size: .25rem;
        font-weight: bold;
        line-height: .25rem;
        height: .25rem;
        text-align: left;
    }
    .rank_main {
        margin-top: .25rem;
        text-align: left;
    }
    #fold{
        /* height: 2.6875rem; */
        height: 2.4075rem;
    }
    #pie {
        /* height: 2.6875rem; */
        height: 2.4075rem;
    }
</style>