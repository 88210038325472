<template>
    <div class="main2" >
        <div class="rank_title2">
            <el-row>
                <el-col :span="12"  class="rank_title_left2">{{numberData.title}}</el-col>
                <el-col :span="11"  class="rank_title_right2">{{numberData.title1}}</el-col>
            </el-row>
        </div>
        <div class="rank_main2">
            <el-row v-for="(item,index) in numberData.list" :key="index"  class="rank_main_list">
                <el-col :span="15" class="rank_main_top_left">
                    <el-col :span="3" class="square_main">
                        <div class="square red_square" v-if="index == 0">{{index+1}}</div>
                        <div class="square green_square" v-if="index == 1">{{index+1}}</div>
                        <div class="square yellow_square" v-if="index == 2">{{index+1}}</div>
                        <div class="square blue_square" v-if="index != 0 &&  index != 1 && index != 2">{{index+1}}</div>
                    </el-col>
                    <el-col :offset="1" :span="20" class="rank_main_top_value">
                        <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
                           <span>{{item.name}}</span> 
                        </el-tooltip>
                    </el-col>
                </el-col>
                <el-col :span="9" class="rank_main_top_right">
                    <span class="rank_main_top_right_value">
                        <countTo :startVal='0' :endVal='item.amount?item.amount:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                    </span>
                    <span v-if="index < 3" class="img">
                        <img src="../../assets/images/greenup.png" alt="" v-if="item.falge == 'up'" class="rank_img">
                        <img src="../../assets/images/reddown.png" alt="" v-if="item.falge == 'down'" class="rank_img">
                        <img src="../../assets/images/balance.png" alt="" v-if="item.falge == 'balance'" class="rank_img">
                    </span>
                    <div v-else class="img">
                        <span class="hiddenimg"></span>
                    </div>
                </el-col>
            </el-row>
            <div class="top2"></div>
            <div class="bottom2"></div>
        </div>
    </div>
</template>
<script>
import countTo from 'vue-count-to';
export default {
    components: { countTo },
    props:{
        numberData:{
            type:Object,
            default() {
                return {}
            }
        } 
    },
    watch:{
        'numberData':{
            handler: function (newValue, oldValue) {
               this.numberData = newValue;
            },
            deep: true
        }
    },
    data() {
        return {
            
        }
    },
    created() {
        
    },
    methods:{
    }
}
</script>
<style scoped>
    .rank_title_left2{
        margin-top: -2px !important;
    }
    .main {
        position: relative;
        width: 100%;
        padding: 0.25rem .1125rem .3625rem .225rem;
        background-color: rgba(0,0,0,0.4);
        color: #fff;
        border-radius: .1875rem;
        z-index: 10;
        box-sizing: border-box;
    }
    .rank_title{
        font-size: .25rem;
        font-weight: bold;
        line-height: .25rem;
        height: .25rem;
    }
    .rank_title_left {
        text-align: left;
    }
    .rank_title_right {
        text-align: right;
    }
    .rank_main {
        margin-top: .375rem;
        text-align: left;
    }
    .rank_main_list {
        height: .6rem;
        line-height: .25rem;
    }
    .rank_main_top_left {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .rank_main_top_right {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
    }
    /* .rank_main_top_right_value {
        margin-right: 10px;
    } */
    .img {
        width: .325rem;
        height: .375rem;
        display: inline-block;
        text-align: right;
    }
    .rank_img {
        width:.1875rem;
        height: .1875rem;
    }
    .hiddenimg{
        width:.1875rem;
        height: .1875rem;
        display: inline-block;
    }
    .square_main{
        position: relative;
    }
    .square {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: .275rem;
        height: .2375rem;
        border-radius: .025rem;
        line-height: .2375rem;
        text-align: center;
        color:#fff;
        font-size: .1625rem;
    }
    .red_square {
        background: #EB6100;
    }
    .green_square {
        background: #06D28D;
    }
    .yellow_square {
         background: #FFD200;
    }
    .blue_square {
         background: #64A6FF;
    }
    .rank_main_top_value {
        font-size: .2rem;
        font-weight: 400;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
    }
</style>