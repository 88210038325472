import http from '@/utils/http'

//获取 shop (总销售、周销售、单日销售额)
export function getTotalSales() {
    return http({
      url: '/api/board/store/getSalesAnalysis',
      method: 'get',
      headers:{
        Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
      }
    })
}

//获取(单店铺周销量排名（数量）)
export function getShopweeksales(query) {
    return http({
      url: '/api/board/store/getStoreFourWeekSales', //?start=' + query.start + '&end='+ query.end + '&index=' + query.index
      method: 'get',
      params:query,
      headers:{
        Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
      }
    })
}

//获取(单店铺日销量排名（数量）)
export function getShopdaysales(query) {
    return http({
      url: '/api/board/store/getStoreSevenDaySales',
      method: 'get',
      params:query,
      headers:{
        Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
      }
    })
}

//获取(月销量排名（数量）)
export function getMonthsales(query) {
    return http({
      url: '/api/board/store/getWeekSalesAnalysisList',
      method: 'get',
      params:query,
      headers:{
        Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
      }
    })
}

//获取(日销量排名（数量）)
export function getDaysales(query) {
    return http({
      url: '/api/board/store/getDaySalesAnalysisList',
      method: 'get',
      params:query,
      headers:{
        Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
      }
    })
}