<template>
    <div class="containers">
        <div class="container-main">
            <div class="container_bg">
                <div class="title">
                    <div class="login">
                        <img src="../../assets/images/logo.png" alt="" class="logo_img">
                        <!-- <span> Pacific Foods</span> -->
                    </div>
                    <div class="header-title">
                        <span>Takeaway Data</span>
                    </div>
                    <div class="back" @click="backMap">
                        <img src="../../assets/images/quit.png" alt="">
                    </div>
                </div>
                <div class="amount">
                    <el-row>
                        <el-col :span="24">
                           <div>
                                <span style="font-size:0.35rem;color: #FFFFFF;">Total Sales 
                                    <span class="amount_text">
                                        <!-- <countTo :startVal='0' :endVal='shopData.total?shopData.total:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo> -->
                                        <countTo :startVal='0' :endVal='salesTotal' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                    </span>
                                </span>
                            </div>
                            <div class="bottom_line"></div>
                        </el-col>
                        
                    </el-row>
                </div>
                <div class="main">
                    <div class="main-top">
                        <el-col :span="12" class="main-top_bg">
                            <div class="main_center">
                                <!-- <div class="linetime1">
                                    <el-col :span="11">
                                        <el-date-picker
                                            v-model="weekLimit.start"
                                            type="date"
                                            placeholder="选择日期"
                                            size="mini"
                                            @change="changeStart1"
                                            >
                                        </el-date-picker>
                                    </el-col>
                                    <el-col :span="2">
                                        <div style="color:#fff;padding: 0 7px;margin: 13px 0;"><div style="width:100%;height:1px;background:#fff;"></div></div>
                                    </el-col>
                                    <el-col :span="11">
                                        <el-date-picker
                                            v-model="weekLimit.end"
                                            type="date"
                                            placeholder="选择日期"
                                            size="mini"
                                            @change="changeEnd1">
                                        </el-date-picker>
                                    </el-col>
                                </div> -->
                                <el-date-picker
                                    v-model="weekLimit.searchDate"
                                    type="month"
                                    format="yyyy-MM"
                                    align="center"
                                    size="mini"
                                    @change="changeMonthrange">
                                </el-date-picker>
                                <el-table
                                    :data="shopWeek.data ? shopWeek.data.slice((weekLimit.index-1)*weekLimit.limit,weekLimit.index*weekLimit.limit) : []"
                                    :header-cell-style="{background:'#143778',color:'#71CDF9',fontSize: '0.2375rem',border:'0px'}"
                                    :row-style="{height:'0.5225rem'}"
                                    style="width: 100%;margin-top:0.1875rem;"
                                    @row-click="weekRowclick"
                                    :row-class-name="tableRowClassNameweek"
                                    size="mini">
                                    <template slot="empty">
                                        <span style="color: #969799;">No data</span>
                                    </template>
                                    <el-table-column
                                    prop="storeName"
                                    label="Shop Name"
                                    show-overflow-tooltip
                                    align="left">
                                    </el-table-column>
                                    <el-table-column
                                    prop="totalPrice"
                                    label="Sales"
                                    align="right"
                                    width="120">
                                    <template slot-scope="scope">
                                        <countTo :startVal='0' :endVal='scope.row.totalPrice?scope.row.totalPrice:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                    </template>
                                    </el-table-column>
                                </el-table>
                                <div class="block"  v-if="shopWeek.size > 6">
                                    <el-pagination
                                        layout="prev, pager, next"
                                        :pager-count="5"
                                        :page-size="weekLimit.limit"
                                        :current-page="weekLimit.index"
                                        @current-change="week_current_change"
                                        :total="shopWeek.size">
                                    </el-pagination>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12" class="main-top_bg">
                            <div class="main_center">
                                
                                <el-date-picker
                                    v-model="dayLimit.day"
                                    type="week"
                                    format="yyyy Week WW"
                                    placeholder="选择周"
                                    :picker-options="{'firstDayOfWeek': 1}"
                                    size="mini"
                                    @change="changeDay">
                                </el-date-picker>
                                <el-table
                                    :data="shopDay.data ? shopDay.data.slice((dayLimit.index-1)*dayLimit.limit,dayLimit.index*dayLimit.limit) : []"
                                    :header-cell-style="{background:'#143778',color:'#71CDF9',fontSize: '0.2375rem',border:'0px'}"
                                    :row-style="{height:'0.5225rem'}"
                                    style="width: 100%;margin-top:0.1875rem;"
                                    @row-click="dayRowclick"
                                    :row-class-name="tableRowClassNameday"
                                    size="mini">
                                    <template slot="empty">
                                        <span style="color: #969799;">No data</span>
                                    </template>
                                    <el-table-column
                                    prop="storeName"
                                    label="Shop Name"
                                    show-overflow-tooltip
                                    align="left">
                                    </el-table-column>
                                    <el-table-column
                                    prop="totalPrice"
                                    label="Sales"
                                    align="right"
                                    width="120">
                                    <template slot-scope="scope">
                                        <countTo :startVal='0' :endVal='scope.row.totalPrice?scope.row.totalPrice:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                    </template>
                                    </el-table-column>
                                </el-table>
                                <div class="block" v-if="shopDay.size > 6">
                                    <el-pagination
                                        layout="prev, pager, next"
                                        :pager-count="5"
                                        :page-size="dayLimit.limit"
                                        :current-page="dayLimit.index"
                                        @current-change="current_change"
                                        :total="shopDay.size">
                                    </el-pagination>
                                </div>
                            </div>
                        </el-col>
                    </div>
                    <div class="main-bottom">
                        <div class="main-bottom_center">
                            <div id="cpuColumn"></div>
                        </div>
                        // <el-col :span="12"></el-col>
                        <!--<el-col :span="12">
                            <div class="main-bottom_center">
                                <div id="line1"></div>
                            </div>
                        </el-col>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import {getTotalSales,getMonthsales,getShopweeksales,getDaysales,getShopdaysales} from '../../api/shop'
import countTo from 'vue-count-to';
import {toThousands} from "../../utils/utils"
export default {
    components: { countTo },
    props:{
        salesTotal:{
            type:Number,
            default() {
                return 0
            }
        } 
    },
    data() {
        return {
            shopWeek:{
                data:[],
                size:0
            },
            shopDay:{
                data:[],
                size:0
            },
            weekLimit:{
                index: 1,
                limit:6,
                // start:'',
                // end:""
                searchDate:'',
                all:''
            },
            dayLimit:{
                index: 1,
                limit:6,
                day:''
            },
            shopData:{},
            totalsalesTime:null,
            columnData:{},
            lineData:[],
            weekRowindex:0,
            dayRowindex:0,
            // pickerOptions:{
            //     shortcuts: [{
            //         text: '全部',
            //         onClick(picker) {
            //             // let date = new Date()
            //             // let month = date.getMonth();
            //             // let start = date.setMonth(0);
            //             // let end = date.setMonth(month + 11-month);
            //             // picker.$emit('pick', [1,11]);
            //             picker.$emit('pick', [0]);
            //         }
            //     }]
            // },
            dateType:0//0选择月 1选择周
        }
    },
    destroyed(){
        clearInterval(this.totalsalesTime)
        this.totalsalesTime = null;
    },
    created(){
        this.initFunction()
        // this.setTimeFuction()
    },
    methods:{
        initFunction() {
                //时间段查
            // this.weekLimit.end = new Date();
            // this.changeEnd1(this.weekLimit.end)
            this.dayLimit.day = this.getPreDate();
            this.getTotalSales()
            this.getDaysales()

            //月份查
            let date = new Date;
            let data2 = this.getlastMonthT(date);
            this.weekLimit.searchDate = data2;
            this.getMonthsales()
        },
        setTimeFuction() {
            var that = this;
            this.totalsalesTime = setInterval(()=> {
                this.getTotalSales();
            },that.$ajaxTime)
        },
        //店铺数据统计
        getTotalSales() {
            getTotalSales().then(res => {
                if(res.data.code == 1) {
                    res.data.data.total = 162163.56;  //写死数据
                    this.shopData = res.data.data;
                }
            })
        },
        // 月店铺数据
        getMonthsales() {
            let parmas = {
                index: this.weekLimit.index,
                limit:this.weekLimit.limit,
                date: this.getMonth(this.weekLimit.searchDate) ,
                // start: this.timeStr(this.weekLimit.start),
                // end: this.timeStr(this.weekLimit.end)
            }
            getMonthsales(parmas).then(res => {
                if(res.data.code == 1){
                    if(res.data.data && res.data.data.length > 0) {
                        this.shopWeek.data = res.data.data;
                        this.shopWeek.size = res.data.data.length;
                        this.getShopweeksales(this.shopWeek.data[0])
                        this.weekRowindex = 0;
                    }else {
                        this.shopWeek = {
                            data:[],
                            size:0
                        }
                        this.columnData = [];
                        this.$nextTick(()=> {
                            let chartDom = document.getElementById('cpuColumn');
                            chartDom.innerHTML = 'No Data';
                            chartDom.style.cssText = 'text-align:center; color: #fff; border: none;line-height: 250px;font-size:15px;';
                            chartDom.removeAttribute('_echarts_instance_');
                        })
                    }
                }
            })
        },
        // 单日店铺数据
        getDaysales() {
            let parmas = {
                index: this.dayLimit.index,
                limit:this.dayLimit.limit,
                day: this.timeStr(this.dayLimit.day)
            }
            getDaysales(parmas).then(res => {
                if(res.data.code == 1){
                    if(res.data.data && res.data.data.length > 0) {
                        this.shopDay.data = res.data.data;
                        this.shopDay.size = res.data.data.length;
                        // this.getShopdaysales(this.shopDay.data[0])
                        // this.getShopweeksales(this.shopDay.data[0]);
                        this.dayRowindex = 0;
                    }else {
                        // this.shopDay = {
                        //     data:[],
                        //     size:0
                        // }
                        // this.lineData = [];
                        // this.$nextTick(()=> {
                        //     let chartDom = document.getElementById('line1');
                        //     chartDom.innerHTML = 'No Data';
                        //     chartDom.style.cssText = 'text-align:center; color: #fff; border: none;line-height: 250px;font-size:15px;';
                        //     chartDom.removeAttribute('_echarts_instance_');
                        // })
                    }
                }
            })
        },
        // 单个店铺周数据
        getShopweeksales(item) {
            var dateInfo = '';
            if(this.dateType == 0){//0按照每月
                dateInfo = this.getMonthNum(this.weekLimit.searchDate);
            }else{//1 按照每周
                dateInfo = this.timeStr(this.dayLimit.day);
            }
            getShopweeksales({storeId: item.storeId,date:dateInfo }).then(res => {
                if(res.data.code == 1) {
                    this.columnData = [];
                    this.columnData = res.data.data;
                    this.inintColumn()
                }
            })
        },
        // 单个店铺日数据
        getShopdaysales(item) {
            getShopdaysales({storeId: item.storeId,date: this.timeStr(this.dayLimit.day)}).then(res => {
                if(res.data.code == 1) {
                    this.lineData = [];
                    this.lineData = res.data.data;
                    // this.initLine1()
                }
            })
        },
        initLine1() {
            let data = [this.lineData.onePrice,this.lineData.twoPrice,this.lineData.threePrice,this.lineData.fourPrice,this.lineData.fivePrice,this.lineData.sixPrice,this.lineData.sevenPrice]
            let xdata = [this.lineData.oneDay,this.lineData.twoDay,this.lineData.threeDay,this.lineData.fourDay,this.lineData.fiveDay,this.lineData.sixDay,this.lineData.sevenDay]
            var rageSum = 0
            data.map(item => {
                rageSum += item
            })
            let rage = Math.ceil(rageSum/7)
            let rageData = [rage,rage,rage,rage,rage,rage,rage]
            var chartFold = document.getElementById('line1');
            let myFold  = echarts.init(chartFold);
            myFold.showLoading({
                text: 'loading',
                color: '#409eff',
                textColor: '#fff',
                maskColor: 'rgba(0, 0, 0, 0.1)',
                zlevel: 0,
            });;
            setTimeout(() => {
                    // setOption前隐藏loading事件 
                    myFold.hideLoading();
                    myFold.setOption(option);
            },1000)
            var option;
            option = {
                title: {
                    text: 'Daily Sales',
                    textStyle:{
                        color: '#fff',
                        fontSize: 20
                    }
                    
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    },
                    textStyle:{
                        align:'left'
                    },
                    formatter: function(params) {
                        var result = ''
                        var dotHtml =  `<div><div style="text-align:center">${params[0].name}</div><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#FE411B"></span>`
                            result = dotHtml +'Sales' + `<span style="margin-left:10px;">￡</span>` + toThousands(params[0].data) + "</div>"
                        return result
                    }
                },
                legend: {
                    top: '0%',
                    right: '5%',
                    itemWidth:10,
                    itemHeight:10,
                    itemStyle:{
                        borderWidth:0,
                    },
                    textStyle:{
                        color:'#fff',
                        fontSize:15
                    }
                },
                grid: {
                    top:'20%',
                    left: '0%',
                    right: '4%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff',
                        }
                    },
                    axisLabel:{
                        fontSize:"0.125rem",
                    },
                    
                    data: xdata
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:false
                    },
                },
                dataZoom:{
                    type:'inside'
                },
                series: [
                    {
                        data: data,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2, 
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#FE411B' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#FE9B1A', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"Actual",
                        symbolSize:8,
                        symbol:'circle',
                        // stack: 'Total',
                        // showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#FE411B' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#FE9B1A', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        color:{
                            type: 'radial',
                                x: 0.5,
                                y: 0.5,
                                r: 0.5,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#FE411B' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#FE9B1A', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                        }
                    },
                    {
                        data: rageData,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2, 
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#00F0FF' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#3D8FFF', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"Average",
                        symbolSize:8,
                        symbol:'circle',
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#00F0FF' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#3D8FFF', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        color:{
                            type: 'radial',
                                x: 0.5,
                                y: 0.5,
                                r: 0.5,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#3D8FFF' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#00F0FF', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                        },
                        // tooltip:{
                        //     show:false
                        // },
                        showSymbol:false
                    }
                ]
            };
            // option && myFold.setOption(option);
        },
        getWeeks(year) {
            // 一年第一天是周几
            var first = new Date(year, 0, 1).getDay()
            if (first == 1) {
                first = 0
            } else if (first == 0) {
                first = 1
            } else {
                first = 8 - first
            }
            // 计算一年有多少天
            if ((year % 4 == 0 && year % 100 != 0) || (year % 100 == 0 && year % 400 == 0)) {
                var allyears = 366
            } else {
                var allyears = 365
            }
            // 计算一年有多少周
            var week = Math.ceil((allyears - first) / 7) + (first !== 0 ? 1 : 0)
            return week
        },
        getNowWeek(num) {
            if(num > 0) {
                return num
            }else {
              let week =  this.getWeeks(new Date().getFullYear())
              return   week - Math.abs(num);
            }
        },
        inintColumn() {
            let data = [this.columnData.eightPrice,this.columnData.sevenPrice,this.columnData.sixPrice,this.columnData.fivePrice,this.columnData.fourPrice,this.columnData.threePrice,this.columnData.twoPrice,this.columnData.onePrice]
            let chartDom = document.getElementById('cpuColumn');
            let myChart = echarts.init(chartDom);
            myChart.showLoading({
                text: 'loading',
                color: '#409eff',
                textColor: '#fff',
                maskColor: 'rgba(0, 0, 0, 0.1)',
                zlevel: 0,
            });;
            setTimeout(() => {
                    // setOption前隐藏loading事件 
                    myChart.hideLoading();
                    myChart.setOption(option);
            },1000)
            let option;
            option = {
                title: {
                    text: 'Weekly Sales',
                    textStyle:{
                        color: '#fff',
                        fontSize: 20
                    }
                    
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'none'
                    },
                    formatter: function(params) {
                        var result = ``
                        var dotHtml =  `<div><div style="text-align:center">${params[0].name}</div><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#51FCF9"></span>`
                            result = dotHtml +'Sales' + `<span style="margin-left:10px;">￡</span>` + toThousands(params[0].data) + "</div>"
                        return result
                    }
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '10%',
                    top:"15%",
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: [
                        'Week '+parseFloat(this.getNowWeek(this.columnData.week-7)),
                        'Week '+parseFloat(this.getNowWeek(this.columnData.week-6)),
                        'Week '+parseFloat(this.getNowWeek(this.columnData.week-5)),
                        'Week '+parseFloat(this.getNowWeek(this.columnData.week-4)),
                        'Week '+parseFloat(this.getNowWeek(this.columnData.week-3)),
                        'Week '+parseFloat(this.getNowWeek(this.columnData.week-2)),
                        'Week '+parseFloat(this.getNowWeek(this.columnData.week-1)),
                        'Week '+parseFloat(this.getNowWeek(this.columnData.week))
                    ],
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:true,
                        lineStyle:{
                            color:'#FFFFFF',
                            opacity:'0.1'
                        },
                    },
                    axisLabel: {
                        formatter: "£{value}",
                    },
                },
                series: [
                    {
                        name:'Sales',
                        type: 'bar',
                        large:true,
                        barWidth : 30,
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: '#51FCF9' // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: '#52F7A5' // 0% 处的颜色
                                },
                                // {
                                //     offset: 0.9,
                                //     color: '#000' // 0% 处的颜色
                                // },
                                // {
                                //     offset: 1,
                                //     color: '#000' // 100% 处的颜色
                                // }
                            ],
                            global: false // 缺省为 false
                        },
                        data: data
                    }
                ]
                };

            // option && myChart.setOption(option);
        },
        backMap() {
            clearInterval(this.totalsalesTime)
            this.totalsalesTime = null;
            this.$store.commit('changeRankshow',false)
        },
        current_change(page) {
            this.dayLimit.index = page;
            this.dayRowindex = 0;
            // this.getDaysales()
            // this.getShopdaysales(this.shopDay.data.slice((this.dayLimit.index-1)*this.dayLimit.limit,this.dayLimit.index*this.dayLimit.limit)[0]);
        },
        week_current_change(page){
            this.weekLimit.index = page;
            this.weekRowindex = 0;
            // this.getMonthsales()
            this.getShopweeksales(this.shopWeek.data.slice((this.weekLimit.index-1)*this.weekLimit.limit,this.weekLimit.index*this.weekLimit.limit)[0]);
        },
        changeStart1(time) {
            let t = new Date(time.getTime())
            const maxTime = new Date(t).setMonth(new Date(t).getMonth() + 1);
            this.weekLimit.end = new Date(maxTime)
            this.getMonthsales()
        },
        changeEnd1(time) {
            let t = new Date(time.getTime())
            const minTime = new Date(t).setMonth(new Date(t).getMonth() - 1);
            this.weekLimit.start = new Date(minTime)
            this.getMonthsales()
        },
        changeDay(time) {
            this.isTypeFun(1);
            let dateStr = new Date(time.setDate(time.getDate() - 1));
            this.dayLimit.day = dateStr;
            this.getDaysales()
        },
        weekRowclick(item) {
            this.isTypeFun(0);
            this.getShopweeksales(item)
            this.weekRowindex = this.getArrayIndex(this.shopWeek.data, item);
        },
        dayRowclick(item) {
            // this.getShopdaysales(item)
            this.isTypeFun(1);
            // this.getShopweeksales(item)
            this.dayRowindex = this.getArrayIndex(this.shopDay.data, item);
        },
        isTypeFun(date){
            this.dateType = date;
        },
        timeStr(dataStr){
            var date = new Date(dataStr);
            var y = date.getFullYear();

            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;

            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;

            // var h = date.getHours();
            // h = h < 10 ? ('0' + h) : h;

            // //获得分
            // var mm = date.getMinutes()
            // mm = mm < 10 ? ('0' + mm) : mm;

            // //获得秒
            // var ss = date.getSeconds()
            // ss = ss < 10 ? ('0' + ss) : ss;

            // return y+'-'+m+'-'+d+' '+h+':'+mm+':'+ss
            return y+'-'+m+'-'+ d;
        },
        tableRowClassNameweek({row, rowIndex}) {
            if (rowIndex === this.weekRowindex) {
                return 'highlight-row'
            }
            return '';
        },
        tableRowClassNameday({row, rowIndex}) {
            if (rowIndex === this.dayRowindex) {
                return 'highlight-row'
            }
            return '';
        },
        getArrayIndex(arr, obj) {
            var i = arr.length;
            while (i--) {
                if (arr[i] === obj) {
                return i;
                }
            }
            return -1;
        },
        getPreDate(){
            // var curDate = new Date('2022-10-30');
            // var preDate = new Date(curDate.getTime() - 24*60*60*1000*7);

            var curDate = new Date()
            var nowTime = curDate.getTime();
            var weekDay = curDate.getDay()-1;
            var oneDayTime = 24*60*60*1000;
            var preDate = nowTime - (weekDay+0)*oneDayTime; //获取本周日
            var oldDate = new Date(preDate- 24*60*60*1000*7)//获取上周日
            return oldDate;
        },
        changeMonthrange(time) {
            // this.weekLimit.all = false;
            // if(Array.isArray(time)) {
            //     this.weekLimit.all = true;
            // }else {
                this.isTypeFun(0);
                this.weekLimit.searchDate = time;
            // }
            this.weekRowindex = 0;
            this.getMonthsales()
        },
        getMonth(dataStr) {
            var date = new Date(dataStr);
            var y = date.getFullYear();

            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            return y+'-'+m;
        },
        //按照上月查询
        getLastMonth(dataStr) {
            var lastMonthToday = new Date(
                new Date(dataStr).getTime() - 30 * 24 * 60 * 60 * 1000
            );
            var lastMonthYear = lastMonthToday.getFullYear();
            var lastMonth = lastMonthToday.getMonth() + 1;
            return lastMonthYear+'-'+lastMonth;
        },
        getlastMonthT() {
            let now = new Date();
            // 当前月的日期
            let nowDate = now.getDate();
            let lastMonth = new Date(now.getTime());
            // 设置上一个月（这里不需要减1）
            lastMonth.setMonth(lastMonth.getMonth());
            // 设置为0，默认为当前月的最后一天
            lastMonth.setDate(0);
            // 上一个月的天数
            let daysOflastMonth = lastMonth.getDate();
            // 设置上一个月的日期，如果当前月的日期大于上个月的总天数，则为最后一天 
            lastMonth.setDate(nowDate > daysOflastMonth ? daysOflastMonth : nowDate);
            return lastMonth;
        },
        getMonthNum(dataStr) {
            var date = new Date(dataStr);
            var y = date.getFullYear();

            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var days = this.getLastDay(y,m);
            return y+'-'+m+'-'+days;
        },
        getLastDay(year, month) {
            const isLeapYear = ((year % 4)==0) && ((year % 100)!=0) || ((year % 400)==0)
            const maxDays = [1,3,5,7,8,10,12]  
            const middleDays = [4,6,9,11]  
            month = Number(month)
            if (month == 2) {
                if (isLeapYear) {
                    return 29
                } else {
                    return 28
                }
            }  else  if (maxDays.includes(month)) {
                return 31
            } else if (middleDays.includes(month)) {
                return 30
            }
        } 
    }
}
</script>
<style scoped>
    .containers {
        width: 100%;
        height: 100%;
        padding: .25rem 1.25rem;
        box-sizing: border-box;
    }
    .container-main {
        width: 100%;
        height: 100%;
        background: url('../../assets/images/analysis.png') no-repeat;
        background-size: 100% 100%;
    }
    .container_bg {
        width: 100%;
        height: 100%;
        padding: .375rem 1.5rem;
        box-sizing: border-box;
    }
    .title {
        display: flex;
        justify-content:space-between;
        align-items:center;
        font-size: .4rem;
        font-weight: 500;
        color: #fff;
        position: relative;
    }
    /* .login img {
        width: .7375rem;
        height: .7375rem;
        vertical-align: middle;
    } */
    .back {
        width: .4625rem;
        height: .375rem;
    }
    .amount {
        margin-top: .25rem;
    }
    .amount_text {
        font-size:0.4625rem;
        font-weight: bold;
        color: #FFFFFF;
        background: linear-gradient(0deg, #fcc480 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .bottom_line{
        width: 3.25rem;
        height: .025rem;
        margin-top: .025rem;
        background: radial-gradient(ellipse,#00C8F1,#000);
        margin: .025rem auto;
    }
    .main {
        width: 100%;
        height:100%;
        margin-top: .125rem;
    }
    .main-top{
        height: 5.3875rem;
        width: 100%;
    }
    .main-top_bg {
        width: 50%;
        height: 100%;
        background: url('../../assets/images/left_top.png') no-repeat;
        background-size: 100% 100%;
    }
    #line1 {
        height: 4.2rem;
    }
    #cpuColumn{
        height: 4.2rem;
    }
    .linetime {
        z-index: 10000;
        width: 50%;
        background: rgba(250, 250, 250, 0.1);
        border: none;
    }
    /deep/ .el-range-editor--mini .el-range-input {
        background: rgba(250, 250, 250, 0);
        color: #fff;
    }
    /deep/ .el-range-editor--mini .el-range-separator {
        color: #fff;
    }
    .main_center {
        padding: .5625rem 1.0625rem;
        text-align: center;
        margin: 0  auto;
        position: relative;
    }
    /deep/ .el-table td.el-table__cell {
        border-bottom: 0;
        color: #fff;
        background-color: #091c38;
    }
    /deep/ .el-table::before {
        width: 0;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td{
        background-color: #235dc8 !important;
    }
    /deep/ .el-pagination button:disabled {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination button {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager .number{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager li.active{
        color: #409EFF !important;
    }
    /deep/ .el-date-editor .el-input__inner {
       background: rgba(250, 250, 250, 0.1);
        color: #fff;
        border: 0;
        text-align: center;
    }
    .main-bottom {
        height: 4.5375rem;
        width: 100%;
        background: url('../../assets/images/analysis_bottombg.png') no-repeat;
        background-size: 100% 100%;
    }
    .block {
        position: absolute;
        bottom: 8px;
        right: 12%;
    }
    .main-bottom_center {
        padding: .375rem 1.0625rem;
        text-align: center;
        margin: 0  auto;
    }
    .linetime1 {
        z-index: 10000;
        width: 60%;
        border: none;
        margin: 0 auto;
        margin-bottom: .5625rem
    }
    /deep/ .linetime1 .el-date-editor.el-input{
        width: 100%;
    }
    /deep/ .el-table__empty-block {
        background: #091c38;
    }
    /deep/ .el-table__body-wrapper {
        background: #091c38;
    }
    /deep/ .el-table__header-wrapper {
        background: #091c38;
    }
    /deep/ .el-pager li.btn-quicknext {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pager li.btn-quickprev{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    .logo_img {
        width: 2.5rem;
        height: .875rem;
        vertical-align: middle;
        text-align: left;
        display: inline-block;
    }
</style>