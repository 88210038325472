<template>
    <div class="containers">
        <div class="container-main" v-show="businessType == 'main'">
            <div class="container_bg" >
                <div class="title">
                    <div class="login">
                        <img src="../../assets/images/logo.png" alt="" class="logo_img">
                        <!-- <span> Pacific Foods</span> -->
                    </div>
                    <div class="back" @click="backMap">
                        <img src="../../assets/images/quit.png" alt="">
                    </div>
                </div>
                <div class="amount">
                    <el-row>
                        <el-col :span="8">
                           <div>
                                <span style="font-size:0.35rem;color: #FFFFFF;">Total Sales 
                                   <span class="amount_text">
                                        <countTo :startVal='0' :endVal='businessData.totalSales?businessData.totalSales:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                    </span>
                                </span>
                            </div>
                            <div class="bottom_line"></div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <span style="font-size:0.35rem;color: #FFFFFF;">Weekly Sales 
                                    <span class="amount_text">
                                        <countTo :startVal='0' :endVal='businessData.weeklySales?businessData.weeklySales:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                    </span>
                                </span>
                            </div>
                            <div class="bottom_line"></div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <span style="font-size:0.35rem;color: #FFFFFF;">Daily Sales 
                                    <span class="amount_text">
                                        <countTo :startVal='0' :endVal='businessData.dailySales?businessData.dailySales:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                    </span>
                                </span>
                            </div>
                            <div class="bottom_line"></div>
                        </el-col>
                    </el-row>
                </div>
                <div class="main">
                    <div class="main-top">
                        <el-col :span="12" class="main-top_bg">
                            <div class="main_center">
                                <!-- <div style="height:28px;width:100%;"></div> -->
                                <!-- height="3.75rem" 0.625rem businessWeek ? businessWeek.slice((weekLimit.page-1)*weekLimit.limit,weekLimit.page*weekLimit.limit) : []-->
                                <el-table
                                    ref="table1"
                                    :data="businessWeek"
                                    :header-cell-style="{background:'#143778',color:'#71CDF9',fontSize: '0.2375rem',border:'0px'}"
                                    :row-style="{'height':'0.625rem'}"
                                    style="width: 100%;margin-top:0.1875rem;"
                                    height="3.75rem"
                                    @row-click="weekRowclick"
                                    :row-class-name="tableRowClassNameweek"
                                    @sort-change="sortFun"
                                    :default-sort = "{prop: 'amount', order: 'descending'}"
                                    size="mini">
                                    <template slot="empty">
                                        <span style="color: #969799;">No data</span>
                                    </template>
                                    <el-table-column
                                    prop="storeName"
                                    label="Customer Name"
                                    align="left"
                                    min-width="55%">
                                    </el-table-column>
                                    <el-table-column
                                    prop="amount"
                                    label="Sales"
                                    show-overflow-tooltip
                                    align="right"
                                    sortable="custom"
                                    min-width="20%">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.amount != '--'">
                                            <countTo :startVal='0' :endVal='scope.row.amount?scope.row.amount:0' :duration='1000' separator="," :decimals="0" prefix="￡"></countTo>
                                        </div>
                                        <div v-else></div>
                                    </template>
                                    

                                    </el-table-column>
                                    <el-table-column
                                    prop="postCode"
                                    label="Postcode"
                                    align="right"
                                    min-width="25%">
                                    </el-table-column>
                                    
                                </el-table>
                                <!-- 自定义表格 -->
                                <!-- <div class="custom-table">
                                    <div class="custom-table-head custom-pw">
                                        <div class="l1">Customer Name</div>
                                        <div class="l2">Amount</div>
                                        <div class="l3">Postcode</div>
                                        <div class="l4">Area</div>
                                    </div>
                                    <div class="custom-table-body" ref="scrollview">
                                        <div class="custom-table-li custom-pw" v-for="(item,index) in businessWeek" :id="'anchor'+item.pageNum+item.counts" :key="index">
                                            <div class="l1">{{item.storeName}}</div>
                                            <div class="l2">£ {{item.amount}}</div>
                                            <div class="l3">{{item.postCode}}</div>
                                            <div class="l4">{{item.area}}</div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="block" v-if="businessWeeksize >= 5">
                                    <el-pagination
                                        layout="prev, pager, next"
                                        :pager-count="5"
                                        :page-size="weekLimit.limit"
                                        :current-page="weekLimit.page"
                                        @current-change="week_current_change"
                                        :total="businessWeeksize">
                                    </el-pagination>
                                </div>

                                <div class="Cosmo" @click="cosmoShow">
                                    <div class="more_text"><span>COSMO</span> <img src="../../assets/images/right_arrow.png" alt=""></div>
                                </div>

                                <div class="more" @click="leftShow" v-if="businessWeeksize >= 5">
                                    <div class="more_text"><span>MORE</span> <img src="../../assets/images/right_arrow.png" alt=""></div>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12" class="main-top_bg">
                            <div class="main_center">
                                <!-- <el-date-picker
                                    v-model="dayLimit.searchDate"
                                    type="month"
                                    format="M月"
                                    align="center"
                                    size="mini"
                                    :picker-options="pickerOptions"
                                    @change="changeMonthrange">
                                </el-date-picker> -->
                                <!-- businessDay ? businessDay.slice((dayLimit.page-1)*dayLimit.limit,dayLimit.page*dayLimit.limit) : [] -->
                                <el-table
                                    ref="table2"
                                    :data="businessDay"
                                    :header-cell-style="{background:'#143778',color:'#71CDF9',fontSize: '0.2375rem',border:'0px'}"
                                    :row-style="{height:'0.625rem'}"
                                    style="width: 100%;margin-top:0.1875rem;"
                                    height="3.75rem"
                                    @row-click="cpuRowclick"
                                    :row-class-name="tableRowClassNameday"
                                    @sort-change="sortFunDay"
                                    :default-sort = "{prop: 'amount', order: 'descending'}"
                                    size="mini">
                                    <!-- 207.89px -->
                                    <template slot="empty">
                                        <span style="color: #969799;">No data</span>
                                    </template>
                                    <el-table-column
                                    show-overflow-tooltip
                                    min-width="25%"
                                    align="left">
                                        <template slot="header" slot-scope="scope">
                                            <el-dropdown trigger="click" @command="handleCommand2" size="mini">
                                                <span>
                                                    Category<i class="el-icon-arrow-down el-icon--right"></i>
                                                </span>
                                                <el-dropdown-menu slot="dropdown" class="dropdown-menu">
                                                    <el-dropdown-item v-for="item in classList"
                                                    :key="item.code" :command="item.code">
                                                    {{item.labelEn}}
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </template>
                                        <template slot-scope="scope"> 
                                            <div class="category_name">
                                            {{scope.row.categoryName}}
                                            </div>
                                        </template>
                                        <!-- <template slot="header" slot-scope="scope">
                                            <el-popover placement="right" class='sort' trigger="click">
                                                <el-select v-model='selectSortId' clearable multiple filterable collapse-tags @change='changePermission($event)'>
                                                    <el-option v-for='item in listSort' :value='item.id' :label='item.sort_name' :key='item.id'></el-option>
                                                </el-select>
                                                <div slot="reference"> <label> Category</label> <i class='el-icon-arrow-down'> </i> </div>
                                            </el-popover>
                                        </template> -->
                                        <!-- <template slot-scope="scope"> 
                                            <div>
                                            {{scope.row}}
                                            </div>
                                        </template> -->
                                    </el-table-column>
                                    <el-table-column
                                    prop="goodsNameEn"
                                    label="Name"
                                    align="left"
                                    min-width="37%"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                    prop="amount"
                                    label="Sales"
                                    show-overflow-tooltip
                                    align="right"
                                    sortable="custom"
                                    min-width="20%">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.amount != '--'">
                                            <countTo :startVal='0' :endVal='scope.row.amount?scope.row.amount:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                        </div>
                                        <div v-else></div>
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    prop="total"
                                    label="QTY"
                                    align="center"
                                    sortable="custom"
                                    min-width="18%">
                                    </el-table-column>
                                    <!-- <el-table-column
                                    prop="unit"
                                    label="Unit"
                                    align="right"
                                    min-width="12%">
                                    </el-table-column> -->
                                </el-table>
                                <div class="block" v-if="businessDaysize >= 5">
                                    <el-pagination
                                        layout="prev, pager, next"
                                        :pager-count="5"
                                        :page-size="dayLimit.limit"
                                        :current-page="dayLimit.page"
                                        @current-change="current_change"
                                        :total="businessDaysize">
                                    </el-pagination>
                                </div>
                                <div class="more" @click="rightShow" v-if="businessDaysize >= 5">
                                    <div class="more_text"><span>MORE</span> <img src="../../assets/images/right_arrow.png" alt=""></div>
                                </div>
                            </div>
                        </el-col>
                    </div>
                    <div class="main-bottom">
                        <el-col :span="12">
                            <div class="main-bottom_center">
                                <div id="businessColumns"></div>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="main-bottom_center">
                                <div id="businessline1"></div>
                            </div>
                        </el-col>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="businessType != 'main'">
            <business-left v-if="businessType == 'left'" @ParentType="parentType"></business-left>
            <business-right v-if="businessType == 'right'" @ParentType="parentType"></business-right>
            <cosmo v-if="businessType == 'cosmo'" @ParentType="parentType"></cosmo>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import {getTotalSales,getWeeklyrank,getDayrank,getClassification,getAllgoods,getGoodsLine,getStoreList,getGoodsMonth6,getSalesrank,getSalesData,getrangeGMV, getSalesrankTest,recent6Month,getBusinessStatisticFoodo2o,getCountList} from '../../api/business'
import {getGoodsWeek,getGoodsDays} from '../../api/cpu'
import countTo from 'vue-count-to';
import businessLeft from "./businessLeft.vue";
import businessRight from "./businessRight.vue"
import cosmo from "./cosmo.vue"
import {toThousands} from "../../utils/utils"
export default {
    components: { countTo ,businessLeft,businessRight,cosmo},
    data() {
        return {
            businessWeek:[],
            businessWeeksize:0,
            businessDay:[],
            businessDaysize:0,
            weekLimit:{
                page: 1,
                limit:5,
                beginDate:'',
                endDate:""
            },
            dayLimit:{
                page: 1,
                limit:5,
                searchDate:'',
                categoryCode:"",
                all:false
            },
            dom:'',
            businessData:{},
            totalsalesTime1:null,
            columnData:{},
            lineData:[],
            weekRowindex:0,
            dayRowindex:0,
            pickerOptions:{
                shortcuts: [{
                    text: '全部',
                    onClick(picker) {
                        // let date = new Date()
                        // let month = date.getMonth();
                        // let start = date.setMonth(0);
                        // let end = date.setMonth(month + 11-month);
                        // picker.$emit('pick', [1,11]);
                        picker.$emit('pick', [0]);
                    }
                }]
            },
            countPages:0,
            businessType:'main',
            classList:[],
            urlType:'',
            dom:'',
            dom2:'',
            saleSortWeek:2,
            numSortGoods:'',
            saleSortGoods:2
        }
    },
    destroyed(){
        clearInterval(this.totalsalesTime1)
        this.totalsalesTime1 = null;
    },
    created(){
        this.urlType = window.location.hostname;
        this.initFunction()
        // this.setTimeFuction()
    },
    mounted() {
        this.leftTableScroll();
        this.rightTableScroll();
    },
    methods:{
        sortFun(item){
            if(item.order == "descending"){
                this.saleSortWeek = 2;
            }else{
                this.saleSortWeek = 1;
            }
            this.dom.scrollTop = 0;
            this.weekLimit.page = 1;
            this.weekRowindex = 0;
            this.getWeeklyrank();
        },
        sortFunDay(item){
            if(item.prop == "total"){//按数量排序
                if(item.order == "descending"){
                    this.numSortGoods = 2;
                }else{
                    this.numSortGoods = 1;
                }
                this.saleSortGoods = '';
            }else if(item.prop == "amount"){//按金额排序
                if(item.order == "descending"){
                    this.saleSortGoods = 2;
                }else{
                    this.saleSortGoods = 1;
                }
                this.numSortGoods = '';
            }
            this.dom2.scrollTop = 0;
            this.dayLimit.page = 1;
            this.dayRowindex = 0;
            this.getAllgoods();
        },
        leftTableScroll(){
            // 获取需要绑定的table
            this.dom = this.$refs.table1.bodyWrapper
            this.dom.addEventListener('scroll', () => {
                // 表格滚动条滚动的距离
                let scrollTop = this.dom.scrollTop;
                let tabHeight = 0;
                for (let i = 0; i < this.businessWeek.length; i++) {
                    this.businessWeek[i].top = tabHeight;
                    tabHeight = tabHeight + document.getElementsByClassName('anchor'+this.businessWeek[i].pageNum+this.businessWeek[i].counts)[0].offsetHeight;
                    
                    this.businessWeek[i].bottom = tabHeight;
                }
                let scrollTops = scrollTop+(document.getElementsByClassName('anchor'+this.businessWeek[0].pageNum+this.businessWeek[0].counts)[0].offsetHeight*3);
                
                for (let i = 0; i < this.businessWeek.length; i++) {
                    if (scrollTops > this.businessWeek[i].top && scrollTops < this.businessWeek[i].bottom) {
                        this.weekLimit.page = this.businessWeek[i].pageNum;
                    }
                }
                
            })
        },
        rightTableScroll(){
            this.dom2 = this.$refs.table2.bodyWrapper;
            this.dom2.addEventListener('scroll', () => {
                // 表格滚动条滚动的距离
                let scrollTop = this.dom2.scrollTop;
                let tabHeight = 0;
                for (let i = 0; i < this.businessDay.length; i++) {
                    this.businessDay[i].top = tabHeight;
                    tabHeight = tabHeight + document.getElementsByClassName('anchorT'+this.businessDay[i].pageNum+this.businessDay[i].counts)[0].offsetHeight;
                    this.businessDay[i].bottom = tabHeight;
                }
                let scrollTops = scrollTop+(document.getElementsByClassName('anchorT'+this.businessDay[0].pageNum+this.businessDay[0].counts)[0].offsetHeight*3);
                for (let i = 0; i < this.businessDay.length; i++) {
                    if (scrollTops > this.businessDay[i].top && scrollTops < this.businessDay[i].bottom) {
                        this.dayLimit.page = this.businessDay[i].pageNum;
                    }
                }
            
            });
        },
        initFunction() {
            this.getClassification();
            // this.dayLimit.searchDate = new Date();
            this.getAllgoods()
            this.getWeeklyrank();
            //this.getStoreList()
            // this.weekLimit.endDate = new Date();
            // this.dayLimit.searchDate = this.getPreDate();
            // this.changeEnd1(this.weekLimit.endDate)
            this.getTotalSales()
            // this.getDayrank()
        },
        //查分类
        getClassification(){
            getClassification().then(res => {
                this.classList = [];
                this.classList = res.data.data.list;
                this.classList.unshift({code:"",labelEn:'All'})
            })
        },
        //查菜品
        getAllgoods() {
            let parmas = {
                page: this.dayLimit.page,
                limit: this.dayLimit.limit,
                categoryCode:this.dayLimit.categoryCode,
                sortAmount:this.saleSortGoods,
                sortNum:this.numSortGoods
                // category:this.dayLimit.categoryCode
            }
            getAllgoods(parmas).then(res => {
                    this.businessDay = [];

                    let datas = res.data.rows;
                    let pages = 0;
                    for(let i =0;i<datas.length;i++){
                        if(i%5 == 0){
                            pages++;
                        }
                        datas[i].pageNum = pages;
                        datas[i].counts = i%5;
                    }

                    this.businessDay = datas;
                    this.businessDaysize = datas.length;
                    
                    let countNum = this.businessDay[this.businessDay.length-1].counts+1;
                    for(let n = 0;n<5-countNum;n++){
                        this.businessDay.push({categoryName:'',goodsNameEn:'',amount:'--',total:'',unit:'',pageNum:pages,counts:countNum+n});
                    }

                    if(this.businessDay.length>0) {
                        this.getSalesData(this.businessDay[0])
                    }else {
                        this.lineData = [];
                        this.$nextTick(()=> {
                            let chartDom = document.getElementById('businessline1');
                            chartDom.innerHTML = 'No Data';
                            chartDom.style.cssText = 'text-align:center; color: #fff; border: none;line-height: 250px;font-size:15px;';
                            chartDom.removeAttribute('_echarts_instance_');
                        })
                    }
            })
            // if(this.urlType == 'pfoods.co.uk'){
            //     getSalesrankTest(parmas).then(res => {
            //         this.businessDay = [];
            //         this.businessDay = res.data.data;
            //         this.businessDaysize = this.businessDay.length;
            //         if(this.businessDay.length>0) {
            //             this.getSalesData(this.businessDay[0])
            //         }else {
            //             this.lineData = [];
            //                 this.$nextTick(()=> {
            //                     let chartDom = document.getElementById('businessline1');
            //                     chartDom.innerHTML = 'No Data';
            //                     chartDom.style.cssText = 'text-align:center; color: #fff; border: none;line-height: 250px;font-size:15px;';
            //                     chartDom.removeAttribute('_echarts_instance_');
            //                 })
            //         }
            //     })
            // }else{
            //     getSalesrank(parmas).then(res => {
            //         this.businessDay = [];
            //         this.businessDay = res.data.data;
            //         this.businessDaysize = this.businessDay.length;
            //         if(this.businessDay.length>0) {
            //             this.getSalesData(this.businessDay[0])
            //         }else {
            //             this.lineData = [];
            //                 this.$nextTick(()=> {
            //                     let chartDom = document.getElementById('businessline1');
            //                     chartDom.innerHTML = 'No Data';
            //                     chartDom.style.cssText = 'text-align:center; color: #fff; border: none;line-height: 250px;font-size:15px;';
            //                     chartDom.removeAttribute('_echarts_instance_');
            //                 })
            //         }
            //     })
            // }
            
        },
        getSalesData(item) {
            // getSalesData({code: item.code,category:''}).then(res => {
            //     this.lineData = [];
            //     this.lineData = res.data.data;
            //     this.initLine1()
            // })
            getGoodsLine({goodsCode: item.goodsCode,goodsType:item.goodsType}).then(res => {
                this.lineData = [];
                this.lineData = res.data.data;
                this.initLine1()
            })
        },
        getStoreList() {
            getStoreList({areaId:''}).then(res => {
                this.businessWeek = [];
                let result = res.data.data;
                result.map(item => {
                    var reg = RegExp(/Cosmo/);
                    if(!item.name.match(reg) && item.amount > 0){
                        this.businessWeek.push(item);   
                    }
                })
                this.businessWeeksize = this.businessWeek.length;

                if(this.businessWeek.length>0){
                    this.getGoodsMonth6(this.businessWeek[0])
                }else {
                    this.columnData = [];
                    this.$nextTick(()=> {
                        let chartDom = document.getElementById('businessColumns');
                        chartDom.innerHTML = 'No Data';
                        chartDom.style.cssText = 'text-align:center; color: #fff; border: none;line-height: 250px;font-size:15px;';
                        chartDom.removeAttribute('_echarts_instance_');
                    })
                }
            })
        },
        getGoodsMonth6(item) {
            getGoodsMonth6({code: item.code}).then(res => {
                this.columnData = [];
                this.columnData = res.data.data;
                this.inintColumn()
            })
        },
        setTimeFuction() {
            var that = this;
            this.totalsalesTime1 = setInterval(()=> {
                this.getTotalSales();
            },that.$ajaxTime)
        },
        async getTotalSales() {
            let parmas = {
                beginDate: '2022-03-01',
                endDate: this.timeStr(new Date())
            }
            let result = await getrangeGMV(parmas).then(res => {
                if(res.data.code == 200){
                    if(res.data.data.hasOwnProperty('gmv')) {
                        return  res.data.data.gmv
                    }else {
                      return 0;
                    }
                }
            })
            getBusinessStatisticFoodo2o().then(res => {
                if(res.data.code == 200) {
                    // this.businessData = res.data.data;
                    // res.data.data.weeklySales,
                    this.businessData = {
                        totalSales: 4341753.27 + result + 1191966.15 + 121350 + 105329,
                        weeklySales: 245305.48,
                        dailySales: res.data.data.dailySales
                    }
                }
            })
        },
        getWeeklyrank() {
            let parmas = {
                page: this.weekLimit.page,
                limit: this.weekLimit.limit,
                sortAmount: this.saleSortWeek
                //beginDate: this.timeStr(this.weekLimit.beginDate),
                //endDate: this.timeStr(this.weekLimit.endDate)
            }
            getCountList(parmas).then(res => {
                if(res.data.code == 200){
                    //this.businessWeek = res.data.rows;
                    

                    let datas = res.data.rows;
                    let pages = 0;
                    for(let i =0;i<datas.length;i++){
                        if(i%5 == 0){
                            pages++;
                        }
                        datas[i].pageNum = pages;
                        datas[i].counts = i%5;
                    }
                    
                    this.businessWeek = datas;
                    this.businessWeeksize = datas.length;
                    let countNum = this.businessWeek[this.businessWeek.length-1].counts+1;
                    for(let n = 0;n<5-countNum;n++){
                        this.businessWeek.push({storeName:'',amount:'--',postCode:'',pageNum:pages,counts:countNum+n});
                    }

                    if(this.businessWeek.length>0){
                        this.getGoodsWeek(this.businessWeek[0])
                    }else {
                        this.columnData = [];
                        this.$nextTick(()=> {
                            let chartDom = document.getElementById('businessColumns');
                            chartDom.innerHTML = 'No Data';
                            chartDom.style.cssText = 'text-align:center; color: #fff; border: none;line-height: 250px;font-size:15px;';
                            chartDom.removeAttribute('_echarts_instance_');
                        })
                    }
                }
            })
        },
        getDayrank() {
            let parmas = {
                page: this.dayLimit.page,
                limit: this.dayLimit.limit,
                searchDate: this.timeStr(this.dayLimit.searchDate),
            }
            getDayrank(parmas).then(res => {
                if(res.data.code == 200){
                    this.businessDay = res.data.rows;
                    this.businessDaysize = res.data.total;
                    if(this.businessDay.length>0) {
                        this.getGoodsDays(this.businessDay[0])
                    }else {
                        this.lineData = [];
                        this.$nextTick(()=> {
                            let chartDom = document.getElementById('businessline1');
                            chartDom.innerHTML = 'No Data';
                            chartDom.style.cssText = 'text-align:center; color: #fff; border: none;line-height: 250px;font-size:15px;';
                            chartDom.removeAttribute('_echarts_instance_');
                        })
                    }
                }
            })
        },
        initLine1() {
            let data = []
            let xdata = []
            // var rageSum = 0
            this.lineData.map(item => {
                // rageSum += item.total
                data.push(item.amount)
                // xdata.push(item.month)
                xdata.push(item.dt)
            })
            // let rage = Math.ceil(rageSum/7)
            // let rageData = [rage,rage,rage,rage,rage,rage,rage]
            var chartFold = document.getElementById('businessline1');
            let myFold  = echarts.init(chartFold);

            myFold.showLoading({
                text: 'loading',
                color: '#409eff',
                textColor: '#fff',
                maskColor: 'rgba(0, 0, 0, 0.1)',
                zlevel: 0,
            });;
            setTimeout(() => {
                    // setOption前隐藏loading事件 
                    myFold.hideLoading();
                    myFold.setOption(option);
            },1000)
            var option;
            option = {
                title: {
                    text: "Product Monthly Sales",
                    textStyle:{
                        color: '#fff',
                        fontSize: 20
                    }
                    
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    },
                    textStyle:{
                        align:'left'
                    },
                    formatter: function(params) {
                        var result = ``
                        var dotHtml =  `<div><div style="text-align:center">${params[0].name}</div><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#e78801"></span>`
                            result = dotHtml +'Sales' + `<span style="margin-left:10px;">￡</span>` + toThousands(params[0].data) + "</div>"
                        return result
                    }
                },
                // legend: {
                //     top: '0%',
                //     right: '5%',
                //     itemWidth:10,
                //     itemHeight:10,
                //     itemStyle:{
                //         borderWidth:0,
                //     },
                //     textStyle:{
                //         color:'#fff',
                //         fontSize:15
                //     }
                // },
                grid: {
                    top:'20%',
                    left: '0%',
                    right: '4%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff',
                        }
                    },
                    axisLabel:{
                        fontSize:"10px",
                        // interval: 0
                    },
                    
                    data: xdata
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:false
                    },
                    axisLabel:{
                        formatter: function (value) {
                            let texts = '';
                            texts = "£"+toThousands(Math.floor(value/1000*1000));
                            return texts;
                        }
                    }
                },
                dataZoom: [
                    {
                        type: 'inside',
                        // start: 80,
                        // end: 100,
                        // zoomLock:true
                    }
                ],
                series: [
                    {
                        data: data,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2, 
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#e78801' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#e78801', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"Sales",
                        symbolSize:8,
                        symbol:'circle',
                        // stack: 'Total',
                        // showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#e78801' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#e78801', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        color:{
                            type: 'radial',
                                x: 0.5,
                                y: 0.5,
                                r: 0.5,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#F59011' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#FDDB00', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                        },
                    },
                ]
            };
            // option && myFold.setOption(option);
        },
        inintColumn() {
            let data = [];
            let xdata = [];
            this.columnData.map(item => {
                // data.push(item.amount)
                // xdata.push(item.date)
                data.push(item.gmvTotal)
                xdata.push(item.dt)
            })
            let chartDom = document.getElementById('businessColumns');
            let myChart = echarts.init(chartDom);
            myChart.showLoading({
                text: 'loading',
                color: '#409eff',
                textColor: '#fff',
                maskColor: 'rgba(0, 0, 0, 0.1)',
                zlevel: 0,
            });;
            setTimeout(() => {
                    // setOption前隐藏loading事件 
                    myChart.hideLoading();
                    myChart.setOption(option);
            },1000)
            let option;
            option = {
                title: {
                    text: "Monthly Sales",
                    textStyle:{
                        color: '#fff',
                        fontSize: 20
                    }
                    
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none'
                    },
                    formatter: function(params) {
                        var result = ``
                        var dotHtml =  `<div><div style="text-align:center">${params[0].name}</div><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#28b1ff"></span>`
                            result = dotHtml +'Sales' + `<span style="margin-left:10px;">￡</span>` +toThousands(params[0].data) + "</div>"
                        return result
                    }
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '10%',
                    top:"15%",
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: xdata,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:true,
                        lineStyle:{
                            color:'#FFFFFF',
                            opacity:'0.1'
                        },
                    },
                    axisLabel:{
                        formatter: function (value) {
                            let texts = '';
                            texts = "£"+toThousands(Math.floor(value/1000*1000));
                            return texts;
                        }
                    }
                },
                series: [
                    {
                        name:'Sales',
                        type: 'bar',
                        large:true,
                        barWidth : 30,
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: '#28b1ff' // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: '#0072ff' // 0% 处的颜色
                                },
                                // {
                                //     offset: 0.9,
                                //     color: '#000' // 0% 处的颜色
                                // },
                                // {
                                //     offset: 1,
                                //     color: '#000' // 100% 处的颜色
                                // }
                            ],
                            global: false // 缺省为 false
                        },
                        data: data
                    }
                ]
                };

            // option && myChart.setOption(option);
        },
        backMap() {
            clearInterval(this.totalsalesTime1)
            this.totalsalesTime1 = null;
            this.$store.commit('changeBusinessShow',false)
        },
        current_change(page) {
            this.dayLimit.page = page;
            let PageId = document.querySelector('.anchorT' + page +'0');
            setTimeout(() => {
                this.dom2.scrollTop = PageId.offsetTop;
            },10);
            for(let i =0;i<this.businessDay.length;i++){
                if(page == this.businessDay[i].pageNum && this.businessDay[i].counts == 0){
                    this.dayRowindex = i;
                }
            }
            this.getSalesData(this.businessDay[this.dayRowindex]);
        },
        week_current_change(page){
            this.weekLimit.page = page;
            let PageId = document.querySelector('.anchor' + page +'0');
            //let id = '.anchor' + page +'0';
            //const el = document.querySelector(id);
            setTimeout(() => {
                this.dom.scrollTop = PageId.offsetTop;
            },10);
            for(let i =0;i<this.businessWeek.length;i++){
                if(page == this.businessWeek[i].pageNum && this.businessWeek[i].counts == 0){
                    this.weekRowindex = i;
                }
            }
            this.getGoodsWeek(this.businessWeek[this.weekRowindex])
        },
        changeStart1(time) {
            let t = new Date(time.getTime())
            const maxTime = new Date(t).setMonth(new Date(t).getMonth() + 1);
            this.weekLimit.endDate = new Date(maxTime)
            this.getWeeklyrank()
        },
        changeEnd1(time) {
            let t = new Date(time.getTime())
            const minTime = new Date(t).setMonth(new Date(t).getMonth() - 1);
            this.weekLimit.beginDate = new Date(minTime)
            this.getWeeklyrank()
        },
        changeDay(time) {
            this.dayLimit.searchDate = time;
            this.getDayrank()
        },
        getGoodsWeek(item) {
            recent6Month({userCode: item.code}).then(res => {
                if(res.data.code == 200) {
                    this.columnData = [];
                    this.columnData = res.data.data;
                    this.inintColumn()
                }
            })
        },
        getGoodsDays(item) {
            getGoodsDays({goodsCode: item.goodsCode}).then(res => {
                if(res.data.code == 200) {
                    this.lineData = [];
                    this.lineData = res.data.data;
                    this.initLine1()
                }
            })
        },
        weekRowclick(item) {
            //this.getGoodsMonth6(item)
            this.getGoodsWeek(item);
            let ary = this.businessWeek.slice((this.weekLimit.page-1)*this.weekLimit.limit,this.weekLimit.page*this.weekLimit.limit)
            this.weekRowindex = this.getArrayIndex(ary, item);
        },
        cpuRowclick(item) {
            this.getSalesData(item)
            let ary = this.businessDay.slice((this.dayLimit.page-1)*this.dayLimit.limit,this.dayLimit.page*this.dayLimit.limit)
            this.dayRowindex = this.getArrayIndex(ary, item);
        },
        tableRowClassNameweek({row, rowIndex}) {
            if (rowIndex === this.weekRowindex) {
                return 'highlight-row'+ ' '+ 'anchor'+ row.pageNum + row.counts
            }
            if(row.amount == "--"){
                return 'blank_sty'+ ' '+ 'anchor'+ row.pageNum + row.counts
            }
            return 'anchor'+ row.pageNum + row.counts;
        },
        tableRowClassNameday({row, rowIndex}) {
            if (rowIndex === this.dayRowindex) {
                return 'highlight-row'+ ' '+ 'anchorT'+ row.pageNum + row.counts
            }
            if(row.amount == "--"){
                return 'blank_sty'+ ' '+ 'anchorT'+ row.pageNum + row.counts
            }
            return 'anchorT'+ row.pageNum + row.counts;
        },
        getArrayIndex(arr, obj) {
            var i = arr.length;
            while (i--) {
                if (arr[i] === obj) {
                return i;
                }
            }
            return -1;
        },
        timeStr(dataStr){
            var date = new Date(dataStr);
            var y = date.getFullYear();

            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;

            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;

            // var h = date.getHours();
            // h = h < 10 ? ('0' + h) : h;

            // //获得分
            // var mm = date.getMinutes()
            // mm = mm < 10 ? ('0' + mm) : mm;

            // //获得秒
            // var ss = date.getSeconds()
            // ss = ss < 10 ? ('0' + ss) : ss;

            // return y+'-'+m+'-'+d+' '+h+':'+mm+':'+ss
            return y+'-'+m+'-'+ d;
        },
        getMonthNum(dataStr) {
            var date = new Date(dataStr);
            var m = date.getMonth() + 1
            return m;
        },
        getPreDate(){
            var curDate = new Date();
            var preDate = new Date(curDate.getTime() - 24*60*60*1000);
            return preDate;
        },
        changeMonthrange(time) {
            this.dayLimit.all = false;
            if(Array.isArray(time)) {
                this.dayLimit.all = true;
            }else {
                this.dayLimit.searchDate = time;
            }
            this.dayRowindex = 0;
            this.getAllgoods();
        },
        handleCommand2(command) {
            this.dayLimit.categoryCode = command;
            this.dayLimit.page = 1;
            this.dom2.scrollTop = 0;
            this.getAllgoods();
        },
        leftShow(){
            this.businessType = 'left';
        },
        rightShow(){
            this.businessType = 'right';
        },
        parentType(value){
            this.businessType = value;
            this.$nextTick(() => {
                this.$refs.table1.doLayout();
                this.$refs.table2.doLayout();
            })
            
        },
        cosmoShow(){
            this.businessType = 'cosmo';
        }
    }
}
</script>
<style scoped>
    .custom-pw .l1{
        width: 40%;
        text-align: left;
    }
    .custom-pw .l2{
        width: 20%;
    }
    .custom-pw .l3{
        width: 20%;
    }
    .custom-pw .l4{
        width: 20%;
    }
    .custom-pw div{
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow:ellipsis;
        padding: 0 0.2rem;
    }
    /* 设置滚动条的样式 */
    ::-webkit-scrollbar {
        width: 2px !important; /*滚动条宽度*/
        height: 8px !important; /*滚动条高度*/
    }
    /* 滚动槽 */
    ::-webkit-scrollbar-track {
        border-radius: 0px !important; /*滚动条的背景区域的圆角*/
        background-color: rgba(2,29,87,1) !important; /*滚动条的背景颜色*/
    }
    /* 滚动条滑块 */
    ::-webkit-scrollbar-thumb {
        border-radius: 0px !important; /*滚动条的圆角*/
        background-color: rgba(24,144,255,0.50) !important; /*滚动条的背景颜色*/
    }
    ::-webkit-scrollbar-thumb:window-inactive {
        background-color: rgba(2,29,87,1) !important;
    }

    .custom-table{
        width:100%;
        margin-top: 0.1875rem;
        height: 3.75rem;
    }
    .custom-table-head{
        display: flex;
        justify-content: space-around;
        background: rgb(20, 55, 120);
        color: rgb(113, 205, 249);
        font-size: 0.2375rem;
        height: 0.625rem;
        line-height:0.625rem;
        font-weight: 500;
    }
    .custom-table-body{
        height: 3.125rem;
        overflow-y: scroll;
        background: #071632;
        color: #fff;
    }
    .custom-table-li{
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        /* padding: 0.286rem 0; */
        height: 0.625rem;
        font-size: 0.16rem !important;
    }
    .custom-table-li:hover{
        background-color: #235dc8 !important;
    }
    .containers {
        width: 100%;
        height: 100%;
        padding: .25rem 1.25rem;
        box-sizing: border-box;
    }
    .container-main {
        width: 100%;
        height: 100%;
        background: url('../../assets/images/analysis.png') no-repeat;
        background-size: 100% 100%;
    }
    .container_bg {
        width: 100%;
        height: 100%;
        padding: .375rem 1.5rem;
        box-sizing: border-box;
    }
    .title {
        display: flex;
        justify-content:space-between;
        align-items:center;
        font-size: .4rem;
        font-weight: 500;
        color: #fff;
    }
    /* .login img {
        width: .7375rem;
        height: .7375rem;
        vertical-align: middle;
    } */
    .back {
        width: .4625rem;
        height: .375rem;
    }
    .amount {
        margin-top: .25rem;
    }
    .amount_text {
        font-size:0.4625rem;
        font-weight: bold;
        color: #FFFFFF;
        background: linear-gradient(0deg, #fcc480 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .bottom_line{
        width: 3.25rem;
        height: .025rem;
        margin-top: .025rem;
        background: radial-gradient(ellipse,#00C8F1,#000);
        margin: .025rem auto;
    }
    .main {
        width: 100%;
        height:100%;
        margin-top: .125rem;
    }
    .main-top{
        height: 5.3875rem;
        width: 100%;
    }
    .main-top_bg {
        width: 50%;
        height: 100%;
        background: url('../../assets/images/left_top.png') no-repeat;
        background-size: 100% 100%;
    }
    #businessline1 {
        height: 4.2rem;
    }
    #businessColumns{
        height: 4.2rem;
    }
    .linetime {
        z-index: 10000;
        width: 50%;
        background: rgba(250, 250, 250, 0.1);
        border: none;
    }
    /deep/ .el-range-editor--mini .el-range-input {
        background: rgba(250, 250, 250, 0);
        color: #fff;
    }
    /deep/ .el-range-editor--mini .el-range-separator {
        color: #fff;
    }
    .main_center {
        padding: .5625rem 1.0625rem;
        text-align: center;
        margin: 0  auto;
        position: relative;
    }
    /deep/ .el-table td.el-table__cell {
        border-bottom: 0;
        color: #fff;
        background-color: #091c38;
    }
    /deep/ .el-table::before {
        width: 0;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td{
        background-color: #235dc8;
    }
    /deep/ .el-table--enable-row-hover .el-table__body .blank_sty:hover>td{
        background-color: #091c38;
    }
    /deep/ .el-pagination button:disabled {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination button {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager .number{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager li.active{
        color: #409EFF !important;
    }
    /deep/ .el-date-editor .el-input__inner {
       background: rgba(250, 250, 250, 0.1);
        color: #fff;
        border: 0;
        text-align: center;
    }
    .main-bottom {
        height: 4.5375rem;
        width: 100%;
        background: url('../../assets/images/analysis_bottombg.png') no-repeat;
        background-size: 100% 100%;
    }
    .block {
        position: absolute;
        bottom: 0;
        left: 10%;
    }
    .Cosmo {
        position: absolute;
        bottom: 3%;
        right: 28%;
    }
    .more {
        position: absolute;
        bottom: 3%;
        right: 12%;
    }
    .more_text {
        color: #fff;
    }
    .more_text img {
        vertical-align: text-bottom;

    }
    .main-bottom_center {
        padding: .375rem 1.0625rem;
        text-align: center;
        margin: 0  auto;
    }
    .linetime1 {
        z-index: 10000;
        width: 60%;
        border: none;
        margin: 0 auto;
        margin-bottom: .5625rem
    }
    /deep/ .linetime1 .el-date-editor.el-input{
        width: 100%;
    }
    /deep/ .el-table__empty-block {
        background: #091c38;
    }
    /deep/ .el-table__body-wrapper {
        background: #091c38;
    }
    /deep/ .el-table__header-wrapper {
        background: #091c38;
    }
    /deep/ .el-pager li.btn-quicknext {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pager li.btn-quickprev{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-dropdown {
        color:#71CDF9 !important;
        font-size: 0.2375rem !important;
    }
    .dropdown-menu {
        /* background: rgba(0, 0, 0, 0.8);
        color: #fff; 
        border:0; */
        position: absolute;
        left: 54.5% !important;
        /* top: 26.5% !important; */
        height: 2rem;
        overflow-y: auto;
    }
    .dropdown-menu::-webkit-scrollbar {
        width: 0rem;
    }
    /* .el-dropdown-menu__item {
        color:#fff;
    } */
    .category_name {
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
    }
    .logo_img {
        width: 2.5rem;
        height: .875rem;
        vertical-align: middle;
        text-align: left;
        display: inline-block;
    }

    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px !important; /*滚动条宽度*/
    height: 8px !important; /*滚动条高度*/
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
    border-radius: 0px !important; /*滚动条的背景区域的圆角*/
    background-color: rgba(2,29,87,1) !important; /*滚动条的背景颜色*/
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-corner {
    background-color: rgba(2,29,87,1) !important;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px !important; /*滚动条的圆角*/
    background-color: rgba(24,144,255,0.50) !important; /*滚动条的背景颜色*/
    }

    /deep/ .el-table .gutter{
        background: rgb(20, 55, 120) !important;
    }

    /* 设置滚动条的样式 */
    ::-webkit-scrollbar {
        width: 8px !important; /*滚动条宽度*/
        height: 8px !important; /*滚动条高度*/
    }
    /* 滚动槽 */
    ::-webkit-scrollbar-track {
        border-radius: 0px !important; /*滚动条的背景区域的圆角*/
        background-color: rgba(2,29,87,1) !important; /*滚动条的背景颜色*/
    }
    /* 滚动条滑块 */
    ::-webkit-scrollbar-thumb {
        border-radius: 4px !important; /*滚动条的圆角*/
        background-color: rgba(24,144,255,0.50) !important; /*滚动条的背景颜色*/
    }
    ::-webkit-scrollbar-thumb:window-inactive {
        background-color: rgba(2,29,87,1) !important;
    }

    /deep/ .el-table__body{
        width: 100% !important;
    }
    /deep/ .el-table__body-wrapper .is-scrolling-none{
        height: 100% !important;
    }
    .el-table__body-wrapper .is-scrolling-none{
        height: 100% !important;
    }
</style>