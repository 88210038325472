<template>
    <div class="containers">
        <div class="container-main" v-show="userType == 'main'">
            <div class="container_bg">
                <div class="title">
                    <div class="login">
                        <img src="../../assets/images/logo.png" alt="" class="logo_img">
                        <!-- <span> Pacific Foods</span> -->
                    </div>
                    <div class="header-title">
                        <span>USER</span>
                    </div>
                    <div class="back" @click="backMap">
                        <img src="../../assets/images/quit.png" alt="">
                    </div>
                </div>
                <div class="amount">
                    <el-row>
                        <el-col :span="8">
                           <div @click="setUserType('Existing')">
                               <span style="font-size:0.35rem;color: #FFFFFF;">Current Business User
                                   <span class="amount_text"> 
                                        <countTo :startVal='0' :endVal='239' :duration='1000' separator=","></countTo>
                                    </span>
                                </span>
                            </div>
                            <div class="bottom_line"></div>
                        </el-col>
                        <el-col :span="8">
                            <div @click="setUserType('Waiting')">
                               <span style="font-size:0.35rem;color: #FFFFFF;">Waiting Customer
                                   <span class="amount_text"> 
                                        <countTo :startVal='0' :endVal='290' :duration='1000' separator=","></countTo>
                                    </span>
                                </span>
                            </div>
                            <div class="bottom_line"></div>
                        </el-col>
                        <el-col :span="8">
                           <div @click="setUserType('consumer')">
                               <span style="font-size:0.35rem;color: #FFFFFF;">APP Download
                                   <span class="amount_text"> 
                                        <countTo :startVal='0' :endVal='usertObj.consumerUser? usertObj.consumerUser : 0' :duration='1000' separator="," ></countTo>
                                    </span>
                                </span>
                            </div>
                            <div class="bottom_line"></div>
                        </el-col>
                        <!-- <el-col :span="8">
                            <div>
                                <span style="font-size:0.35rem;color: #FFFFFF;">Monthly Increase
                                    <span class="amount_text"> 
                                        <countTo :startVal='0' :endVal='usertObj.monthtotal?usertObj.monthtotal:0' :duration='1000' separator="," ></countTo>
                                    </span>
                                </span>
                            </div>
                            <div class="bottom_line"></div>
                        </el-col> -->
                        <!-- <el-col :span="8">
                            <div>
                                <span style="font-size:0.35rem;color: #FFFFFF;">Growth Than
                                    <span class="amount_text">
                                        <countTo :startVal='0' :endVal='usertObj.than?usertObj.than:0' :duration='1000' separator="," :decimals="2" suffix="%"></countTo>
                                    </span>
                                </span>
                            </div>
                            <div class="bottom_line"></div>
                        </el-col> -->
                    </el-row>
                </div>
                <div class="main">
                    <!-- <div class="main-top">
                        <el-col :span="12" class="main-top_bg">
                            <div class="main_center">
                                <div id="cpuColumn"></div>
                            </div>
                        </el-col>
                        <el-col :span="12" class="main-top_bg">
                            <div class="main_center">
                                <div id="line1"></div>
                            </div>
                        </el-col>
                    </div> -->
                    <!-- <div class="main-bottom">
                        <div class="main-bottom_center">
                            <div id="cpuColumn"></div>
                        </div>
                    </div> -->
                    <!-- <div class="main-bottom">
                        <div class="main-bottom_center">
                            <div id="line2"></div>
                        </div>
                    </div> -->

                    <div class="main-bottom">
                        <div class="main-bottom_center">
                            <div id="cpuColumn2"></div>
                        </div>
                    </div>
                    <div class="main-bottom">
                        <div class="main-bottom_center">
                            <div id="cpuColumn3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <business-left v-show="userType == 'business'" @userType="setUserType" ref="busleft"></business-left>
            <consumers-detail v-if="userType == 'consumer'" @userType="setUserType" :userTotal="usertObj.consumerUser"></consumers-detail>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import {getTotaluser,getMonth,getWeek,getDay,getMonthapp,getWeekapp,getDayapp,getAppTotaluser,getWebuserList,getUserWeekNums} from '../../api/user'
import countTo from 'vue-count-to';
import consumersDetail from './consumersDetail.vue'
import businessLeft from '../backgroundRank/businessLeft.vue'
export default {
    components: { countTo,consumersDetail,businessLeft },
    data() {
        return {
            startTime1:'',
            endTime1:"",
            startTime2:'',
            endTime2:"",
            userTotal:{
                businessUser:{
                    monthIncrease: 0,
                    totalUser: 0
                },
                appUser:{
                    monthIncrease: 0,
                    totalUser: 0
                }
            },
            monthData:[],
            weekData:{
                business:{},
                app:{},
                xdata:[]
            },
            dayData:{
                business:{},
                app:{}
            },
            totalsalesTime2:null,
            usertObj:{
                total:0,
                monthtotal:0,
                than:0,
                businessUser:0,
                consumerUser:0
            },
            oldIncrease:0,
            rageData:[],
            dateList:[],
            urlType:"",
            userType:'main',
            appUser:[],
            webUser:[]
        }
    },
    destroyed() {
        clearInterval(this.totalsalesTime2)
        this.totalsalesTime2 = null;
    },
    created(){
        this.urlType = window.location.hostname;
        // this.getTotaluser()
        // this.getAppTotaluser()
        // this.mapMonth()
        // this.initFunction()
        // this.setTimeFuction()
        this.customizeData()
    },
    methods:{
        customizeData() {
            // this.getSevenDate()
            this.usertObj = {
                total:sessionStorage.getItem('businessUser')*1 + sessionStorage.getItem('consumerUser')*1,
                monthtotal:15,
                businessUser:sessionStorage.getItem('businessUser')*1,
                consumerUser:sessionStorage.getItem('consumerUser')*1,
                existingCustomer:sessionStorage.getItem('existingCustomer')*1,
                waitingCustomer:sessionStorage.getItem('waitingCustomer')*1
            };
            // this.weekData.business = {month1:18,month2:38,month3:49,month4:54,month5:74,month6:88};
            // this.weekData.app = {oneNum:0,twoNum:0,threeNum:0,fourNum:0,fiveNum:0,fixNum:sessionStorage.getItem('consumerUser')*1};
            //  this.weekData.xdata = ['Jan-22','Feb-22','Mar-22','Apr-22','May-22','Jun-22']
            // this.dayData.business = [{total:0},{total:1},{total:1},{total:2},{total:2},{total:1},{total:3}];
            // this.dayData.app = {oneNum:0,oneDay:this.dateList[0],twoNum:0,twoDay:this.dateList[1],threeNum:0,threeDay:this.dateList[2],fourNum:0,fourDay:this.dateList[3],fiveNum:0,fiveDay:this.dateList[4],sixNum:0,sixDay:this.dateList[5],sevenNum:0,sevenDay:this.dateList[6]};
            // this.$nextTick(()=> {
            //     setTimeout(()=>{
                    // this.initLine1()
                    // this.inintColumn()
                    // this.initline2()
            //     },1000)
            // })

            this.getUserData()
        },
        getUserData() {
            this.getWeekUsers()
            this.getWebuserList()
        },
        getWeekUsers() {
            getUserWeekNums().then(res => {
                this.appUser = res.data.data;
                setTimeout(()=>{
                    this.inintColumn2()
                },1000)
            })
        },
        getWebuserList() {
            //如果查询时间段发生变化，Current Business User和Waiting Customer 也的修改，现在是按照接口反的数据查的

            // getWebuserList({"beginDate":"2021-10","endDate":"2022-10"}).then(res => {
            //     this.webUser = res.data.data;
            //     setTimeout(()=>{
            //         this.inintColumn3()
            //     },1000)
            // })
            //写的死数据
            this.webUser = [
                {waitingCustomer:0,existingCustomer:91,userCount:91,mont:'Oct-21'},
                {waitingCustomer:0,existingCustomer:188,userCount:188,mont:' Nov-21'},
                {waitingCustomer:54,existingCustomer:200,userCount:254,mont:'Dec-21'},
                {waitingCustomer:91,existingCustomer:224,userCount:315,mont:'Jan-22'},
                {waitingCustomer:127,existingCustomer:234,userCount:361,mont:'Feb-22'},
                {waitingCustomer:175,existingCustomer:234,userCount:409,mont:'Mar-22'},
                {waitingCustomer:193,existingCustomer:235,userCount:428,mont:'Apr-22'},
                {waitingCustomer:208,existingCustomer:235,userCount:443,mont:'May-22'},
                {waitingCustomer:242,existingCustomer:235,userCount:477,mont:'Jun-22'},
                {waitingCustomer:252,existingCustomer:235,userCount:487,mont:'Jul-22'},
                {waitingCustomer:256,existingCustomer:235,userCount:491,mont:'Aug-22'},
                {waitingCustomer:259,existingCustomer:237,userCount:496,mont:'Sep-22'},
                {waitingCustomer:261,existingCustomer:237,userCount:498,mont:'Oct-22'},
                {waitingCustomer:273,existingCustomer:238,userCount:511,mont:'Nov-22'},
                {waitingCustomer:284,existingCustomer:238,userCount:522,mont:'Dec-22'},
                {waitingCustomer:290,existingCustomer:239,userCount:529,mont:'Jan-23'},
                {waitingCustomer:290,existingCustomer:239,userCount:529,mont:'Feb-23'},
                {waitingCustomer:290,existingCustomer:239,userCount:529,mont:'Mar-23'},
            ]
            setTimeout(()=>{
                    this.inintColumn3()
            },1000)
        },

        getSevenDate(){
            let time = new Date();
            this.dateList = [];
            for(let i=7;i >= 1 ;i--){
                let yesterday = new Date(time.getTime()-24*60*60*1000*i);
                let m = this.timeMonthStr(yesterday)
                this.dateList.push(m)
            }
        },
        setTimeFuction() {
            var that = this;
            this.totalsalesTime2 = setInterval(()=> {
                this.getTotaluser();
                this.getAppTotaluser()
            },that.$ajaxTime)
        },
        getTotaluser() {
            getTotaluser().then(res => {
                if(res.data.code == 200) {
                    this.userTotal.businessUser = res.data.data;
                    this.mapUserTotal();
                }
            })
        },
        getAppTotaluser() {
            getAppTotaluser().then(res => {
                if(res.data.code == 1) {
                    this.userTotal.appUser = res.data.data;
                    this.mapUserTotal();
                }
            })
        },
        mapUserTotal() {
            let that = this;
            that.usertObj.total = that.userTotal.businessUser.totalUser + that.userTotal.appUser.userTotal;
            that.usertObj.monthtotal = that.userTotal.businessUser.monthIncrease + that.userTotal.appUser.userCurMonth;
            let oneMonth = that.userTotal.businessUser.preIncrease ? that.userTotal.businessUser.preIncrease : 0 + that.userTotal.appUser.userPrevMonth ? that.userTotal.appUser.userPrevMonth : 0; //上个月
            let twoMonth = that.userTotal.businessUser.prePreIncrease ? that.userTotal.businessUser.prePreIncrease : 0 + that.userTotal.appUser.userPrevedMonth ? that.userTotal.appUser.userPrevedMonth : 0; //上上个月
            that.oldIncrease  = that.oldIncrease == 0 ? twoMonth : that.oldIncrease;
            // that.usertObj.than = that.oldIncrease != 0 && that.oldIncrease != undefined ? Math.round((oneMonth-twoMonth)/that.oldIncrease*10000)/100 : '-'
            that.oldIncrease = twoMonth;
        },
        async mapMonth() {
            let that = this;
            let result = await getMonth().then(res => {
                if(res.data.code == 200){
                    return res.data.data;
                }else {
                    return [];
                }
            });
            let appResult = await getMonthapp().then(res => {
                if(res.data.code == 1) {
                    return res.data.data;
                }else {
                    return [];
                }
            });
            result.map(x => {
                appResult.map(t => {
                    if(x.dt == t.date) {
                        that.monthData.push({dt:x.dt,total:x.total*1 + t.num*1})
                        that.rageData.push(Math.round(t.num*1/x.total*1))
                    }
                })
            })
            this.initline2()
        },
        initFunction(){
            this.endTime1 = new Date();
            this.endTime2 = new Date();
            this.changeEnd1(this.endTime1)
            this.changeEnd2(this.endTime2)
        },
        getWeek() {
            let parmas = {
                beginDate:this.startTime1,
                endDate: this.endTime1
            }
            getWeek(parmas).then(res => {
                if(res.data.code == 200) {
                    this.weekData.business = res.data.data;
                    this.inintColumn()
                }
            })
        },
        getDay() {
            let parmas = {
                beginDate:this.startTime2,
                endDate: this.endTime2
            }
            getDay(parmas).then(res => {
                if(res.data.code == 200) {
                    this.dayData.business = res.data.data;
                    let timer = null;
                    clearTimeout(timer)
                    timer = setTimeout(()=> {
                        this.initLine1()
                    },1500)
                }
            })
        },
        getWeekapp() {
            let parmas = {
                start: this.timeStr(this.startTime1),
                end: this.timeStr(this.endTime1)
            }
            getWeekapp(parmas).then(res => {
                if(res.data.code == 1) {
                    this.weekData.app = res.data.data;
                    this.inintColumn()
                }
            })
        },
        getDayapp() {
            let parmas = {
                start:this.timeStr(this.startTime2),
                end: this.timeStr(this.endTime2)
            }
            getDayapp(parmas).then(res => {
                if(res.data.code == 1) {
                    this.dayData.app = res.data.data;
                    let timer = null;
                    clearTimeout(timer)
                    timer = setTimeout(()=> {
                        this.initLine1()
                    },1500)
                }
            })
        },
        initLine1() {
            let webData = []
            if(this.dayData.business.length > 0) {
                this.dayData.business.map(item => {
                    webData.push(item.total)
                })
            }
            let appData = [this.dayData.app.oneNum,this.dayData.app.twoNum,this.dayData.app.threeNum,this.dayData.app.fourNum,this.dayData.app.fiveNum,this.dayData.app.sixNum,this.dayData.app.sevenNum];
            let xdata = [this.dayData.app.oneDay,this.dayData.app.twoDay,this.dayData.app.threeDay,this.dayData.app.fourDay,this.dayData.app.fiveDay,this.dayData.app.sixDay,this.dayData.app.sevenDay];
            var chartFold = document.getElementById('line1');
            let myFold  = echarts.init(chartFold);
            var option;
            option = {
                title: {
                    text: 'Daily growth',
                    textStyle:{
                        color: '#fff',
                        fontSize: 15
                    }
                    
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    bottom: '0%',
                    right: '0%',
                    itemWidth:10,
                    itemHeight:10,
                    itemGap:20,
                    itemStyle:{
                        borderWidth:0,
                    },
                    textStyle:{
                        color:'#fff',
                        fontSize:15
                    },
                    icon:'rect'
                },
                grid: {
                    top:'20%',
                    left: '0%',
                    right: '5%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff',
                        }
                    },
                    axisLabel:{
                        fontSize:"0.125rem",
                    },
                    
                    data: xdata
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:false
                    },
                },
                dataZoom:{
                    type:'inside'
                },
                series: [
                    {
                        data: webData,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2, 
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#F59011' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#FDDB00', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"Web User",
                        symbolSize:8,
                        symbol:'circle',
                        // stack: 'Total',
                        // showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#F59011' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#FDDB00', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        color:'#F59011'
                    },
                    {
                        data: appData,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2, 
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#3D8FFF' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#00F0FF', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"App User",
                        symbolSize:8,
                        symbol:'circle',
                        // stack: 'Total',
                        // showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#00F0FF' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#3D8FFF', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        color:'#00F0FF'
                    }
                ]
            };
            option && myFold.setOption(option);
        },
        inintColumn() {
            let that = this;
            let webData = [this.weekData.business.month1,this.weekData.business.month2,this.weekData.business.month3,this.weekData.business.month4,this.weekData.business.month5,this.weekData.business.month6];
            let appData = [this.weekData.app.oneNum,this.weekData.app.twoNum,this.weekData.app.threeNum,this.weekData.app.fourNum,this.weekData.app.fiveNum,this.weekData.app.fixNum]
            let chartDom = document.getElementById('cpuColumn');
            let myChart = echarts.init(chartDom);
            let option;
            option = {
                title: {
                    text: 'Month of growth',
                    textStyle:{
                        color: '#fff',
                        fontSize: 15
                    },
                    top:'6%',
                    left:'4%'
                },
                // tooltip: {
                //     trigger: 'axis',
                //     axisPointer: {
                //     type: 'none'
                //     }
                // },
                legend: {
                    top: '5%',
                    right: '5%',
                    itemWidth:10,
                    itemHeight:10,
                    itemStyle:{
                        borderWidth:0,
                    },
                    textStyle:{
                        color:'#fff',
                        fontSize:15
                    }
                },
                grid: {
                    top:'20%',
                    left: '5%',
                    right: '5%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: that.weekData.xdata,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:true,
                        lineStyle:{
                            color:'#FFFFFF',
                            opacity:'0.1'
                        },
                    },
                },
                series: [
                    {
                        name:'App User',
                        type: 'bar',
                        large:true,
                        barWidth : 30,
                        label:{
                            show:true,
                            color:'#fff',
                            fontSize:16,
                            position: 'top'
                        },
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: '#28B1FF' // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: '#2B8AFF' // 0% 处的颜色
                                },
                            ],
                            global: false // 缺省为 false
                        },
                        data: appData
                    },
                    {
                        name:'Web User',
                        type: 'bar',
                        large:true,
                        barWidth : 30,
                        label:{
                            show:true,
                            color:'#fff',
                            fontSize:16,
                            position: 'top'
                        },
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: '#FFAF04' // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: '#FFAB61' // 0% 处的颜色
                                },
                            ],
                            global: false // 缺省为 false
                        },
                        data: webData
                    }
                ]
            };

            option && myChart.setOption(option);
        },
        initline2() {
            let data = [];
            let xdata = [];
            data = [18,56,105,159,233,321]
            xdata = ['Jan-22','Feb-22','Mar-22','Apr-22','May-22','Jun-22']
            var chartDom = document.getElementById('line2');
            let myChart = echarts.init(chartDom);
            var option;
            option = {
                title: {
                    text: 'Users',
                    textStyle:{
                        color: '#fff',
                        fontSize: 15
                    },
                    top:'6%',
                    left:'4%'
                },
                tooltip: {
                    // formatter: function(params) {
                    //     var result = ''
                    //     var dotHtml = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#00F0FF"></span>'
                    //         //result = dotHtml + params.name + '  ' +params.data
                    //         result = dotHtml + params.data
                    //     return result
                    // }
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    },
                    textStyle:{
                        align:'left'
                    }
                },
                legend: {
                    top: '5%',
                    right: '5%',
                    itemWidth:10,
                    itemHeight:10,
                    itemStyle:{
                        borderWidth:0,
                    },
                    textStyle:{
                        color:'#fff',
                        fontSize:15
                    }
                },
                grid: {
                    top:'20%',
                    left: '5%',
                    right: '5%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: 'solid',
                            color:'#fff',
                        }
                    },
                    axisLabel:{
                        fontSize:10,
                    },
                    data: xdata //['January', 'February', 'March', 'April','May','June','July','August','September','October','November','December']
                },
                yAxis: [{
                        type: 'value',
                        axisLine:{
                            show:true,
                            lineStyle:{
                                type: 'solid',
                                color:'#fff'
                            }
                        },
                        axisTick:{
                            lineStyle:{
                                type:"solid",
                            }
                        },
                        splitLine:{
                            show:true,
                            lineStyle:{
                                color:'#ccc',
                                opacity:0.1
                            }
                        },
                        position: 'left'
                    },
                    // {
                    //     type: 'value',
                    //     axisLine:{
                    //         show:true,
                    //         lineStyle:{
                    //             type: 'solid',
                    //             color:'#fff'
                    //         }
                    //     },
                    //     axisTick:{
                    //         lineStyle:{
                    //             type:"solid",
                    //         }
                    //     },
                    //     splitLine:{
                    //         show:true,
                    //         lineStyle:{
                    //             color:'#ccc',
                    //             opacity:0.1
                    //         }
                    //     },
                    //     position: 'right'
                    // }
                ],
                series: [
                    {
                        data: data,
                        type: 'line',
                        name:"Actual",
                        symbolSize:8,
                        symbol:'circle',
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#00F0FF' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#3D8FFF', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        color: {
                            type: 'radial',
                                x: 0.5,
                                y: 0.5,
                                r: 0.5,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#00F0FF' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#3D8FFF', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                        },
                        yAxisIndex: 0
                    },
                    // {
                    //     data: this.rageData,
                    //     type: 'line',
                    //     smooth: true,
                    //     name:"User ratio",
                    //     symbolSize:8,
                    //     symbol:'circle',
                    //     lineStyle:{
                    //         color:{
                    //             type: 'linear',
                    //             x: 0,
                    //             y: 0,
                    //             x2: 0,
                    //             y2: 1,
                    //             colorStops: [
                    //                 {
                    //                     offset: 0,
                    //                     color: '#FE411B' // 0% 处的颜色
                    //                 },
                    //                 {
                    //                     offset: 1,
                    //                     color: '#FE9B1A', // 0% 处的颜色,
                    //                 },
                    //             ],
                    //             global: false // 缺省为 false
                    //         }
                    //     },
                    //     color:{
                    //         type: 'radial',
                    //             x: 0.5,
                    //             y: 0.5,
                    //             r: 0.5,
                    //             colorStops: [
                    //                 {
                    //                     offset: 0,
                    //                     color: '#FE411B' // 0% 处的颜色
                    //                 },
                    //                 {
                    //                     offset: 1,
                    //                     color: '#FE9B1A', // 0% 处的颜色,
                    //                 },
                    //             ],
                    //             global: false // 缺省为 false
                    //     },
                    //     tooltip:{
                    //         show:true
                    //     },
                    //     showSymbol:false,
                    //     yAxisIndex: 1,
                    // }
                ]
            };
            option && myChart.setOption(option);
        },
        backMap() {
            clearInterval(this.totalsalesTime2)
            this.totalsalesTime2 = null;
            this.$store.commit('changeUsershow',false)
        },
        changeStart1(time) {
            let t = new Date(time.getTime())
            const maxTime = new Date(t).setMonth(new Date(t).getMonth() + 1);
            this.endTime1 = new Date(maxTime)
            this.getWeek()
            this.getWeekapp()
        },
        changeEnd1(time) {
            let t = new Date(time.getTime())
            const minTime = new Date(t).setMonth(new Date(t).getMonth() - 1);
            this.startTime1 = new Date(minTime)
            this.getWeek()
            this.getWeekapp()
        },
        changeStart2(time) {
            let timeRange = 7 * 24 * 60 * 60 * 1000 // 7天
            const maxTime = time.getTime() + timeRange;
            this.endTime2 = new Date(maxTime)
            this.getDay()
            this.getDayapp()
        },
        changeEnd2(time) {
            let timeRange = 7 * 24 * 60 * 60 * 1000 // 7天
            const minTime = time.getTime() - timeRange;
            this.startTime2 = new Date(minTime)
            this.getDay()
            this.getDayapp()
        },
        timeStr(dataStr){
            var date = new Date(dataStr);
            var y = date.getFullYear();

            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;

            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;

            // var h = date.getHours();
            // h = h < 10 ? ('0' + h) : h;

            // //获得分
            // var mm = date.getMinutes()
            // mm = mm < 10 ? ('0' + mm) : mm;

            // //获得秒
            // var ss = date.getSeconds()
            // ss = ss < 10 ? ('0' + ss) : ss;

            // return y+'-'+m+'-'+d+' '+h+':'+mm+':'+ss
            return y+'-'+m+'-'+ d;
        },
        timeMonthStr(dataStr){
            var date = new Date(dataStr);
           
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;

            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;

            return m+'-'+ d;
        },
        setUserType(value){
            let str = ''
            if(value == 'Waiting') {
                str = 'business';
                this.$refs.busleft.tapActive('NODEAL');
            }else if (value == 'Existing'){
                str = 'business';
                this.$refs.busleft.tapActive('TRADING');
            }else {
                str = value;
            }
            this.userType = str;
        },


        inintColumn2() {
            let that = this;
            let data = [],xdata = [];
            // for (const key in this.appUser) {
            //     if (Object.hasOwnProperty.call(this.appUser, key)) {
            //         const element = this.appUser[key];
            //         data.push(element)
            //         xdata.push(key)
            //     }
            // }
            let  num = 0;
            let  arrvalue = this.appUser.length - 20;
            this.appUser.map((item,index) => {
                if(index < arrvalue) {
                    num =  num + item.userNum;
                }else if(index == arrvalue) {
                    data.push(item.userNum + num)
                }else {
                    data.push(item.userNum)
                }
                if(index >= arrvalue) {
                    let str = 'week'+ item.week
                    xdata.push(str)
                }
            })
            
            data = this.fomartData(data);
            let chartDom = document.getElementById('cpuColumn2');
            let myChart = echarts.init(chartDom);
            let option;
            option = {
                title: {
                    text: 'APP Download',
                    textStyle:{
                        color: '#fff',
                        fontSize: 15
                    },
                    top:'6%',
                    left:'4%'
                },
                grid: {
                    top:'20%',
                    left: '5%',
                    right: '5%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data:xdata,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:true,
                        lineStyle:{
                            color:'#FFFFFF',
                            opacity:'0.1'
                        },
                    },
                },
                series: [
                    {
                        name:'App User',
                        type: 'bar',
                        large:true,
                        barWidth : 30,
                        label:{
                            show:true,
                            color:'#fff',
                            fontSize:16,
                            position: 'top'
                        },
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: 'rgba(40,177,255,1)' // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(43,138,255,0)' // 0% 处的颜色
                                },
                            ],
                            global: false // 缺省为 false
                        },
                        data: data
                    }
                ]
            };

            option && myChart.setOption(option);
        },
        inintColumn3() {
            let that = this;
            let waitingCustomer = [],existingCustomer = [],xdata = [],sum = [];
            this.webUser.map(item=> { //写的死数据
                // if(item.mont == 'Oct-21'){
                //     item.waitingCustomer = 0;
                //     item.existingCustomer = 91;
                //     item.userCount = 91;
                // }else if(item.mont == 'Nov-21'){
                //     item.waitingCustomer = 0;
                //     item.existingCustomer = 188;
                //     item.userCount = 188;
                // }else if(item.mont == 'Oct-22'){
                //     item.waitingCustomer = 269;
                //     item.existingCustomer = 237;
                //     item.userCount = 506;
                // }else {
                //     item = item;
                // }
                waitingCustomer.push(item.waitingCustomer)
                existingCustomer.push(item.existingCustomer)
                sum.push(item.userCount)
                xdata.push(item.mont)
            })
            // waitingCustomer = this.fomartData(waitingCustomer)
            // existingCustomer = this.fomartData(existingCustomer)
            // sum = this.fomartData(sum)
            // for(let i=0;i<sum.length;i++){
            //     let obj =  {
            //         waitingCustomer:waitingCustomer[i],
            //         existingCustomer:existingCustomer[i],
            //         userCount:sum[i]
            //     }
            //     total.push(obj)
            // }

            let chartDom = document.getElementById('cpuColumn3');
            let myChart = echarts.init(chartDom);
            let option;
            option = {
                title: {
                    text: 'Web user',
                    textStyle:{
                        color: '#fff',
                        fontSize: 15
                    },
                    top:'6%',
                    left:'4%'
                },
                tooltip:{
                    show:true,
                    formatter: function(params) {
                        var  index = params.dataIndex;
                        var dotHtml =  `<div>
                        <div style="text-align:center"> Total <span style="margin-left:10px;">${sum[index]}</span></div>
                        <div style="text-align:center;">Waiting Customer</div>
                        <div style="margn-top:5px;display:flex;justify-content: space-between;align-items: center;">
                            <div style="border-radius:2px;width:30px;height:16px;background-color:rgba(1,201,135, 1);"></div>
                            <div>${params.name}</div>
                            <div>${waitingCustomer[index]}</div>
                        </div>
                        <div style="text-align:center;">Current Business user</div>
                        <div style="margn-top:5px;display:flex;justify-content: space-between;align-items: center;">
                            <div style="border-radius:2px;width:30px;height:16px;background-color:rgba(231, 196 ,1,1)"></div>
                            <div>${params.name}</div>
                            <div>${existingCustomer[index]}</div>
                        </div>`
                        return dotHtml;
                    }
                },
                
                legend: {
                    top:'10%',
                    right: '5%',
                    bottom: '5%',
                    data:[{
                        name:'Current Business user',
                        textStyle:{
                            color:'#fff'
                        },
                        itemStyle:{
                            color:'rgba(231, 196 ,1,1)'
                        },
                    },{
                        name:'Waiting Customer',
                        textStyle:{
                            color:'#fff'
                        },
                        itemStyle:{
                            color:'rgba(33,213,224, 1)'
                        },
                    }], 
                },

                grid: {
                    top:'30%',
                    left: '5%',
                    right: '5%',
                    bottom: '5%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: xdata,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:true,
                        lineStyle:{
                            color:'#FFFFFF',
                            opacity:'0.1'
                        },
                    },
                },
                series: [
                    {
                        name:'Current Business user',
                        type: 'bar',
                        large:true,
                        barWidth : 30,
                        // label:{
                        //     show:true,
                        //     color:'#fff',
                        //     fontSize:16,
                        //     position: 'top',
                        // },
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: 'rgba(231, 196 ,1,1) ' // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(254, 215, 1,0)' // 0% 处的颜色
                                },
                            ],
                            global: false // 缺省为 false
                        },
                        stack: 'Ad',
                        data: existingCustomer
                    },
                    {
                        name:'Waiting Customer',
                        type: 'bar',
                        large:true,
                        barWidth : 30,
                        // label:{
                        //     show:true,
                        //     color:'#fff',
                        //     fontSize:16,
                        //     position: 'top'
                        // },
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: 'rgba(33,213,224, 1)' // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(1,201,135, 1)' // 0% 处的颜色
                                },
                            ],
                            global: false // 缺省为 false
                        },
                        stack: 'Ad',
                        data: waitingCustomer
                    }
                ]
            };

            option && myChart.setOption(option);
        },
        fomartData(ary){
            let list = []
            for(let i = 0;i<ary.length;i++){
                if(i == 0) {
                    list.push(ary[i])
                }else {
                    list.push(ary[i]+list[i-1])
                }
            }
            return list;
        }
    },
    
}
</script>
<style scoped>
    .containers {
        width: 100%;
        height: 100%;
        padding: .25rem 1.25rem;
        box-sizing: border-box;
    }
    .container-main {
        width: 100%;
        height: 100%;
        background: url('../../assets/images/analysis.png') no-repeat;
        background-size: 100% 100%;
    }
    .container_bg {
        width: 100%;
        height: 100%;
        padding: .375rem 1.5rem;
        box-sizing: border-box;
    }
    .title {
        display: flex;
        justify-content:space-between;
        align-items:center;
        font-size: .4rem;
        font-weight: 500;
        color: #fff;
        position: relative;
    }
    /* .login img {
        width: .7375rem;
        height: .7375rem;
        vertical-align: middle;
    } */
    .back {
        width: .4625rem;
        height: .375rem;
    }
    .amount {
        margin-top: .25rem;
    }
    .amount_text {
        font-size:0.4625rem;
        font-weight: bold;
        color: #FFFFFF;
        background: linear-gradient(0deg, #fcc480 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .bottom_line{
        width: 3.25rem;
        height: .025rem;
        margin-top: .025rem;
        background: radial-gradient(ellipse,#00C8F1,#000);
        margin: .025rem auto;
    }
    .main {
        width: 100%;
        height:100%;
        margin-top: .125rem;
    }
    .main-top{
        height: 5.3875rem;
        width: 100%;
    }
    .main-top_bg {
        width: 50%;
        height: 100%;
        background: url('../../assets/images/left_top.png') no-repeat;
        background-size: 100% 100%;
    }
    #line1 {
        height: 4.2rem;
        width: 100%;
    }
    #line2 {
        width: 100%;
        height: 4.5375rem;
    }
    #cpuColumn{
        height: 4.2rem;
        width: 100%;
    }
    #cpuColumn2{
        height: 4.2rem;
        width: 100%;
    }
    #cpuColumn3{
        height: 4.2rem;
        width: 100%;
    }
    .linetime {
        z-index: 10000;
        width: 50%;
        background: rgba(250, 250, 250, 0.1);
        border: none;
        position: absolute;
        right: 10%;
    }
    .linetime1 {
        z-index: 10000;
        width: 50%;
        border: none;
        position: absolute;
        right: 10%;
    }
    /deep/ .el-range-editor--mini .el-range-input {
        background: rgba(250, 250, 250, 0);
        color: #fff;
    }
    /deep/ .el-range-editor--mini .el-range-separator {
        color: #fff;
    }
    /deep/ .el-date-editor .el-input__inner {
       background: rgba(250, 250, 250, 0.1);
        color: #fff;
        border: 0;
        text-align: center;
    }
    /deep/ .el-date-editor.el-input{
        width: 100%;
    }
    .main_center {
        padding: .625rem .9375rem;
        text-align: center;
        margin: 0  auto;
        position: relative;
    }
    .main-bottom {
        height: 4.5375rem;
        width: 100%;
        background: url('../../assets/images/analysis_bottombg.png') no-repeat;
        background-size: 100% 100%;
    }
    .block {
        position: absolute;
        bottom: 0;
        right: 12%;
    }
    .main-bottom_center {
        /* padding: .375rem 1.0625rem; */
        text-align: center;
        margin: 0  auto;
        position: relative;
    }
    .changelinetype{
        width: 2.5rem;
        position: absolute;
        right: 3%;
        top: 10%;
        color:#fff;
    }
    .logo_img {
        width: 2.5rem;
        height: .875rem;
        vertical-align: middle;
        text-align: left;
        display: inline-block;
    }

    /deep/ .el-table td.el-table__cell {
        border-bottom: 0;
        color: #fff;
        background-color: #091c38;
    }
    /deep/ .el-table::before {
        width: 0;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td{
        background-color: #235dc8 ;
    }
    /deep/ .el-table--enable-row-hover .el-table__body .blank_sty:hover>td{
        background-color: #091c38 !important;
    }
    /deep/ .el-pagination button:disabled {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination button {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager .number{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager li.active{
        color: #409EFF !important;
    }
    /deep/ .el-date-editor .el-input__inner {
       background: rgba(250, 250, 250, 0.1);
        color: #fff;
        border: 0;
        text-align: center;
    }

    /deep/ .el-table__empty-block {
        background: #091c38;
    }
    /deep/ .el-table__body-wrapper {
        background: #091c38;
    }
    /deep/ .el-table__header-wrapper {
        background: #091c38;
    }
    /deep/ .el-pager li.btn-quicknext {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pager li.btn-quickprev{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }

    /* 设置滚动条的样式 */
    ::-webkit-scrollbar {
        width: 2px !important; /*滚动条宽度*/
        height: 8px !important; /*滚动条高度*/
    }
    /* 滚动槽 */
    ::-webkit-scrollbar-track {
        border-radius: 0px !important; /*滚动条的背景区域的圆角*/
        background-color: rgba(2,29,87,1) !important; /*滚动条的背景颜色*/
    }
    /* 滚动条滑块 */
    ::-webkit-scrollbar-thumb {
        border-radius: 0px !important; /*滚动条的圆角*/
        background-color: rgba(24,144,255,0.50) !important; /*滚动条的背景颜色*/
    }
    ::-webkit-scrollbar-thumb:window-inactive {
        background-color: rgba(2,29,87,1) !important;
    }

    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 8px !important; /*滚动条宽度*/
        height: 8px !important; /*滚动条高度*/
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        border-radius: 0px !important; /*滚动条的背景区域的圆角*/
        background-color: rgba(2,29,87,1) !important; /*滚动条的背景颜色*/
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-corner {
        background-color: rgba(2,29,87,1) !important;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 4px !important; /*滚动条的圆角*/
        background-color: rgba(24,144,255,0.50) !important; /*滚动条的背景颜色*/
    }
    /deep/ .el-table .el-table__cell.gutter {
        background: rgb(20, 55, 120) !important;
    }

    /deep/ .el-loading-mask {
        background-color:rgba(7,29,56,.5)
    }
</style>