<template>
    <div class="main">
        <!-- <audio id="audio" src="../../assets/music/sou.mp3" muted style="width:1px;height:1px;"></audio> -->
        
        <!-- <div class="logo">
            <img src="../../assets/images/logo.png" border="0" alt="" class="logo_img">
            <div class="logo_time">
                <span style="color:#FFDB58;margin-left:5px;"> {{timeData.time}} </span>
            </div>
        </div> -->

        <div class="main_right"  @click="jumpTotal">
            <div class="store_deatil">
                <div class="main_right_top" >
                    <div class="main_right_title"> To Business</div>
                    <div class="main-month ">Dec 2021 - 
                    <span style="color:#FFDB58;margin-left:5px;"> {{timeData.day}} {{timeData.month}} {{timeData.year}} </span>
                    </div>
                    <div class="main_right_main">
                        <div class="main_right_main_item1">
                            <span class="main_right_main_item1_title">GMV</span>
                            <div class="main_right_main_item1_num">
                                <span class="gmvtitle">
                                    <span style="font-size:0.42rem;">£</span>
                                    <countTo :startVal='0' :endVal='totalData.businessData.totalGmv?totalData.businessData.totalGmv:0' :duration='1000' separator="," :decimals="2"></countTo>
                                </span>
                            </div>
                        </div>
                        <div class="main_right_main_item2">
                            <span class="main_right_main_item2_title">Users</span>
                            <div class="main_right_main_item2_num"> 
                                <span class="gmvtitle">
                                    <countTo :startVal='0' :endVal='totalData.businessData.totalUser ? totalData.businessData.totalUser : 0' :duration='1000' separator=","></countTo>
                                </span>
                            
                            </div>
                        </div>
                        <div class="main_right_main_item2">
                            <span class="main_right_main_item2_title">Orders</span>
                            <div class="main_right_main_item2_num">
                                <span class="gmvtitle">
                                    <countTo :startVal='0' :endVal='totalData.businessData.totalOrder?totalData.businessData.totalOrder:0' :duration='1000' separator=","></countTo>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main_right_top">
                    <div class="main_right_title"> To Consumer</div>
                    <div class="main-month">Aug 2022 - 
                        <span style="color:#FFDB58;margin-left:5px;"> {{timeData.day}} {{timeData.month}} {{timeData.year}} </span>    
                    </div>
                    <div class="main_right_main">
                        <div class="main_right_main_item1">
                            <span class="main_right_main_item1_title">GMV</span>
                            <div class="main_right_main_item1_num"><span class="gmvtitle">
                                <span style="font-size:0.42rem;">£</span>
                                <countTo :startVal='0' :endVal='totalData.storeData.sales?totalData.storeData.sales:0' :duration='1000' separator="," :decimals="2"></countTo>
                            </span></div>
                        </div>
                        <div class="main_right_main_item2">
                            <span class="main_right_main_item2_title">Users</span>
                            <div class="main_right_main_item2_num"><span class="gmvtitle">
                                <countTo :startVal='0' :endVal='totalData.storeData.userNum?totalData.storeData.userNum:0' :duration='1000' separator="," ></countTo>
                            </span></div>
                        </div>
                        <div class="main_right_main_item2">
                            <span class="main_right_main_item2_title">Orders</span>
                            <div class="main_right_main_item2_num"><span class="gmvtitle">
                                <countTo :startVal='0' :endVal='totalData.storeData.orderNum?totalData.storeData.orderNum:0' :duration='1000' separator=","></countTo>
                            </span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        
        <div id="map" v-loading="loading"
            element-loading-text="Loading..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 0.8)">
            <div id="viewDiv"></div>
        </div>
        <!-- <div class="main-bottom">
            <div class="main-bottom_main">
                <div class="main-bottom_main_img"></div>
                <div class="main-bottom_main_text">Partner Takeaway</div>
            </div>
            <div class="main-bottom_main" style="margin-top:0.2875rem">
                <div class="main-bottom_main_img1"></div>
                <div class="main-bottom_main_text">Other Takeaway</div>
            </div>
        </div> -->
    </div>
</template>
<script>
import { loadModules } from "esri-loader";
import countTo from 'vue-count-to';
export default {
    components: { countTo },
    props:{
        totalData:{
            type:Object,
            default() {
                return {}
            }
        },
        timeData:{
            type:Object,
            default() {
                return {
                    day:'',
                    month:'',
                    year:'',
                    time:''
                }
            }
        }
    },
    data() {
        return {
            clientHeight:0,
            earthRotateTimer:null,
            loading:false,
        }
    },
    mounted() {
        this.initArcgis()
        this.isFullscreenForNoScroll()
    },
    methods:{
        initArcgis(){
            var that = this;
            let options = {
                // url: "https://js.arcgis.com/4.15/",
                // css: "https://js.arcgis.com/4.15/esri/themes/light/main.css"
            };
            this.loading = true;
            if(document.getElementById('viewDiv')) {
                document.getElementById('map').removeChild(document.getElementById('viewDiv'))
            }
            let mapdom = document.createElement('div');
            mapdom.setAttribute("id","viewDiv")
            mapdom.style.width = '100%';
            mapdom.style.height = '100%';
            document.getElementById('map').appendChild(mapdom)

            loadModules(["esri/Map", "esri/views/SceneView","esri/config", "esri/WebScene","dojo/domReady!"], options).then(
                ([Map, SceneView,esriConfig,WebScene]) => {
                esriConfig.apiKey = "AAPK73b3c93a31ce4d7ca693387287e2b079bTw137q8RlF0T9tKs_cVsF-ZsPcC4rKqEg6kfpJowVqPC3rotX9n1mcSFjxAmXOG";
                // const scene = new Map({
                //     basemap:'arcgis-nova',//"arcgis-nova",//新星"arcgis-nova",, // 底图层 Basemap layer arcgis-topographic 白色地图  arcgis-imagery卫星地图
                //     // ground: "world-elevation" // 海拔服务 Elevation service
                // });
                const scene = new WebScene({
                    portalItem: {
                        id: "93fbbe9bdbfd474989dc292f2be3e6ea"
                    }
                });
                var view = new SceneView({
                    map: scene,
                    scale: 100000000,
                    container: "viewDiv",
				    center: [0, 50],
                    alphaCompositingEnabled: true,
                    environment: {
                        background: {
                            type: "color",
                            color: [255, 252, 244, 0]
                        },
                        // disable stars 禁用星星
                        starsEnabled: true,
                        //disable atmosphere 禁用气氛
                        atmosphereEnabled: false
                    },
                    padding:{
                        top:350
                    },
                    qualityProfile: "medium"
                });
                that.loading = false;
                view.when( () => {
                    view.on("click", (event) => {
                        if(event.mapPoint.latitude >= 50 && event.mapPoint.latitude <= 58 && event.mapPoint.longitude >= -7 && event.mapPoint.longitude <= 2) {
                            let data = {
                                type: true,
                                center:{}
                            }
                            that.$store.commit('changeMapdetail',data)
                            clearInterval(that.earthRotateTimer)
                            that.earthRotateTimer = null;
                        }
                    });
                    //.跳转至相应位置，并产生摆动的效果
                    // function customEasing(t) {
                    //     return 1 - Math.abs(Math.sin(-1.7 + t * 4.5 * Math.PI)) * Math.pow(0.5, t * 10);
                    // }
                    mapdom.addEventListener('mouseover',function(e){
                            clearInterval(that.earthRotateTimer)
                            that.earthRotateTimer = null;
                            view.goTo(
                                {
                                    position: {
                                        x: -3,
                                        y: 51.5287714,
                                        z: 2000000,
                                        spatialReference: {
                                            wkid: 4326
                                        }
                                    },
                                    // heading: -25,
                                    // tilt: 10
                                    heading: -10,
                                    tilt: 8
                                },
                                {
                                    duration: 2000
                                }
                            )
                    })
                    mapdom.addEventListener('mouseout',function(e){
                        view.goTo({
                        zoom: 2.9,
                        heading: 0,
                        tilt: 0,
                        center: [0, 30],
                        }, {
                            duration: 1000
                        }).then(function () {
                            earthRotate();
                        })
                    })
                    if(that.earthRotateTimer == null) {
                        earthRotate();
                    }
                    //地球旋转
                    function earthRotate() {
                        clearInterval(that.earthRotateTimer)
                        that.earthRotateTimer = null;
                        that.earthRotateTimer = setInterval(function () {
                            setTimeout(() => {
                                view.goTo(shiftCamera(60), {
                                    // animate: true,
                                    speedFactor: 0.1,
                                    easing: "linear",
                                })
                            },0)
                        }, 10);
        
                        function shiftCamera(deg) {
                            var camera = view.camera.clone();
                            camera.position.longitude += deg;
                            camera.position.latitude = 0;
                            camera.tilt = 0;
                            camera.heading = 0;
                            return camera;
                        }
                    }
                })

            })
        },
        isFullscreenForNoScroll(){
            var explorer = window.navigator.userAgent.toLowerCase();
            if(explorer.indexOf('chrome')>0){//webkit
                if (document.body.scrollHeight === window.screen.height && document.body.scrollWidth === window.screen.width) {
                    document.getElementById('map').style.height = document.documentElement.clientHeight-50+'px';
                } else {
                    document.getElementById('map').style.height = window.screen.height-50+'px';
                }
            }else{
                if (window.outerHeight === window.screen.height && window.outerWidth === window.screen.width) {
                    document.getElementById('map').style.height = document.documentElement.clientHeight-50+'px';
                } else {
                    document.getElementById('map').style.height = window.screen.height-50+'px';
                }
            }
        },
        playMusic() {
            var music = document.getElementById("audio");
            this.$nextTick(() =>{
                music.play()
            })
        },
        jumpTotal() {
            // this.$store.commit('changeTotal',true)
        },
        clearmapInterval() {
            clearInterval(this.earthRotateTimer)
            this.earthRotateTimer = null;
        }
    }
}
</script>
<style scoped>
    .main{
        position: relative;
    }
    .logo {
        position: absolute;
        top: 0;
        left: .35rem;
        font-size: .4rem;
        color: #fff;
        z-index: 1;
    }
    .logo_img {
        width: 2.5rem;
        height: .875rem;
        vertical-align: middle;
        text-align: left;
        display: inline-block;
    }
    .logo_title {
        display: inline-block;
        margin-left: .225rem;
    }
    .logo_time {
        font-size: .45rem;
        margin: 0.125rem 0;
    }
    .main_right {
        position: absolute;
        z-index: 1;
        width: 100%;
        /* top: .32rem;
        right: .375rem;
        display: flex; */
    }

    .store_deatil {
        width: 100%;
        margin-top: 0.8rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .main_right_title {
        font-size: .35rem;
        font-weight: bold;
        margin-bottom: .0625rem;
        color: #fff;
    }
    .main-month {
        font-size: .25rem;
        color: #fff;
        margin-bottom: .125rem;
        height: .5rem;
        display: flex;
        align-items: center;
    }
    .main_right_top{
        /* margin-bottom: .375rem; */
        text-align: left;
        background: url("../../assets/images/mian_bg.png") no-repeat;
        background-size: 100% 100%;
        padding: 0.4rem 0.7rem;
    }
    .main_right_main_item1 {
        /* margin-bottom: .25rem; */
        height: .625rem;
        line-height: .625rem;
    }
    .main_right_main_item1_title{
        /* font-size: .25rem; */
        /* font-weight: 500; */
        font-size:.25rem;
        color: #fff;
        /* font-weight: bold; */
        width: .875rem;
        display: inline-block;
    }
    .main_right_main_item1_num {
        font-size: .325rem;
        font-weight: 800;
        /* color: #FFFFFF; */
        background: linear-gradient(0deg, #FEE303 0%, #FEE303 100%);
        /* background: linear-gradient(0deg, #FFD4A0 0%, #FFFFFF 100%); */
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        margin-left: .1125rem;
    }
    .main_right_main_item2 {
        /* margin-bottom: .2375rem; */
        height: .625rem;
        line-height: .625rem;
    }
    .main_right_main_item2_title{
        font-size:.25rem;
        /* font-weight: bold; */
        color: #FFFFFF;
        width: 1.25rem;
        display: inline-block;
        width: .875rem;
    }
    .main_right_main_item2_num{
        font-size: .25rem;
        font-weight: 800;
        /* color: #FFFFFF; */
        background: linear-gradient(0deg, #FEE303 0%, #FEE303 100%);
        /* background: linear-gradient(0deg, #FFD4A0 0%, #FFFFFF 100%); */
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        margin-left:.125rem;
    }
    #map {
        width:100%;
    }
    #viewDiv {
        height: 100%;
        width: 100%;
    }
    #mapDiv {
        height: 100%;
        width: 100%;
    }
    .main-bottom {
        position: absolute;
        left: .35rem;
        bottom:.7625rem;
    }
    .main-bottom_main {
        display: flex;
        justify-content: start;
        align-items: center;
    }
    .main-bottom_main_img {
        width:.1375rem;
        height: .15rem;
        background: #FFF000;
        transform: rotate(45deg);
        border: none;
        box-shadow: -0.025rem 0 .075rem -0.0125rem #FFF000,
        0 -0.025rem .075rem -0.0125rem #FFF000,
        0 0.025rem .075rem -0.0125rem #FFF000,
        0.025rem 0 .075rem -0.0125rem #FFF000; 
        
    }
    .main-bottom_main_text {
        font-size: .25rem;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: .3125rem;
    }
    .main-bottom_main_img1 {
        width:.1375rem;
        height: .15rem;
        background: #00FFAE;
        border-radius: 50%;
        border: none;
        box-shadow: -0.025rem 0 .075rem -0.0125rem #00FFAE,
        0 -0.025rem .075rem -0.0125rem #00FFAE,
        0 0.025rem .075rem -0.0125rem #00FFAE,
        0.025rem 0 .075rem -0.0125rem #00FFAE; 
    }
    /deep/ .esri-ui-manual-container > .esri-component {
        display: none;
    }
    /deep/ .esri-ui {
        display: none;
    }
    .gmvtitle {
        font-family: 'myfont';
        font-size: .5rem;
    }
    @font-face {
        font-family: 'myfont';
        src: url("../../assets/font/DSDIGI.ttf");
    }
    .margin-r-10 {
        margin-right: .4375rem;
    }
    .custom_layout {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .marl20 {
        margin-left:.0625rem;
    }
    .main_right_main {
        margin-top: -0.0625rem;
    }
</style>