<template>
    <div :class="{blackbg:isShow}">
        <div class="container" v-if="isShow" style="position: relative">
            <div class="header_main">
                <div class="header_content">
                    <div class="header_item">
                        <div class="header-left"> <img src="../assets/images/logo.png" border="0" alt="" class="logo_img"></div>
                        <div class="header_date"><span class="header_text">{{timeData.day}} {{timeData.month}} {{timeData.year}}</span></div>
                        <div class="header-right"><span class="header_text"> {{timeData.time}} </span></div>
                    </div>
                </div>
            </div>

            <div class="container_left">
                <background-rank :backgroundData="rank1Object" class="business rank"  @click.native="JumpBusinessdetail()"></background-rank>
                <progress-rank :progressData="rankObject" class="consumer rank"  @click.native="JumpConsumerDetail()"></progress-rank>
                <number-rank :numberData="rank2Object" class="cpu rank"  @click.native="JumpCpudetail()"></number-rank>
            </div>
            <div class="container_center">
                <Maps :totalData="totalData" v-if="dataNum !=''" :timeData="timeData"  ref="maps"></Maps>
            </div>
            <div class="container_right">
                <arrow-rank :arrowData="rank3Object" class="shop" @click.native="JumpRank()"></arrow-rank>
                <fold-line :costStatistics="CostStatistics" class="annalysis" v-if="isFold" @click.native="JumpFoldline()"></fold-line>
                <cylindrical :cylindricalData="cylindricalData" v-if="isCylindrical" @click.native="JumpUser()" class="user" ></cylindrical>
            </div>
        </div>
        <div class="container_detail" v-if="!isShow">
            <transition name="bounce"  appear>
                <b-rank v-if="isBusiness"></b-rank>
            </transition>
            <transition name="bounce"  appear>
                <map-detail v-if="isMapdeatil" class="map_deatil"></map-detail>
            </transition>
            <transition name="bounce"  appear>
                <fold-detail v-if="isFoldshow"></fold-detail>
            </transition>
            <transition name="bounce"  appear>
                <cpu-detail v-if="isCpushow"></cpu-detail>
            </transition>
            <transition name="bounce"  appear>
                <consumer-detail v-if="isConsumer"></consumer-detail>
            </transition>
            <transition name="bounce"  appear>
                <rank-detail v-if="isRank" :salesTotal="totalData.storeData.sales"></rank-detail>
            </transition>
            <transition name="bounce"  appear>
                <user-detail v-if="isUser"></user-detail>
            </transition>
            <transition name="bounce"  appear>
                <total-gmv v-if="isToal"></total-gmv>
            </transition>
        </div>
    </div>
</template>
<script>
import {mapState,mapMutations} from 'vuex'
import progressRank from '../components/progressRank/index.vue'
import backgroundRank from '../components/backgroundRank/index.vue'
import numberRank from "../components/numberRank/index.vue"
import arrowRank from "../components/arrowRank/index.vue"
import foldLine from "../components/foldline/index.vue"
import cylindrical from "../components/cylindrical/index.vue"
import Maps from "../components/map/index.vue"
import MapDetail from "../components/map/mapDetail.vue"
import FoldDetail from "../components/foldline/detail.vue"
import cpuDetail from "../components/numberRank/detail.vue"
import bRank from "../components/backgroundRank/detail.vue"
import consumerDetail from "../components/progressRank/detail.vue"
import rankDetail from "../components/arrowRank/detail.vue"
import userDetail from "../components/cylindrical/detail.vue"
import totalGmv from "../components/map/totalGmv.vue"
import {getRank,getrankCPU,getStore,getStoresales,getBusinessTotal,getStoreTotal,getUserTotal,getAppUserTotal,getCostStatistics,getSixMonthUsers} from '../api/map'
import {getWebuserList} from '../api/user'
import {getrangeGMV} from '../api/business'
import {cpuRankList} from '../api/cpu'
export default {
    components:{
        backgroundRank,
        progressRank,
        numberRank,
        arrowRank,
        foldLine,
        cylindrical,
        Maps,
        MapDetail,
        FoldDetail,
        cpuDetail,
        bRank,
        consumerDetail,
        rankDetail,
        userDetail,
        totalGmv
    },
    computed:{
        ...mapState(['mapDetail','foldType','cpuType','businessType','consumerType','rankType','userType','totalType'])
    },
    watch:{
        'mapDetail':{
            handler: function (newValue, oldValue) {
                this.isMapdeatil = newValue.type;
                if(this.isMapdeatil == true){
                    this.isShow = false;
                }else {
                    this.isShow = true;
                }
            },
            deep: true
        },
        'foldType':{
            handler: function (newValue, oldValue) {
                this.isFoldshow = newValue;
                if(this.isFoldshow == true){
                    this.isShow = false;
                }else {
                    this.isShow = true;
                }
            },
            deep: true
        },
        'cpuType':{
            handler: function (newValue, oldValue) {
                this.isCpushow = newValue;
                if(this.isCpushow == true){
                    this.isShow = false;
                }else {
                    this.isShow = true;
                }
            },
            deep: true
        },
        "businessType":{
            handler: function (newValue, oldValue) {
                this.isBusiness = newValue;
                if(this.isBusiness == true){
                    this.isShow = false;
                }else {
                    this.isShow = true;
                }
            },
            deep: true
        },
        "consumerType":{
            handler: function (newValue, oldValue) {
                this.isConsumer = newValue;
                if(this.isConsumer == true){
                    this.isShow = false;
                }else {
                    this.isShow = true;
                }
            },
            deep: true
        },
        "rankType":{
            handler: function (newValue, oldValue) {
                this.isRank = newValue;
                if(this.isRank == true){
                    this.isShow = false;
                }else {
                    this.isShow = true;
                }
            },
            deep: true
        },
        "userType":{
            handler: function (newValue, oldValue) {
                this.isUser = newValue;
                if(this.isUser == true){
                    this.isShow = false;
                }else {
                    this.isShow = true;
                }
            },
            deep: true
        },
        "totalType" :{
            handler: function (newValue, oldValue) {
                this.isToal = newValue;
                if(this.isToal == true){
                    this.isShow = false;
                }else {
                    this.isShow = true;
                }
            },
            deep: true
        },
        "isShow": {
            handler(newValue,oldValue) {
                if(newValue == true) {
                    this.initFunction()
                }else {
                    this.destroyedFunction()
                }
            },
            deep:true
        }
    },
    data() {
        return {
            isShow:true,
            rankObject:{
                title:'Dishes Data 菜品數據',
                title1:'Sales',
                list:[]
            },
            rank1Object: {
                title:'Supply Chain 供应链',
                title1:'Sales',
                name1:'To Business',
                list:[]
            },
            rank2Object:{
                title:'CPU Production 半成品',
                title1:'Sales',
                list:[]
            },
            rank3Object:{
                title:'Takeaway Data 門店數據',
                title1:'Weekly',
                name:"Shop name",
                name1:"Sales",
                list:[]
            },
            totalData:{
                businessData:{},
                storeData:{}
            },
            cylindricalData:{
                userTotal:{},
                appuserTotal:{}
            },
            CostStatistics:{},
            isFold:false,
            isCylindrical:false,
            isMapdeatil:false,
            isFoldshow:false,
            isCpushow:false,
            isBusiness:false,
            isConsumer:false,
            isRank:false,
            isUser:false,
            isToal:false,
            setTime:null,
            dataNum:'',
            timeData:{
                day:'',
                month:'',
                year:'',
                time:''
            },
            showTimer:null
        }
    },
    created() {
        this.initFunction()
    },
    destroyed(){
        this.destroyedFunction()
    },
    methods:{
        initFunction() {
            //初始化方法
            this.getRank()
            this.getrankCPU()
            this.getStore()
            this.getStoresales()
            this.getBusinessTotal()
            this.getStoreTotal()
            this.getUserTotal()
            this.getAppUserTotal()
            this.getCostStatistics()
            //加载定时器
            this.setTimeFuction()
        },
        destroyedFunction(){
            clearInterval(this.setTime)
            this.setTime = null;
            clearInterval(this.showTimer)
            this.showTimer = null;
        },
        setTimeFuction() {
            var that = this;
            clearInterval(this.setTime)
            this.setTime = null;
            this.initData()
            this.setTime = setInterval(()=> {
                setTimeout(() => {
                    this.getRank();
                    this.getrankCPU();
                    this.getStore();
                    this.getStoresales();
                    this.getBusinessTotal();
                    this.getStoreTotal();
                    this.getUserTotal();
                    this.getAppUserTotal();
                    this.getCostStatistics();
                }, 0)
            },that.$ajaxTime)
        },
        initData() {
            this.rankObject.list = [];
            this.rank1Object.list = [];
            this.rank2Object.list = [];
            this.rank3Object.list = [];
            this.totalData = {
                businessData:{},
                storeData:{}
            }
            this.cylindricalData = {
                userTotal:{},
                appuserTotal:{}
            }
            this.CostStatistics = {}
        },
         //business
        getRank() {
            getRank().then(res => {
                let  list = this.maplistData(res.data.data,this.rank1Object);
                let reslist = JSON.parse(JSON.stringify(list))
                reslist.map(item => {
                    item.total = 0;
                })
                this.rank1Object.list = reslist;
                setTimeout(()=> {
                    this.rank1Object.list = list;
                },100)
            })
        },
        //cpu
        getrankCPU() {
            cpuRankList().then (res => {
                this.cpuWeek = [];
                this.cpuWeek = res.data.data;
                let  list = this.mapData(this.cpuWeek,this.rank2Object);
                let reslist = JSON.parse(JSON.stringify(list))
                reslist.map(item => {
                    item.amount = 0;
                })
                this.rank2Object.list = reslist;
                setTimeout(()=> {
                    this.rank2Object.list = list;
                },100)
            })
           
        },
        //to consumer
        getStore() {
            getStore().then(res => {
                let  list = this.mapstoreData(res.data.data,this.rankObject);
                let reslist = JSON.parse(JSON.stringify(list))
                reslist.map(item => {
                    item.sales = 0;
                })
                this.rankObject.list = reslist;
                setTimeout(()=> {
                    this.rankObject.list = list;
                },100)
            })
        },
        //shop ranking
        getStoresales() {
            getStoresales().then(res => {
                let  list = this.mapStoresalesData(res.data.data,this.rank3Object);
                let reslist = JSON.parse(JSON.stringify(list))
                reslist.map(item => {
                    item.sales = 0;
                })
                this.rank3Object.list = reslist;
                setTimeout(()=> {
                    this.rank3Object.list = list;
                },100)
            })
        },
        async getBusinessTotal() {
            let parmas = {
                beginDate: '2022-03-01',
                endDate: this.timeStr(new Date())
            }
            let result = await getrangeGMV(parmas).then(res => {
                if(res.data.code == 200){
                    if(res.data.data.hasOwnProperty('gmv')) {
                        return  res.data.data.gmv
                    }else {
                      return 0;
                    }
                }
            })
            getBusinessTotal().then(res => {
                let s = res.data.data;
                s.totalGmv = 4341753.27 + result + 1191966.15 + 121350 + 105329;
                s.totalUser = 529;//写的死 数据
                this.totalData.businessData = s;
                this.newTimer(res.headers.date)
                this.dataNum = res.headers.date;
                sessionStorage.setItem('businessUser',s.totalUser)
                sessionStorage.setItem('existingCustomer',s.existingCustomer)
                sessionStorage.setItem('waitingCustomer',s.waitingCustomer)
            })
        },
        getStoreTotal() {
            getStoreTotal().then(res => {
                this.totalData.storeData = res.data.data[0];
                sessionStorage.setItem('consumerUser',this.totalData.storeData.userNum)
            })
        },
        //user
        getUserTotal() {

            getWebuserList({"beginDate":"2022-10","endDate":"2023-03"}).then(res => {
                let datas = JSON.parse(JSON.stringify(res.data.data));
                this.cylindricalData.userTotal = {data:[],totalUser:0}
                setTimeout(() => {
                   this.cylindricalData.userTotal = {
                        data:datas,
                        totalUser: sessionStorage.getItem('businessUser')*1
                    }; 
                },100)
                this.isCylindrical = true;
            })
        },
        //app user
        getAppUserTotal() {

            getSixMonthUsers({start:"2022-10",end:"2023-03"}).then(res => {
                this.cylindricalData.appuserTotal = {data:[],userTotal:0}
                setTimeout(() => {
                    this.cylindricalData.appuserTotal = {data:res.data.data,userTotal: sessionStorage.getItem('consumerUser')*1};
                },100)
            })
        },
        getCostStatistics() {
            getCostStatistics().then(res => {
                let result = this.mapcostData(res.data.data);
                this.isFold = true;
                this.CostStatistics = result;
            })
        },
        JumpBusinessdetail() {
            this.$store.commit('changeBusinessShow',true)
            clearInterval(this.setTime)
            this.setTime = null;
            this.$refs.maps.clearmapInterval()
        },
        JumpFoldline() {
            this.$store.commit('changeFoldshow',true)
            clearInterval(this.setTime)
            this.setTime = null;
            this.$refs.maps.clearmapInterval()
        },
        JumpCpudetail() {
            this.$store.commit('changeCpushow',true)
            clearInterval(this.setTime)
            this.setTime = null;
            this.$refs.maps.clearmapInterval()
        },
        JumpConsumerDetail() {
            this.$store.commit('changeConsumershow',true)
            clearInterval(this.setTime)
            this.setTime = null;
            this.$refs.maps.clearmapInterval()
        },
        JumpRank() {
            this.$store.commit('changeRankshow',true)
            clearInterval(this.setTime)
            this.setTime = null;
            this.$refs.maps.clearmapInterval()
        },
        JumpUser() {
            this.$store.commit('changeUsershow',true)
            clearInterval(this.setTime)
            this.setTime = null;
            this.$refs.maps.clearmapInterval()
        },
        mapData(news,old) {
            const list = JSON.parse(JSON.stringify(old.list))
            if(list.length > 0) {
                news.map(t => {
                    list.map(x => {
                        if(t.code == x.code){
                            if(t.amount > x.amount) {
                                t.falge = 'up';
                            }else if(t.amount = x.amount){
                                t.falge = 'balance';
                            }else {
                                t.falge = 'down';
                            }
                        }else {
                            t.falge = 'balance';
                        }
                    })
                })
            }else {
                news.map(item => {
                    item.falge = 'balance';
                    list.push(item)
                })
            }
            return news;
        },
        maplistData(news,old) {
            const list = JSON.parse(JSON.stringify(old.list))
            if(list.length > 0) {
                news.map(t => {
                    list.map(x => {
                        if(t.goodsCode == x.goodsCode){
                            if(t.total > x.total) {
                                t.falge = 'up';
                            }else if(t.total = x.total){
                                t.falge = 'balance';
                            }else {
                                t.falge = 'down';
                            }
                        }else {
                            t.falge = 'balance';
                        }
                    })
                })
            }else {
                news.map(item => {
                    item.falge = 'balance';
                    list.push(item)
                })
            }
            return news;
        },
        mapstoreData(news,old) {
            const list = JSON.parse(JSON.stringify(old.list))
            if(list.length > 0) {
                news.map(t => {
                    list.map(x => {
                        if(t.dishesName == x.dishesName){
                            if(t.sales > x.sales) {
                                t.falge = 'up';
                            }else if(t.sales = x.sales){
                                t.falge = 'balance';
                            }else {
                                t.falge = 'down';
                            }
                        }else {
                            t.falge = 'balance';
                        }
                    })
                })
            }else {
                news.map(item => {
                    item.falge = 'balance';
                    list.push(item)
                })
            }
            return news;
        },
        mapStoresalesData(news,old) {
            const list = JSON.parse(JSON.stringify(old.list))
            if(list.length > 0) {
                news.map(t => {
                    list.map(x => {
                        if(t.storeName == x.storeName){
                            if(t.sales > x.sales) {
                                t.falge = 'up';
                            }else if(t.sales = x.sales){
                                t.falge = 'balance';
                            }else {
                                t.falge = 'down';
                            }
                        }else {
                            t.falge = 'balance';
                        }
                    })
                })
            }else {
                news.map(item => {
                    item.falge = 'balance';
                    list.push(item)
                })
            }
            return news;
        },
        mapcostData(data) {
            var costObj = {
                foodData: [], 
                labourData: [],
                ebithaData: [],
                ebithaTotal:data.ebitha ,
                foodCostTotal:data.food,
                labourTotal:data.labour
            };
            data.list.map(item => {
                costObj.foodData.push(item.food)
                costObj.labourData.push(item.labour)
                costObj.ebithaData.push(item.ebitha)
            })
            return costObj;
        },
        timeStr(dataStr){
            var date = new Date(dataStr);
            var y = date.getFullYear();

            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;

            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;

            // var h = date.getHours();
            // h = h < 10 ? ('0' + h) : h;

            // //获得分
            // var mm = date.getMinutes()
            // mm = mm < 10 ? ('0' + mm) : mm;

            // //获得秒
            // var ss = date.getSeconds()
            // ss = ss < 10 ? ('0' + ss) : ss;

            // return y+'-'+m+'-'+d+' '+h+':'+mm+':'+ss
            return y+'-'+m+'-'+ d;
        },
        newTimer(dataNum) {
            var s =  Date.parse(new Date(dataNum));
            clearInterval(this.showTimer)
            this.showTimer = null;
            this.showTimer = setInterval(() => {
                s+=1000;
                setTimeout(()=> {
                    this.showTime(s)
                },0)
            },1000)
        },

        showTime(s){
            var dateTime = new Date(s);
            var year = dateTime.getFullYear();
            var month = dateTime.getMonth();
            var date = dateTime.getDate();
            var hour = dateTime.getHours();
            hour=hour<10?"0"+hour:hour;
            var minute = dateTime.getMinutes();
            minute=minute<10?"0"+minute:minute;
            var second = dateTime.getSeconds();
            second=second<10?"0"+second:second;
            var monthAry = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
            this.timeData = {
                time: hour+":"+minute+':'+second,
                month:monthAry[month],
                day:date,
                year:year
            }
        }
    }
}
</script>
<style scoped>
    .container {
        width: 100%;
        height: 100%;
        /* min-height: 13.5rem; */
        display: flex;
        flex-wrap:nowrap;
        justify-content:space-between;
        align-content:center;
        padding: .3rem .2rem .15rem .2rem;
        /* padding: .45rem .2rem .1875rem .2rem;  */
        box-sizing: border-box;
    }

    .container_left {
        align-items: flex-start;
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction:column;
    }
    .container_center {
        align-items: center;
        width: 50%;
        height: 100%;
    }
    .container_right {
        align-items: flex-end;
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction:column;
    }
    .business {
        min-height: 4.1754rem;
         /* 3.7296rem; */
    }
    .consumer {
        min-height: 3.7185rem;
    }
    .cpu {
        min-height: 4.3229rem;
    }
    .rank {
        width: 100%;
        margin-bottom:.2rem;
    }
    .rank:last-child {
        margin-bottom:0rem;
    }
    .container_detail{
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
    .map_deatil{
       height: 100%;
       width: 100%; 
       box-sizing: border-box;
    }
    .shop {
        margin-bottom:0.2rem;
        min-height: 3.925rem;
    }
    .annalysis{
        margin-bottom:0.2rem;
        /* min-height: 3.6375rem; */
        min-height: 3.3375rem;
    }
    .user {
        /* min-height: 3.9625rem; */
        min-height: 3.9025rem;
    }
    .bounce-enter-active {
        animation: bounce-in 1s;
    }
    /* .bounce-leave-active {
        animation: bounce-in 1s reverse;
    } */
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    .blackbg {
        background: url('../assets/images/blackbg.png') no-repeat;
        background-size: 100% 100%;
    }
</style>