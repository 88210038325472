<template>
    <div class="containers">
        <div class="container-main">
            <div class="container_bg">
                <div class="title">
                    <div class="login">
                        <img src="../../assets/images/logo.png" alt="" class="logo_img">
                        <!-- <span> Pacific Foods</span> -->
                    </div>
                    <div class="header-title">
                        <span> Dishes Data </span>
                    </div>
                    <div class="back" @click="backMap">
                        <img src="../../assets/images/quit.png" alt="">
                    </div>
                </div>
                <!-- <div class="amount">
                    <el-row>
                        <el-col :span="8">
                           <div><span style="font-size:0.35rem;color: #FFFFFF;">Total Sales <span class="amount_text">3448.00</span></span></div>
                            <div class="bottom_line"></div>
                        </el-col>
                        <el-col :span="8">
                            <div><span style="font-size:0.35rem;color: #FFFFFF;">Weekly Sales <span class="amount_text">3448.00</span></span></div>
                            <div class="bottom_line"></div>
                        </el-col>
                        <el-col :span="8">
                            <div><span style="font-size:0.35rem;color: #FFFFFF;">Daily Sales <span class="amount_text">3448.00</span></span></div>
                            <div class="bottom_line"></div>
                        </el-col>
                    </el-row>
                </div> -->
                <div class="main">
                    <div class="main-top">
                        <el-col :span="12" class="main-top_bg">
                            <div class="main_center">
                                <!-- <div class="linetime1">
                                    <el-col :span="11">
                                        <el-date-picker
                                            v-model="weekLimit.start"
                                            type="date"
                                            placeholder="选择日期"
                                            size="mini"
                                            @change="changeStart1"
                                            >
                                        </el-date-picker>
                                    </el-col>
                                    <el-col :span="2">
                                        <div style="color:#fff;padding: 0 7px;margin: 13px 0;"><div style="width:100%;height:1px;background:#fff;"></div></div>
                                    </el-col>
                                    <el-col :span="11">
                                        <el-date-picker
                                            v-model="weekLimit.end"
                                            type="date"
                                            placeholder="选择日期"
                                            size="mini"
                                            @change="changeEnd1">
                                        </el-date-picker>
                                    </el-col>
                                </div> -->
                                
                                <el-dropdown @command="handleDate" placement="bottom">
                                    <span class="el-dropdown-link selector-sty">
                                        {{dateTypeFont}} <i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :command="{name:'Year',id:0}">Year</el-dropdown-item>
                                        <el-dropdown-item :command="{name:'Month',id:1}">Month</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                <span style="display:inline-block;width:0.2rem;"></span>
                                <el-date-picker
                                    v-model="weekLimit.searchYear"
                                    v-if="isYear == 0"
                                    type="year"
                                    format="yyyy"
                                    align="center"
                                    size="mini"
                                    style="width: 180px"
                                    placeholder="请选择"
                                    @change="changeYearrange">
                                </el-date-picker>
                                <el-date-picker
                                    v-model="weekLimit.searchDate"
                                    v-if="isYear == 1"
                                    type="month"
                                    format="yyyy-MM"
                                    align="center"
                                    size="mini"
                                    style="width: 180px"
                                    placeholder="请选择"
                                    @change="changeMonthrange">
                                </el-date-picker>
                                <!-- height="3.6575rem" -->
                                <el-table
                                    ref="table1"
                                    :data="consumerWeek.data"
                                    :header-cell-style="{background:'#143778',color:'#71CDF9',fontSize: '0.2375rem',border:'0px'}"
                                    :row-style="{height:'0.5225rem'}"
                                    style="width: 100%;margin-top:0.1875rem;"
                                    @row-click="weekRowclick"
                                    :row-class-name="tableRowClassNameweek"
                                    @sort-change="sortFun"
                                    :default-sort = "{prop: 'buyNum', order: 'descending'}"
                                    size="mini">
                                    <template slot="empty">
                                        <span style="color: #969799;">No data</span>
                                    </template>
                                    <el-table-column
                                    prop="dishesName"
                                    label="Name"
                                    show-overflow-tooltip
                                    align="left"
                                    :formatter="dishesNameFormatter"
                                    min-width="30%">
                                    </el-table-column>
                                    <el-table-column
                                    show-overflow-tooltip
                                    align="left"
                                    min-width="30%">
                                        <template slot="header" slot-scope="scope">
                                            <el-dropdown placement="bottom" trigger="click" @command="handleCommand2" size="mini">
                                                <span>
                                                    Store<i class="el-icon-arrow-down el-icon--right"></i>
                                                </span>
                                                <el-dropdown-menu slot="dropdown" class="dropdown-menu">
                                                    <el-dropdown-item v-for="item in classList"
                                                    :key="item.code" :command="item.code">
                                                    {{item.labelEn}}
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </template>
                                        <template slot-scope="scope"> 
                                            <div class="category_name">
                                            {{scope.row.storeName}}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                    prop="buyNum"
                                    label="QTY"
                                    align="right"
                                    sortable="custom"
                                    min-width="20%">
                                    </el-table-column>
                                    <el-table-column
                                    prop="amount"
                                    label="Sales"
                                    show-overflow-tooltip
                                    align="right"
                                    sortable="custom"
                                    min-width="20%">
                                    <template slot-scope="scope">
                                        ￡{{scope.row.amount}}
                                    </template>
                                    </el-table-column>
                                </el-table>
                                <div class="block"  v-if="consumerWeek.size >= 5">
                                    <el-pagination
                                        layout="prev, pager, next"
                                        :pager-count="5"
                                        :page-size="weekLimit.limit"
                                        :current-page="weekLimit.index"
                                        @current-change="week_current_change"
                                        :total="consumerWeek.size">
                                    </el-pagination>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12" class="main-top_bg">
                            <div class="main_center">
                                <el-date-picker
                                    v-model="dayLimit.day"
                                    type="date"
                                    placeholder="选择日期"
                                    size="mini"
                                    @change="changeDay">
                                </el-date-picker>
                                <el-table
                                    :data="consumerDay.data"
                                    :header-cell-style="{background:'#143778',color:'#71CDF9',fontSize: '0.2375rem',border:'0px'}"
                                    :row-style="{height:'0.5225rem'}"
                                    style="width: 100%;margin-top:0.1875rem;"
                                    @row-click="dayRowclick"
                                    :row-class-name="tableRowClassNameday"
                                    :default-sort = "{prop: 'buyNum', order: 'descending'}"
                                    @sort-change="sortDayFun"
                                    size="mini">
                                    <template slot="empty">
                                        <span style="color: #969799;">No data</span>
                                    </template>
                                    <el-table-column
                                    prop="dishesName"
                                    label="Name"
                                    show-overflow-tooltip
                                    align="left"
                                    min-width="30%">
                                    </el-table-column>

                                    <el-table-column
                                    show-overflow-tooltip
                                    align="left"
                                    min-width="30%">
                                        <template slot="header" slot-scope="scope">
                                            <el-dropdown placement="bottom" trigger="click" @command="handleCommand3" size="mini">
                                                <span>
                                                    Store<i class="el-icon-arrow-down el-icon--right"></i>
                                                </span>
                                                <el-dropdown-menu slot="dropdown" class="dropdown-menu">
                                                    <el-dropdown-item v-for="item in classList2"
                                                    :key="item.code" :command="item.code">
                                                    {{item.labelEn}}
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </template>
                                        <template slot-scope="scope"> 
                                            <div class="category_name">
                                            {{scope.row.storeName}}
                                            </div>
                                        </template>
                                    </el-table-column>

                                    
                                    <el-table-column
                                    prop="buyNum"
                                    label="QTY"
                                    align="right"
                                    sortable="custom"
                                    min-width="20%">
                                    </el-table-column>
                                    <el-table-column
                                    prop="amount"
                                    label="Sales"
                                    show-overflow-tooltip
                                    align="right"
                                    sortable="custom"
                                    min-width="20%">
                                    <template slot-scope="scope">
                                        ￡{{scope.row.amount}}
                                    </template>
                                    </el-table-column>
                                    
                                </el-table>
                                <div class="block"  v-if="consumerDay.size >= 5">
                                    <el-pagination
                                        layout="prev, pager, next"
                                        :pager-count="5"
                                        :page-size="dayLimit.limit"
                                        :current-page="dayLimit.index"
                                        @current-change="current_change"
                                        :total="consumerDay.size">
                                    </el-pagination>
                                </div>
                            </div>
                        </el-col>
                    </div>
                    <div class="main-bottom">
                        <el-col :span="12">
                            <div class="main-bottom_center">
                                <div id="cpuColumn"></div>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="main-bottom_center">
                                <div id="line1"></div>
                            </div>
                        </el-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import {getConsumerDaysales,getConsumerWeeksales,getWeeksales,getDaysales} from '../../api/consumer'
export default {
    data() {
        return {
            consumerWeek:{
                data:[],
                size:0
            },
            consumerDay:{
                data:[],
                size:0
            },
            weekLimit:{
                index: 1,
                limit:5,
                all:'',
                searchDate:'',
                searchYear:'',
                storeId:''//0全部 1 A，2 G
                // start:'',
                // end:""
            },
            dayLimit:{
                index: 1,
                limit:5,
                storeId:'',//0全部 1 A，2 G
                day:''
            },
            consumerData:{},
            columnData:{},
            lineData:[],
            weekRowindex:0,
            dayRowindex:0,
            classList:[
                {code:'1562418001185206273',labelEn:'Wok Warrior - Aylesbury'},
                {code:'1481883219968749570',labelEn:'Wok Warrior - Grange Farm'},
                {code:'1501503511858864130',labelEn:'Cheng Du'},
                {code:'1501501562480582657',labelEn:'Dragon Boat'},
                {code:'',labelEn:'ALL'}
            ],
            classList2:[
                {code:'1562418001185206273',labelEn:'Wok Warrior - Aylesbury'},
                {code:'1481883219968749570',labelEn:'Wok Warrior - Grange Farm'},
                {code:'1501503511858864130',labelEn:'Cheng Du'},
                {code:'1501501562480582657',labelEn:'Dragon Boat'},
                {code:'',labelEn:'ALL'}
            ],
            isYear:1,//0按照年 1按照月
            dateTypeFont:'Month',
            numSort:2,//排序方式数量(1正序，2倒序)
            saleSort:'',//排序方式金额(1正序，2倒序)
            numSortDay:2,//排序方式数量(1正序，2倒序)
            saleSortDay:'',//排序方式金额(1正序，2倒序)
            // pickerOptions:{
            //     shortcuts: [{
            //         text: '全部',
            //         onClick(picker) {
            //             // let date = new Date()
            //             // let month = date.getMonth();
            //             // let start = date.setMonth(0);
            //             // let end = date.setMonth(month + 11-month);
            //             // picker.$emit('pick', [1,11]);
            //             picker.$emit('pick', [0]);
            //         }
            //     }]
            // },
        }
    },
    created(){
        this.initFunction()
    },
    // mounted() {
    //     // 获取需要绑定的table
    //     this.dom = this.$refs.table1.bodyWrapper
    //     this.dom.addEventListener('scroll', () => {
    //         // 表格滚动条滚动的距离
    //         let scrollTop = this.dom.scrollTop
    //         // 变量windowHeight是可视区的⾼度
    //         let windowHeight = this.dom.clientHeight
    //         // 变量scrollHeight是滚动条的总⾼度
    //         let scrollHeight = this.dom.scrollHeight
    //         if(scrollTop + windowHeight === scrollHeight) { // 这里就是滚动条滚动到底部的时候
    //             // 获取到的不是全部数据当滚动到底部继续获取新的数据
    //             this.weekLimit.index ++;
    //             this.weekRowindex = 0;
    //             this.getConsumerWeeksales()
    //         }
    //     })
    // },
    methods:{
        initFunction() {
            // this.weekLimit.end = new Date();
            // this.changeEnd1(this.weekLimit.end)

            this.dayLimit.day = this.getPreDate();
            
            // this.getTotalSales()
            this.getConsumerDaysales()

            let date = new Date;
            this.weekLimit.searchYear = date;
            this.weekLimit.searchDate = date;
            this.getConsumerWeeksales()
        },
        sortFun(item){
            if(item.prop == "buyNum"){//按数量排序
                if(item.order == "descending"){
                    this.numSort = 2; 
                }else{
                    this.numSort = 1;
                }
                this.saleSort = '';
            }else if(item.prop == "amount"){//按金额排序
                if(item.order == "descending"){
                    this.saleSort = 2;
                }else{
                    this.saleSort = 1;
                }
                this.numSort = '';
            }
            this.getConsumerWeeksales();
        },
        sortDayFun(item){
            if(item.prop == "buyNum"){//按数量排序
                this.numSortDay == 1?this.numSortDay = 2:this.numSortDay = 1;
                this.saleSortDay = '';
            }else if(item.prop == "amount"){//按金额排序
                this.saleSortDay == 1?this.saleSortDay = 2:this.saleSortDay = 1;
                this.numSortDay = '';
            }
            this.getConsumerDaysales();
        },
        getConsumerWeeksales() {
            let parmas = '';
            if(this.isYear == 0){//按照年查询
                parmas = {
                    index: this.weekLimit.index,
                    limit:this.weekLimit.limit,
                    saleSort:this.saleSort,
                    numSort:this.numSort,
                    storeId:this.weekLimit.storeId,
                    year:this.getYearNum(this.weekLimit.searchYear),
                    date: ''
                }
            }else{
                parmas = {
                    index: this.weekLimit.index,
                    limit:this.weekLimit.limit,
                    saleSort:this.saleSort,
                    numSort:this.numSort,
                    storeId:this.weekLimit.storeId,
                    year:'',
                    date: this.getMonthNum(this.weekLimit.searchDate)
                }
            }
            getConsumerWeeksales(parmas).then(res => {
                if(res.data.code == 1){
                    if(res.data.data.data && res.data.data.data.length > 0) {
                        
                        this.consumerWeek.data = res.data.data.data;
                        this.consumerWeek.size = res.data.data.size*1;
                        this.getWeeksales(this.consumerWeek.data[0])
                        this.weekRowindex = 0;
                    }else {
                        this.consumerWeek = {
                            data:[],
                            size:0
                        }
                        this.columnData = [];
                        this.$nextTick(()=> {
                            let chartDom = document.getElementById('cpuColumn');
                            chartDom.innerHTML = 'No Data';
                            chartDom.style.cssText = 'text-align:center; color: #fff; border: none;line-height: 250px;font-size:15px;';
                            chartDom.removeAttribute('_echarts_instance_');
                        })
                    }
                }
            })
        },
        dishesNameFormatter(row, column) {
            return this.htmlDecodeByRegExp(row.dishesName);
        },
        htmlDecodeByRegExp(str) {
            var s = "";
            if (str.length == 0) return "";
            s = str.replace(/&amp;/g, " ");
            s = s.replace(/&lt;/g, "<");
            s = s.replace(/&gt;/g, ">");
            s = s.replace(/&nbsp;/g, " ");
            s = s.replace(/'/g, "\'");
            s = s.replace(/"/g, "\"");
            return s;
        },
        getConsumerDaysales() {
            let parmas = {
                index: this.dayLimit.index,
                limit:this.dayLimit.limit,
                storeId:this.dayLimit.storeId,
                saleSort:this.saleSortDay,
                numSort:this.numSortDay,
                day: this.timeStr(this.dayLimit.day)
            }
            getConsumerDaysales(parmas).then(res => {
                if(res.data.code == 1){
                    if(res.data.data.data && res.data.data.data.length > 0) {
                        this.consumerDay.data = res.data.data.data;
                        this.consumerDay.size = res.data.data.size*1;
                        this.getDaysales(this.consumerDay.data[0])
                        this.dayRowindex = 0;
                    }else {
                        this.consumerDay = {
                            data:[],
                            size:0
                        }
                        this.lineData = [];
                        this.$nextTick(()=> {
                            let chartDom = document.getElementById('line1');
                            chartDom.innerHTML = 'No Data';
                            chartDom.style.cssText = 'text-align:center; color: #fff; border: none;line-height: 250px;font-size:15px;';
                            chartDom.removeAttribute('_echarts_instance_');
                        })
                    }
                }
            })
        },
        getWeeksales(item) {
            getWeeksales({dishesId: item.dishesId,date: this.getMonthNum(this.weekLimit.searchDate)}).then(res => {
                if(res.data.code == 1) {
                    this.columnData = [];
                    this.columnData = res.data.data;
                    this.inintColumn()
                }
            })
        },
        getDaysales(item) {
            getDaysales({dishesId: item.dishesId, date: this.timeStr(this.dayLimit.day)}).then(res => {
                if(res.data.code == 1) {
                    this.lineData = [];
                    this.lineData = res.data.data;
                    this.initLine1()
                }
            })
        },
        initLine1() {
            let data = [this.lineData.onePrice,this.lineData.twoPrice,this.lineData.threePrice,this.lineData.fourPrice,this.lineData.fivePrice,this.lineData.sixPrice,this.lineData.sevenPrice]
            let xdata = [this.lineData.oneDay,this.lineData.twoDay,this.lineData.threeDay,this.lineData.fourDay,this.lineData.fiveDay,this.lineData.sixDay,this.lineData.sevenDay]
            var rageSum = 0
            data.map(item => {
                rageSum += item
            })
            let rage = Math.ceil(rageSum/7)
            let rageData = [rage,rage,rage,rage,rage,rage,rage]
            var chartFold = document.getElementById('line1');
            let myFold  = echarts.init(chartFold);
            myFold.showLoading({
                text: 'loading',
                color: '#409eff',
                textColor: '#fff',
                maskColor: 'rgba(0, 0, 0, 0.1)',
                zlevel: 0,
            });;
            setTimeout(() => {
                    // setOption前隐藏loading事件 
                    myFold.hideLoading();
                    myFold.setOption(option);
            },1000)
            var option;
            option = {
                title: {
                    text: 'Daily Sales',
                    textStyle:{
                        color: '#fff',
                        fontSize: 20
                    }
                    
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    },
                    textStyle:{
                        align:'left'
                    }
                },
                legend: {
                    top: '0%',
                    right: '5%',
                    itemWidth:10,
                    itemHeight:10,
                    itemStyle:{
                        borderWidth:0,
                    },
                    textStyle:{
                        color:'#fff',
                        fontSize:15
                    }
                },
                grid: {
                    top:'20%',
                    left: '0%',
                    right: '4%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff',
                        }
                    },
                    axisLabel:{
                        fontSize:"10px",
                        interval: 0
                    },
                    
                    data: xdata
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff'
                        }
                    },
                    axisLabel: {
                        formatter: "£{value}",
                    },
                    splitLine:{
                        show:false
                    },
                },
                dataZoom:{
                    type:'inside'
                },
                series: [
                    {
                        data: data,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2, 
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#FE411B' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#FE9B1A', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"Actual",
                        symbolSize:8,
                        symbol:'circle',
                        // stack: 'Total',
                        // showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#FE411B' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#FE9B1A', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        color:{
                            type: 'radial',
                                x: 0.5,
                                y: 0.5,
                                r: 0.5,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#FE411B' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#FE9B1A', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                        }
                    },
                    {
                        data: rageData,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2, 
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#00F0FF' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#3D8FFF', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"Average",
                        symbolSize:8,
                        symbol:'circle',
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#00F0FF' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#3D8FFF', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        color:{
                            type: 'radial',
                                x: 0.5,
                                y: 0.5,
                                r: 0.5,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#3D8FFF' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#00F0FF', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                        },
                        // tooltip:{
                        //     show:false
                        // },
                        showSymbol:false
                    }
                ]
            };
            // option && myFold.setOption(option);
        },
        getWeeks(year) {
            // 一年第一天是周几
            var first = new Date(year, 0, 1).getDay()
            if (first == 1) {
                first = 0
            } else if (first == 0) {
                first = 1
            } else {
                first = 8 - first
            }
            // 计算一年有多少天
            if ((year % 4 == 0 && year % 100 != 0) || (year % 100 == 0 && year % 400 == 0)) {
                var allyears = 366
            } else {
                var allyears = 365
            }
            // 计算一年有多少周
            var week = Math.ceil((allyears - first) / 7) + (first !== 0 ? 1 : 0)
            return week
        },
        getNowWeek(num) {
            let week =  this.getWeeks(new Date().getFullYear())
            if(num > week) {
                return num - week;
            }else {
              return  num;
            }
        },
        inintColumn() {
            if(this.isYear == 0) {
                var arr = ['Week '+this.getNowWeek(parseFloat(this.columnData.week)),'Week '+this.getNowWeek(parseFloat(this.columnData.week+1)),'Week '+this.getNowWeek(parseFloat(this.columnData.week+2)),'Week '+this.getNowWeek(parseFloat(this.columnData.week+3))];
            }else {
                var arr = ['Week 1','Week 2','Week 3','Week 4'];
            }
            let data = [this.columnData.onePrice,this.columnData.twoPrice,this.columnData.threePrice,this.columnData.fourPrice]
            let xdata = []
            data.map((item,index) => {
                if(item && item > 0) {
                   xdata.push(arr[index])
                }
            })
            let chartDom = document.getElementById('cpuColumn');
            let myChart = echarts.init(chartDom);
            myChart.showLoading({
                text: 'loading',
                color: '#409eff',
                textColor: '#fff',
                maskColor: 'rgba(0, 0, 0, 0.1)',
                zlevel: 0,
            });;
            setTimeout(() => {
                    // setOption前隐藏loading事件 
                    myChart.hideLoading();
                    myChart.setOption(option);
            },1000)
            let option;
            option = {
                title: {
                    text: 'Weekly Sales',
                    textStyle:{
                        color: '#fff',
                        fontSize: 20
                    }
                    
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'none'
                    }
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '10%',
                    top:"15%",
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: xdata,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                    axisLabel: {
                        //这种做法就是在y轴的数据的值旁边拼接单位，貌似也挺方便的
                        formatter: "£{value}",
                    },
                    splitLine:{
                        show:true,
                        lineStyle:{
                            color:'#FFFFFF',
                            opacity:'0.1'
                        },
                    },
                },
                series: [
                    {
                        name:'Sales',
                        type: 'bar',
                        large:true,
                        barWidth : 30,
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: '#FED701' // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: '#E78801' // 0% 处的颜色
                                },
                                // {
                                //     offset: 0.9,
                                //     color: '#000' // 0% 处的颜色
                                // },
                                // {
                                //     offset: 1,
                                //     color: '#000' // 100% 处的颜色
                                // }
                            ],
                            global: false // 缺省为 false
                        },
                        data: data
                    }
                ]
                };

            // option && myChart.setOption(option);
        },
        backMap() {
            this.$store.commit('changeConsumershow',false)
        },
        current_change(page) {
            this.dayLimit.index = page;
            this.dayRowindex = 0;
            this.getConsumerDaysales()
        },
        week_current_change(page){
            this.weekLimit.index = page;
            this.weekRowindex = 0;
            this.getConsumerWeeksales()
        },
        changeStart1(time) {
            let t = new Date(time.getTime())
            const maxTime = new Date(t).setMonth(new Date(t).getMonth() + 1);
            this.weekLimit.end = new Date(maxTime)
            this.getConsumerWeeksales()
        },
        changeEnd1(time) {
            let t = new Date(time.getTime())
            const minTime = new Date(t).setMonth(new Date(t).getMonth() - 1);
            this.weekLimit.start = new Date(minTime)
            this.getConsumerWeeksales()
        },
        changeDay(time) {
            this.dayLimit.day = time;
            this.getConsumerDaysales()
        },
        weekRowclick(item) {
            if(this.isYear == 0){
                let date = new Date;
                this.weekLimit.searchYear = date;
                this.weekLimit.searchDate = date;
            }
            this.getWeeksales(item)
            this.weekRowindex = this.getArrayIndex(this.consumerWeek.data, item);
        },
        dayRowclick(item) {
            this.getDaysales(item)
            this.dayRowindex = this.getArrayIndex(this.consumerDay.data, item);
        },
        handleDate(dateType){
            this.isYear = dateType.id;
            this.dateTypeFont = dateType.name;
            let date = new Date;
            this.weekLimit.searchYear = date;
            this.weekLimit.searchDate = date;
            this.getConsumerWeeksales();
        },
        handleCommand2(command) {
            this.weekLimit.storeId = command;
            this.weekRowindex = 0;
            this.getConsumerWeeksales()
        },
        handleCommand3(command){
            this.dayLimit.storeId = command;
            this.dayRowindex = 0;
            this.getConsumerDaysales()
        },
        timeStr(dataStr){
            var date = new Date(dataStr);
            var y = date.getFullYear();

            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;

            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;

            // var h = date.getHours();
            // h = h < 10 ? ('0' + h) : h;

            // //获得分
            // var mm = date.getMinutes()
            // mm = mm < 10 ? ('0' + mm) : mm;

            // //获得秒
            // var ss = date.getSeconds()
            // ss = ss < 10 ? ('0' + ss) : ss;

            // return y+'-'+m+'-'+d+' '+h+':'+mm+':'+ss
            return y+'-'+m+'-'+ d;
        },
        tableRowClassNameweek({row, rowIndex}) {
            if (rowIndex === this.weekRowindex) {
                return 'highlight-row'
            }
            return '';
        },
        tableRowClassNameday({row, rowIndex}) {
            if (rowIndex === this.dayRowindex) {
                return 'highlight-row'
            }
            return '';
        },
        getArrayIndex(arr, obj) {
            var i = arr.length;
            while (i--) {
                if (arr[i] === obj) {
                return i;
                }
            }
            return -1;
        },
        getPreDate(){
            // var curDate = new Date();
            // var preDate = new Date('2022-11-17');
            // var preDate = new Date(curDate.getTime());
            // return preDate;

            var curDate = new Date()
            var nowTime = curDate.getTime()
            var weekDay = curDate.getDay();
            var oneDayTime = 24*60*60*1000;
            var preDate = nowTime - (weekDay+2)*oneDayTime; //获取本周5
            // var oldDate = new Date(preDate- 24*60*60*1000*7)//获取上周日
            return preDate;

        },
        changeYearrange(time) {
            this.isYear = 0;
            this.dateTypeFont = 'Year';
            let date = new Date;
            this.weekLimit.searchDate = date;
            this.weekLimit.searchYear = time;
            this.weekRowindex = 0;
            this.getConsumerWeeksales()
        },
        changeMonthrange(time) {
            this.isYear = 1;
            this.dateTypeFont = 'Month';
            // this.weekLimit.all = false;
            // if(Array.isArray(time)) {
            //     this.weekLimit.all = true;
            // }else {
                this.weekLimit.searchYear = time;
                this.weekLimit.searchDate = time;
            // }
            this.weekRowindex = 0;
            this.getConsumerWeeksales()
        },
        getMonthNum(dataStr) {
            var date = new Date(dataStr);
            var y = date.getFullYear();

            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            return y+'-'+m;
        },
        getYearNum(dataStr) {
            var date = new Date(dataStr);
            var y = date.getFullYear();
            return y;
        }
    }
}
</script>
<style scoped>
    /deep/ .el-table__empty-block {
        background: #091c38;
    }
    /deep/ .el-table__body-wrapper {
        background: #091c38;
    }
    /deep/ .el-table__header-wrapper {
        background: #091c38;
    }
    /deep/ .el-pager li.btn-quicknext {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pager li.btn-quickprev{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-dropdown {
        color:#71CDF9 !important;
        font-size: 0.2375rem !important;
    }
    .dropdown-menu {
        /* background: rgba(0, 0, 0, 0.8);
        color: #fff; 
        border:0; */
        /* position: absolute; */
        /* left: 25.5% !important; */
        /* top: 26.5% !important; */
        /* height: 2rem; */
        overflow-y: auto;
    }
    .dropdown-menu::-webkit-scrollbar {
        width: 0rem;
    }
    /deep/ .el-dropdown {
        color:#71CDF9 !important;
        font-size: 0.2375rem !important;
    }
    .category_name {
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
    }
    .containers {
        width: 100%;
        height: 100%;
        padding: .25rem 1.25rem;
        box-sizing: border-box;
    }
    .container-main {
        width: 100%;
        height: 100%;
        background: url('../../assets/images/analysis.png') no-repeat;
        background-size: 100% 100%;
    }
    .container_bg {
        width: 100%;
        height: 100%;
        padding: .375rem 1.5rem;
        box-sizing: border-box;
    }
    .title {
        display: flex;
        justify-content:space-between;
        align-items:center;
        font-size: .4rem;
        font-weight: 500;
        color: #fff;
        position: relative;
    }
    /* .login img {
        width: .7375rem;
        height: .7375rem;
        vertical-align: middle;
    } */
    .back {
        width: .4625rem;
        height: .375rem;
    }
    .amount {
        margin-top: .25rem;
    }
    .amount_text {
        font-size:0.4625rem;
        font-weight: bold;
        color: #FFFFFF;
        background: linear-gradient(0deg, #fcc480 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .bottom_line{
        width: 3.25rem;
        height: .025rem;
        margin-top: .025rem;
        background: radial-gradient(ellipse,#00C8F1,#000);
        margin: .025rem auto;
    }
    .main {
        width: 100%;
        height:100%;
        margin-top: .125rem;
    }
    .main-top{
        height: 5.3875rem;
        width: 100%;
    }
    .main-top_bg {
        width: 50%;
        height: 100%;
        background: url('../../assets/images/left_top.png') no-repeat;
        background-size: 100% 100%;
    }
    #line1 {
        height: 4.2rem;
    }
    #cpuColumn{
        height: 4.2rem;
    }
    .linetime {
        z-index: 10000;
        width: 50%;
        background: rgba(250, 250, 250, 0.1);
        border: none;
    }
    /deep/ .el-range-editor--mini .el-range-input {
        background: rgba(250, 250, 250, 0);
        color: #fff;
    }
    /deep/ .el-range-editor--mini .el-range-separator {
        color: #fff;
    }
    .main_center {
        padding: .5625rem 1.0625rem;
        text-align: center;
        margin: 0  auto;
        position: relative;
    }
    /deep/ .el-table td.el-table__cell {
        border-bottom: 0;
        color: #fff;
        background-color: #091c38;
    }
    /deep/ .el-table::before {
        width: 0;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td{
        background-color: #235dc8 !important;
    }
    /deep/ .el-pagination button:disabled {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination button {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager .number{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager li.active{
        color: #409EFF !important;
    }
    /deep/ .el-date-editor .el-input__inner {
       background: rgba(250, 250, 250, 0.1);
        color: #fff;
        border: 0;
        text-align: center;
    }
    .main-bottom {
        height: 4.5375rem;
        width: 100%;
        background: url('../../assets/images/analysis_bottombg.png') no-repeat;
        background-size: 100% 100%;
    }
    .block {
        position: absolute;
        bottom: 0;
        right: 12%;
    }
    .main-bottom_center {
        padding: .375rem 1.0625rem;
        text-align: center;
        margin: 0  auto;
    }
    .linetime1 {
        z-index: 10000;
        width: 60%;
        border: none;
        margin: 0 auto;
        margin-bottom: .5625rem
    }
    /deep/ .linetime1 .el-date-editor.el-input{
        width: 100%;
    }
    /deep/ .el-table__empty-block {
        background: #091c38;
    }
    /deep/ .el-table__body-wrapper {
        background: #091c38;
    }
    /deep/ .el-table__header-wrapper {
        background: #091c38;
    }
    /deep/ .el-pager li.btn-quicknext {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pager li.btn-quickprev{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    .logo_img {
        width: 2.5rem;
        height: .875rem;
        vertical-align: middle;
        text-align: left;
        display: inline-block;
    }
    .selector-sty{
        width: 180px;
        display: inline-block;
        border-radius: 4px;
        border: 0px !important;
        height: 28px !important;
        line-height: 28px !important;
        background: rgba(250, 250, 250, 0.1);
        font-size: 12px;
        color: #fff;
        transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    }
</style>