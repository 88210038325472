<template>
    <div>
        <div  class="container-main">
            <div class="container_bg">
                <div class="title">
                    <div class="login">
                        <img src="../../assets/images/logo.png" alt="" class="logo_img">
                    </div>
                    <div class="back" @click="backMap">
                        <img src="../../assets/images/quit.png" alt="">
                    </div>
                </div>
                <div class="amount">
                    <el-row>
                        <el-col :span="12">
                           <div @click="setUserType('Existing')">
                               <span style="font-size:0.35rem;color: #FFFFFF;">APP Download
                                   <span class="amount_text"> 
                                        <countTo :startVal='0' :endVal='userNum' :duration='1000' separator=","></countTo>
                                    </span>
                                </span>
                            </div>
                            <div class="bottom_line"></div>
                        </el-col>
                        <el-col :span="12">
                            <div @click="setUserType('Waiting')">
                               <span style="font-size:0.35rem;color: #FFFFFF;">Active Customer
                                   <span class="amount_text"> 
                                        <countTo :startVal='0' :endVal="activeNum" :duration='1000' separator=","></countTo>
                                    </span>
                                </span>
                            </div>
                            <div class="bottom_line"></div>
                        </el-col>
                    </el-row>
                </div>
                <div class="main">
                    <div class="main_center">
                        <el-row :gutter="20">
                            <el-col :span="6">
                                <div class="tap_main" @click="tapActive('Active')">
                                <span class="tap_text">Active Customer</span>
                                <div class="tap_line greenline" v-show="activeName == 'Active'"></div>
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div class="tap_main" @click="tapActive('Waiting')">
                                    <span class="tap_text"> Registered Customer</span>
                                    <div class="tap_line redline" v-show="activeName == 'Waiting'"></div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-table
                            ref="table4"
                            :data="consumersData"
                            :header-cell-style="{background:'#143778',color:'#71CDF9',fontSize: '0.28rem',border:'0px'}"
                            :row-style="{height:'.75rem',fontSize:'0.23rem'}" 
                            style="width: 100%;"
                            height="8rem"
                            :row-class-name="tableRowClassNameweek"
                            :default-sort = "{prop: 'orderNum', order: 'descending'}"
                            @sort-change="sort_change"
                            v-loading="loading"
                            size="mini">
                            <template slot="empty">
                                <span style="color: #969799;">No data</span>
                            </template>
                            <el-table-column type="index" label=" " width="65px" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.orderAmount != '-'"> 
                                        {{ scope.$index+1 }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="account"
                                label="Account"
                                show-overflow-tooltip
                                align="center">
                            </el-table-column>
                            <el-table-column
                                prop="name"
                                label="Name"
                                show-overflow-tooltip
                                align="center">
                            </el-table-column>
                            <el-table-column
                                prop="phone"
                                label="Phone"
                                show-overflow-tooltip
                                align="center"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="regTime"
                                label="Register"
                                show-overflow-tooltip
                                align="center"
                                >
                            </el-table-column>
                            
                            <el-table-column
                                prop="orderNum"
                                label="Order"
                                show-overflow-tooltip
                                align="center"
                                sortable="true"
                                >
                            </el-table-column>
                            
                            <el-table-column
                                prop="orderAmount"
                                label="Sales"
                                show-overflow-tooltip
                                align="center"
                                sortable="true"
                                >
                                <template slot-scope="scope">
                                    <div v-if="scope.row.orderAmount != '-'">
                                       {{scope.row.orderAmount}}
                                    </div>
                                    <div v-else></div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="block" v-if="consumersData.length >= 10">
                            <el-pagination
                                layout="prev, pager, next"
                                :pager-count="5"
                                :page-size="dataLimit.limit"
                                :current-page="dataLimit.page"
                                @current-change="current_change"
                                :total="consumersData.length">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
import {getAppuserList} from '@/api/user.js'
import countTo from 'vue-count-to';
export default { 
    components: { countTo },
    props:{
        userTotal:{
            type:Number,
            default() {
                return 0
            }
        } 
    },
    data() {
        return {
            consumersData:[],
            dataLimit:{
                limit:10,
                page:1
            },
            domCos:'',
            activeName:'Active',
            Active_consumers:[],
            Waiting_consumers:[],
            loading:true,
            activeNum:0,
            userNum:0
        }
    },
    created(){
        let that = this;
        this.$nextTick(() => {
            that.initFunction()
        })
    },
    methods:{
        initFunction() {
            let that = this;
            getAppuserList().then(res => {
                this.loading = false;
                let result = res.data.data;
                result.map(item => {
                    if(item.orderAmount*1 == 0 && item.account.indexOf('qq.com') < 0 && item.account.indexOf('163.com') < 0 && item.account.indexOf('+86') < 0) {
                        this.Waiting_consumers.push(item)
                    }else if(item.orderAmount*1 != 0 && item.account.indexOf('qq.com') < 0 && item.account.indexOf('163.com') < 0 && item.account.indexOf('+86') < 0){
                        this.Active_consumers.push(item)
                    }
                })
                that.userNum = that.userTotal;
                if(that.activeName =='Active') {
                    that.consumersData = that.Active_consumers;
                    that.activeNum = that.Active_consumers.length;
                }else {
                    that.consumersData = that.Waiting_consumers;
                }
                this.dataFormat()
            }) 
        },
        dataFormat() {
            let that = this;
            let pages = 0;
            for(let i =0;i<that.consumersData.length;i++){
                if(i%10 == 0){
                    pages++;
                }
                that.consumersData[i].pageNum = pages;
                that.consumersData[i].counts = i%10;
            }
            let countNum = that.consumersData[that.consumersData.length-1].counts+1;
            for(let n = 0;n<10-countNum;n++){
                that.consumersData.push({account:'',name:'',phone:'',regTime:'',orderNum:'',orderAmount:'-',pageNum:pages,counts:countNum+n});
            }
            that.tableScrollCosmo()
        },
        tableScrollCosmo(){
            let that = this;
            // 获取需要绑定的table
            let tabHeight = 0;
            setTimeout(() => {
                for (let i = 0; i < that.consumersData.length; i++) {
                    that.consumersData[i].top = tabHeight;
                    tabHeight = tabHeight + document.getElementsByClassName('anchorN'+that.consumersData[i].pageNum+that.consumersData[i].counts)[0].offsetHeight;
                    that.consumersData[i].bottom = tabHeight;
                }
            },0)
            
            this.domCos = this.$refs.table4.bodyWrapper;
            this.domCos.addEventListener('scroll', () => {
                // 表格滚动条滚动的距离
                let scrollTop = this.domCos.scrollTop;
                let scrollTops = scrollTop+(document.getElementsByClassName('anchorN'+that.consumersData[0].pageNum+that.consumersData[0].counts)[0].offsetHeight*3);
                
                for (let j = 0; j < that.consumersData.length; j++) {
                    if (scrollTops > that.consumersData[j].top && scrollTops < that.consumersData[j].bottom) {
                        that.dataLimit.page = that.consumersData[j].pageNum;
                    }
                }
            })
        },
        backMap() {
            this.$emit("userType",'main');
        },
        current_change(page){
            this.dataLimit.page = page;
            let PageId = document.querySelector('.anchorN' + page +'0');
            setTimeout(() => {
                this.domCos.scrollTop = PageId.offsetTop;
            },10);
        },
        tableRowClassNameweek({row, rowIndex}) {
            if(row.orderAmount == "-"){
                return 'blank_sty'+ ' '+ 'anchorN'+ row.pageNum + row.counts
            }
            return 'anchorN'+ row.pageNum + row.counts;
        },
        tapActive(value) {
            this.activeName = value;
            if(this.activeName =='Active') {
                this.consumersData = this.Active_consumers;
            }else {
                this.consumersData = this.Waiting_consumers;
            }
            this.dataLimit.page = 1;
            this.domCos.scrollTop = 0;
            this.dataFormat()
        },
        sort_change(column) {
            let list = [];
            list = JSON.parse(JSON.stringify(this.consumersData))
            this.consumersData = list.sort(this.sortFun(column.prop, column.order === 'ascending'))
            this.dataLimit.page = 1;
            this.domCos.scrollTop = 0;
            this.dataFormat()
        },
        
        sortFun(attr, rev) {
            //第一个参数传入info里的prop表示排的是哪一列，第二个参数是升还是降排序
            if (rev == undefined) {
                rev = 1
            } else {
                rev = rev ? 1 : -1
            }
            return function (a, b) {
                a = a[attr]
                b = b[attr]
                if (a && b) {
                if (a < b) {
                    return rev * -1
                }
                if (a > b) {
                    return rev * 1
                }
                }
                return 0
            }
        }

    }
}
</script>
<style scoped>
    .container-main {
        width: 100%;
        height: 100%;
        background: url('../../assets/images/analysis.png') no-repeat;
        background-size: 100% 100%;
    }
    .container_bg {
        width: 100%;
        height: 100%;
        padding: .375rem 1.5rem;
        box-sizing: border-box;
    }
    .title {
        display: flex;
        justify-content:space-between;
        align-items:center;
        font-size: .4rem;
        font-weight: 500;
        color: #fff;
        margin-bottom: .2rem;
    }
    /* .login img {
        width: .7375rem;
        height: .7375rem;
        vertical-align: middle;
    } */
    .back {
        width: .4625rem;
        height: .375rem;
    }
    .main {
        height: 10.6rem;
        width: 100%;
        background: url('../../assets/images/tablebg.png') no-repeat;
        background-size: 100% 100%;
    }
    .linetime {
        z-index: 10000;
        width: 50%;
        background: rgba(250, 250, 250, 0.1);
        border: none;
    }
    .main_center {
        padding: 1rem .9625rem;
        position: relative;
    }
    .tap_main {
        position: relative;
        box-sizing: border-box;
        z-index: 1;
        margin-bottom: .375rem;
    }
    .tap_text {
        font-size: .375rem;
        font-weight: 500;
        color:#fff;
        z-index: -1;
        cursor: pointer;
    }
    .tap_line {
        position: absolute;
        bottom:-20%;
        left: 8%;
        width: 85%;
        height: .25rem;
        border-radius: .25rem;
        z-index: -2;
    }
    .greenline {
        background: #06D28D;
    }
    .redline {
        background: #F2609F;
    }
    .block {
        position: absolute;
        bottom: 4%;
        right: 10%;
    }
    .logo_img {
        width: 2.5rem;
        height: .875rem;
        vertical-align: middle;
        text-align: left;
        display: inline-block;
    }

    /deep/ .el-table td.el-table__cell {
        border-bottom: 0;
        color: #fff;
        background-color: #091c38;
    }
    /deep/ .el-table::before {
        width: 0;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td{
        background-color: #235dc8 !important;
    }
    /deep/ .el-pagination button:disabled {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination button {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager .number{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager li.active{
        color: #409EFF !important;
    }
    /deep/ .el-date-editor .el-input__inner {
       background: rgba(250, 250, 250, 0.1);
        color: #fff;
        border: 0;
        text-align: center;
    }

    /deep/ .el-table__empty-block {
        background: #091c38;
    }
    /deep/ .el-table__body-wrapper {
        background: #091c38;
    }
    /deep/ .el-table__header-wrapper {
        background: #091c38;
    }
    /deep/ .el-pager li.btn-quicknext {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pager li.btn-quickprev{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    .amount_text {
        font-size:0.4625rem;
        font-weight: bold;
        color: #FFFFFF;
        background: linear-gradient(0deg, #fcc480 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
</style>