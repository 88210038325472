import http from '@/utils/http'

//获取User 用户增长数据
export function getTotaluser() {
    return http({
      url: '/prod-api/app/statistics/userSum',
      method: 'get',
    })
}

//获取USER 近12个月增长
export function getMonth() {
    return http({
      url: '/prod-api/app/statistics/user12Month',
      method: 'get',
    })
}

//获取USER 4周每周增加人数
export function getWeek(data) {
    return http({
      url: '/prod-api/app/statistics/user4Week',
      method: 'post',
      data:data
    })
}

//获取USER 一周7天增加人数
export function getDay(data) {
    return http({
      url: '/prod-api/app/statistics/user7Day',
      method: 'post',
      data:data
    })
}

//获取app USER 近12个月增长
export function getMonthapp() {
  return http({
    url: '/api/board/user/getOneYearUserAnalysis',
    method: 'get',
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//获取app USER 4周每周增加人数
export function getWeekapp(query) {
  return http({
    url: '/api/board/user/getFourWeekUserAnalysis',
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//获取app USER 一周7天增加人数
export function getDayapp(query) {
  return http({
    url: '/api/board/user/getSevenDayUserAnalysis',
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//获取app User 用户增长数据
export function getAppTotaluser() {
  return http({
    url: '/api/board/user/getCurAndPrevUserAnalysis',
    method: 'get',
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//获取app User 用户列表
export function getAppuserList() {
  return http({
    url: '/api/board/user/getAppUserList',
    method: 'get',
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//获取app User 用户列表
export function getWebuserList(data) {
  return http({
    url: '/prod-api/app/statistics/countUser',
    method: 'post',
    data:data
  })
}

// app user 31-43周数据
export function getUserWeekNums(data) {
  return http({
    url: '/api/board/user/getUserWeekNums',
    method: 'get',
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}
