<template>
    <!-- <div class="main">
        <div class="rank_title">
            <el-row>
                <el-col :span="12"  class="rank_title_left">{{backgroundData.title}}</el-col>
                <el-col :span="11"  class="rank_title_right">{{backgroundData.title1}}</el-col>
            </el-row>
        </div>
        <div class="rank_main">
            <el-row class="rank_main_title">
                <el-col :span="24">{{backgroundData.name1}}</el-col>
            </el-row>
            <el-row v-for="(item,index) in backgroundData.list" :key="index"  class="rank_main_list">
                <el-col :span="15" class="rank_main_top_left">
                    <el-tooltip class="item" effect="dark" :content="item.goodsNameEn" placement="top">
                        <span>{{index+1}}. {{item.goodsNameEn}}</span>
                    </el-tooltip>
                </el-col>
                <el-col :span="9" class="rank_main_top_right">
                    <span class="rank_main_top_right_value">
                        <countTo :startVal='0' :endVal='item.total?item.total:0' :duration='1000' separator="," :decimals="2"></countTo> {{item.unit}}
                    </span>
                    <span v-if="index < 3" class="img">
                        <img src="../../assets/images/greenup.png" alt="" v-if="item.falge == 'up'" class="rank_img">
                        <img src="../../assets/images/reddown.png" alt="" v-if="item.falge == 'down'" class="rank_img">
                        <img src="../../assets/images/balance.png" alt="" v-if="item.falge == 'balance'" class="rank_img">
                    </span>
                    <div v-else class="img">
                        <span class="hiddenimg"></span>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div> -->
    <div class="main2">
        <div class="rank_title2">
            <el-row>
                <el-col :span="17"  class="rank_title_left2">{{backgroundData.title}}</el-col>
                <el-col :span="5"  class="rank_title_right2">{{backgroundData.title1}}</el-col>
            </el-row>
        </div>
        <div class="rank_main2">
            <el-row class="rank_main_title2">
                <el-col :span="24">{{backgroundData.name1}}</el-col>
            </el-row>
            <el-row v-for="(item,index) in backgroundData.list" :key="index"  class="rank_main_list">
                <el-col :span="15" class="rank_main_top_left">
                    <el-tooltip class="item" effect="dark" :content="item.goodsNameEn" placement="top">
                        <span>{{index+1}}. {{item.goodsNameEn}}</span>
                    </el-tooltip>
                </el-col>
                <el-col :span="9" class="rank_main_top_right">
                    <span class="rank_main_top_right_value">
                        <countTo :startVal='0' :endVal='item.total?item.total:0' :duration='1000' separator="," :decimals="0"></countTo> {{item.unit}}
                    </span>
                    <span v-if="index < 3" class="img">
                        <img src="../../assets/images/greenup.png" alt="" v-if="item.falge == 'up'" class="rank_img">
                        <img src="../../assets/images/reddown.png" alt="" v-if="item.falge == 'down'" class="rank_img">
                        <img src="../../assets/images/balance.png" alt="" v-if="item.falge == 'balance'" class="rank_img">
                    </span>
                    <div v-else class="img">
                        <span class="hiddenimg"></span>
                    </div>
                </el-col>
            </el-row>
            <div class="top2"></div>
            <div class="bottom2"></div>
        </div>
    </div>
    
</template>
<script>
import countTo from 'vue-count-to';
export default {
    components: { countTo },
    props:{
        backgroundData:{
            type:Object,
            default() {
                return {}
            }
        } 
    },
    watch:{
        'backgroundData':{
            handler: function (newValue, oldValue) {
               this.backgroundData = newValue;
            },
            deep: true
        }
    },
    data() {
        return {
            
        }
    },
    created() {
       
    },
    methods:{
    }
}
</script>
<style scoped>
    .rank_main_title2 {
        margin-top: .25rem;
        text-indent: .1375rem;
        margin-bottom: .1rem;
    }
    .top2{
        position: absolute;
        top: 2px;
        left: 0;
        width: 100%;
        height: .45rem;
        background: url('../../assets/images/borTop.png') no-repeat;
        background-size: 100% 100%;
    }
    .bottom2{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: .45rem;
        background: url('../../assets/images/borBottom.png') no-repeat;
        background-size: 100% 100%;
    }
    .rank_main2 {
        padding: 0.125rem .225rem 0.25rem .225rem;
        text-align: left;
        position: relative;
        background-color: rgba(0,0,0,0.2);
        margin-top: -1.2px;
        
    }
    .rank_title_left2{
        text-align: left;
        margin-top: -4px;
        text-shadow: 0 0 5px #fff,
             0 0 20px #3D8FFF,
             0 0 25px #3D8FFF;
        padding-left: 0.325rem;
        white-space: nowrap;
    }
    .rank_title_right2{
        text-align: right;
        font-size: .28rem;
    }
    .rank_title2{
        font-size: .25rem;
        font-weight: bold;
        background: url('../../assets/images/subtitle2.png') no-repeat;
        background-size: 100% 100%;
        padding: 0.14rem .1125rem 0.14rem .225rem;
    }
    .main2 {
        position: relative;
        /* height: 100%; */
        width: 100%;
        /* padding: 0.25rem .1125rem .3625rem .225rem; */
        color: #fff;
        border-radius: .1875rem;
        z-index: 10;
        box-sizing: border-box;
    }
    .main {
        position: relative;
        /* height: 100%; */
        width: 100%;
        /* padding: 0.25rem .1125rem .3625rem .225rem; */
        background-color: rgba(0,0,0,0.4);
        color: #fff;
        border-radius: .1875rem;
        z-index: 10;
        box-sizing: border-box;
    }
    .rank_title{
        font-size: .25rem;
        font-weight: bold;
        line-height: .25rem;
        height: .25rem;
    }
    .rank_title_left {
        text-align: left;
    }
    .rank_title_right {
        text-align: right;
    }
    .rank_main {
        margin-top: .125rem;
        text-align: left;
    }
    .rank_main_title {
        margin-top: .25rem;
        text-indent: .1375rem;
        margin-bottom: .25rem;
    }
    .rank_main_list {
        height: .375rem;
        line-height: .375rem;
        background: url('../../assets/images/bluebackground.png') no-repeat;
        background-size: 100% 100%;
        margin-bottom: .2rem;
        text-indent: .1375rem;
        font-size: .2rem;
    }
    .rank_main_list:last-child {
        margin-bottom: 0rem;
    }
    .rank_main_top_left {
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
        text-indent: .25rem;
    }
    .rank_main_top_right {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
    }
    /* .rank_main_top_right_value {
        text-indent: .1625rem;
    } */
    .img {
        width: .325rem;
        height: .375rem;
        display: inline-block;
        text-align: right;
    }
    .rank_img {
        width:.1875rem;
        height: .1875rem;
    }
    .hiddenimg{
        width: .1875rem;
        height: .1875rem;
        display: inline-block;
    }
</style>