<template>
    <div class="container-main">
        <div class="container_bg">
            <div class="title">
                <div class="login">
                    <img src="../../assets/images/logo.png" alt="" class="logo_img">
                    <!-- <span> Pacific Foods</span> -->
                </div>
                <div class="back" @click="backMap">
                    <img src="../../assets/images/quit.png" alt="">
                </div>
            </div>
            <div class="main">
                <div class="main_center">
                    <!-- <el-date-picker
                        v-model="dayLimit.searchDate"
                        type="month"
                        format="M月"
                        align="center"
                        size="mini"
                        :picker-options="pickerOptions"
                        @change="changeMonthrange">
                    </el-date-picker> -->
                    <el-table
                        :data="businessData.slice((dayLimit.page-1)*dayLimit.limit,dayLimit.page*dayLimit.limit)"
                        :header-cell-style="{background:'#143778',color:'#71CDF9',fontSize: '0.2375rem',border:'0px'}"
                        :row-style="{height:'0.8125rem'}"
                        style="width: 100%;margin-top:0.1875rem;"
                        size="mini">
                        <template slot="empty">
                            <span style="color: #969799;">No data</span>
                        </template>
                        <el-table-column
                            prop="categoryName"
                            label="Category"
                            show-overflow-tooltip
                            align="center"
                            width="200">
                            <template slot="header" slot-scope="scope">
                                <el-dropdown trigger="click" @command="handleCommand2" size="mini">
                                    <span>
                                        Category<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown" class="dropdown-menu">
                                        <el-dropdown-item v-for="item in classList"
                                        :key="item.code" :command="item.code">
                                        {{item.labelEn}}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                            <template slot-scope="scope"> 
                                <div class="category_name">
                                    {{scope.row.category}}
                                </div>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column
                            prop="code"
                            label="Product Code"
                            show-overflow-tooltip
                            align="center"
                            width="150">
                        </el-table-column> -->
                        <el-table-column
                            prop="name"
                            label="Name"
                            show-overflow-tooltip
                            align="center">
                        </el-table-column>
                        <el-table-column
                            prop="price"
                            label="Price"
                            show-overflow-tooltip
                            align="center"
                            width="100">
                            <template slot-scope="scope">
                                <countTo :startVal='0' :endVal='scope.row.price?scope.row.price:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="qty"
                            label=" QTY"
                            show-overflow-tooltip
                            align="center"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="amount"
                            label="Amount"
                            align="center"
                            show-overflow-tooltip
                            width="150">
                            <template slot-scope="scope">
                                <countTo :startVal='0' :endVal='scope.row.amount?scope.row.amount:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="unit"
                            label="Unit"
                            align="center"
                            width="100">
                        </el-table-column>
                    </el-table>
                    <div class="block" v-if="businessWeeksize >= 5">
                        <el-pagination
                            layout="prev, pager, next"
                            :pager-count="5"
                            :page-size="dayLimit.limit"
                            :current-page="dayLimit.page"
                            @current-change="week_current_change"
                            :total="businessWeeksize">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {getSalesrank,getClassification, getSalesrankTest} from '../../api/business'
import countTo from 'vue-count-to';
export default {
    components: { countTo },
    data() {
        return {
            businessData:[],
            businessWeeksize:0,
            dayLimit:{
                page: 1,
                limit:10,
                searchDate:'',
                categoryCode:"",
                all:false
            },
            pickerOptions:{
                shortcuts: [{
                    text: '全部',
                    onClick(picker) {
                        // let date = new Date()
                        // let month = date.getMonth();
                        // let start = date.setMonth(0);
                        // let end = date.setMonth(month + 11-month);
                        // picker.$emit('pick', [1,11]);
                        picker.$emit('pick', [0]);
                    }
                }]
            },
            classList:[],
            urlType:''
        }
    },
    created(){
        this.urlType = window.location.hostname;
        this.initFunction()
        this.getClassification()
    },
    methods:{
        initFunction() {
            this.dayLimit.searchDate = new Date();
            this.getAllgoods()
        },
        getClassification(){
            getClassification().then(res => {
                this.classList = [];
                this.classList = res.data.data.list;
                this.classList.unshift({code:"",labelEn:'All'})
            })
        },
        getAllgoods() {
            let parmas = {
                page: this.dayLimit.page,
                limit: this.dayLimit.limit,
                category:this.dayLimit.categoryCode
            }
            if(this.urlType == 'pfoods.co.uk'){
                getSalesrankTest(parmas).then(res => {
                    this.businessData = [];
                    this.businessData = res.data.data;
                    this.businessWeeksize = this.businessData.length;
                })
            }else{
                getSalesrank(parmas).then(res => {
                    this.businessData = [];
                    this.businessData = res.data.data;
                    this.businessWeeksize = this.businessData.length;
                })
            }
            
        },
        backMap() {
            this.$emit("ParentType",'main');
        },
        week_current_change(page){
            this.dayLimit.page = page;
        },
        changeMonthrange(time) {
            this.dayLimit.all = false;
            if(Array.isArray(time)) {
                this.dayLimit.all = true;
            }else {
                this.dayLimit.searchDate = time;
            }
            this.getAllgoods();
        },
        getMonthNum(dataStr) {
            var date = new Date(dataStr);
            var m = date.getMonth() + 1
            return m;
        },
        handleCommand2(command) {
            this.dayLimit.categoryCode = command;
            this.dayLimit.page = 1;
            this.getAllgoods();
        },
    }
}
</script>
<style scoped>
    .container-main {
        width: 100%;
        height: 100%;
        background: url('../../assets/images/analysis.png') no-repeat;
        background-size: 100% 100%;
    }
    .container_bg {
        width: 100%;
        height: 100%;
        padding: .375rem 1.5rem;
        box-sizing: border-box;
    }
    .title {
        display: flex;
        justify-content:space-between;
        align-items:center;
        font-size: .4rem;
        font-weight: 500;
        color: #fff;
        margin-bottom: .375rem;
    }
    /* .login img {
        width: .7375rem;
        height: .7375rem;
        vertical-align: middle;
    } */
    .back {
        width: .4625rem;
        height: .375rem;
    }
    .main {
        height: 10.6rem;
        width: 100%;
        background: url('../../assets/images/tablebg.png') no-repeat;
        background-size: 100% 100%;
    }
    .linetime {
        z-index: 10000;
        width: 50%;
        background: rgba(250, 250, 250, 0.1);
        border: none;
    }
    .main_center {
        padding: .75rem .9625rem;
        position: relative;
    }
    .block {
        position: absolute;
        bottom: 0;
        right: 10%;
    }
    .category_name {
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
    }
    .logo_img {
        width: 2.5rem;
        height: .875rem;
        vertical-align: middle;
        text-align: left;
        display: inline-block;
    }
</style>