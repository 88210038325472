<template>
    <div class="containers">
        <div class="container-main">
            <div class="container_bg">
                <div class="title">
                    <div class="login">
                        <img src="../../assets/images/logo.png" alt="" class="logo_img">
                        <!-- <span> Pacific Foods</span> -->
                    </div>
                    <div class="header-title">
                        <span v-if="urlType == 'www.foodo2o.com'"> YIELD OF TAKEAWAY </span>
                        <span v-else>ANALYSIS OF FRANCHIS TAKEAWAY</span>
                    </div>
                    <div class="back" @click="backMap">
                        <img src="../../assets/images/quit.png" alt="">
                    </div>
                </div>
                <div class="main">
                    <div class="main-top">
                        <div v-if="urlType != 'www.foodo2o.com'">
                            <el-col :span="12" class="main-line">
                                <div class="linetime1">
                                        <el-col :span="11">
                                            <el-date-picker
                                                v-model="monthLimit.start"
                                                type="date"
                                                placeholder="选择日期"
                                                size="mini"
                                                @change="changeStart1"
                                                >
                                            </el-date-picker>
                                        </el-col>
                                        <el-col :span="2">
                                            <div style="color:#fff;padding: 0 7px;margin: 13px 0;"><div style="width:100%;height:1px;background:#fff;"></div></div>
                                        </el-col>
                                        <el-col :span="11">
                                            <el-date-picker
                                                v-model="monthLimit.end"
                                                type="date"
                                                placeholder="选择日期"
                                                size="mini"
                                                @change="changeEnd1">
                                            </el-date-picker>
                                        </el-col>
                                    </div>
                                <div id="line1"></div>
                            </el-col>
                            <el-col :span="12" class="main-pie">
                                <div id="pie1"></div>
                            </el-col>
                        </div>
                        <div v-if="urlType == 'www.foodo2o.com'">
                            <el-col :span="24">
                                <div class="main_center">
                                    <el-table
                                        :data="storeData"
                                        :header-cell-style="{background:'#143778',color:'#71CDF9',fontSize: '0.2375rem',border:'0px'}"
                                        :row-style="{height:'0.625rem'}"
                                        style="width: 100%;margin-top:0.1875rem;"
                                        @row-click="weekRowclick"
                                        :row-class-name="tableRowClassNameweek"
                                        size="mini">
                                        <template slot="empty">
                                            <span style="color: #969799;">No data</span>
                                        </template>
                                        <el-table-column
                                        prop="storeName"
                                        label="Store"
                                        show-overflow-tooltip
                                        align="left"
                                        min-width="30%">
                                        </el-table-column>
                                        <el-table-column
                                        prop="address"
                                        label="Address"
                                        show-overflow-tooltip
                                        align="left"
                                        min-width="30%">
                                        </el-table-column>
                                        <el-table-column
                                        prop="purchase"
                                        label="Purchase Price"
                                        show-overflow-tooltip
                                        align="right"
                                        min-width="15%"
                                        >
                                        <template slot-scope="scope">
                                            <countTo :startVal='0' :endVal='scope.row.purchase?scope.row.purchase*1:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                        </template>
                                        </el-table-column>
                                        <el-table-column
                                        prop="rent"
                                        label="Weekly Fee"
                                        show-overflow-tooltip
                                        align="right"
                                        min-width="15%"
                                        >
                                        <template slot-scope="scope">
                                            <countTo :startVal='0' :endVal='scope.row.rent?scope.row.rent*1:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                        </template>
                                        </el-table-column>
                                        <el-table-column
                                        prop="yield"
                                        label="Yield"
                                        align="right"
                                        show-overflow-tooltip
                                        min-width="10%">
                                        </el-table-column>
                                    </el-table>
                                    <!-- <div class="block" v-if="storeSize >= 5">
                                        <el-pagination
                                            layout="prev, pager, next"
                                            :pager-count="5"
                                            :page-size="weekLimit.limit"
                                            :current-page="weekLimit.page"
                                            @current-change="week_current_change"
                                            :total="storeSize">
                                        </el-pagination>
                                    </div> -->
                                </div>
                            </el-col>
                        </div>
                    </div>
                    <div class="main-bottom">
                        <div v-if="urlType != 'www.foodo2o.com'">
                            <el-col :span="12" class="main-line">
                                <div class="linetime1">
                                        <el-col :span="11">
                                            <el-date-picker
                                                v-model="weekLimit.start"
                                                type="date"
                                                placeholder="选择日期"
                                                size="mini"
                                                @change="changeStart2"
                                                >
                                            </el-date-picker>
                                        </el-col>
                                        <el-col :span="2">
                                            <div style="color:#fff;padding: 0 7px;margin: 13px 0;"><div style="width:100%;height:1px;background:#fff;"></div></div>
                                        </el-col>
                                        <el-col :span="11">
                                            <el-date-picker
                                                v-model="weekLimit.end"
                                                type="date"
                                                placeholder="选择日期"
                                                size="mini"
                                                @change="changeEnd2">
                                            </el-date-picker>
                                        </el-col>
                                    </div>
                                <div id="line2"></div>
                            </el-col>
                            <el-col :span="12" class="main-pie">
                                <div id="pie2"></div>
                            </el-col>
                        </div>
                        <div v-if="urlType == 'www.foodo2o.com'" style="height:100%;">
                            <div class="displayBox"> 
                                <div style="height:4.35rem;margin-right: 5%;width: 40%;">
                                    <img :src="shopImg" alt="" style="width:auto;height:100%;"> 
                                </div>
                                <div style="height:4.35rem;width: 40%;" id="googleMap"> 
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import {getSevenData,getFiveStoreList,get52WeekYieldList} from "../../api/takeaway"
import {getCostStatistics} from '../../api/map/index'
import countTo from 'vue-count-to';


Date.prototype.Format = function(fmt){ //需要JS格式化时间，后期做的时候方便使用   
  var o = {   
    "M+" : this.getMonth(),                 //月份   
    "d+" : this.getDate(),                    //日   
    "h+" : this.getHours(),                   //小时   
    "m+" : this.getMinutes(),                 //分   
    "s+" : this.getSeconds(),                 //秒   
    "q+" : Math.floor((this.getMonth()+3)/3), //季度   
    "S"  : this.getMilliseconds()             //毫秒   
  };   
  if(/(y+)/.test(fmt))   
    fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));   
  for(var k in o)   
    if(new RegExp("("+ k +")").test(fmt))   
  fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));   
  return fmt;   
}
export default {
    components: { countTo },
    data() {
        return {
            weekLimit:{
                start:'',
                end:""
            },
            monthLimit:{
                start:'',
                end:""
            },
            lineData1:{
                ebithaList:[],
                foodList:[],
                labourList:[]
            },
            pieData1:{},
            lineData2:{
                ebithaList:[],
                foodList:[],
                labourList:[],
                xdata:[]
            },
            pieData2:{},
            piePercentage1:[],
            piePercentage2:[],

            storeData:[],
            storeRowindex:0,
            columnData:[],
            // weekLimit:{
            //     page: 1,
            //     limit:5
            // },
            storeSize:0,
            urlType:"",
            isGoogle:true,
            googletimer:null,
            isGoogle:true,
            shopImg:''//店铺图片
        }
    },
    created() {
        this.urlType = 'www.foodo2o.com'//window.location.hostname;
        if(this.urlType !=  'www.foodo2o.com') {
            this.weekLimit.end = new Date();
            this.monthLimit.end = new Date();
            this.changeEnd1(this.monthLimit.end)
            this.changeEnd2(this.weekLimit.end)
        }else {
            this.getTakewayTable()
        }
    },
    mounted() {
        this.createGoogle();
    },
    methods:{
        createGoogle() {
            var scripts = document.querySelectorAll('script');
            scripts.forEach(item => {
                if(item.title == 'googlemap') {
                    this.isGoogle = false;
                }else {
                    this.isGoogle = true;
                }
            })
            if(this.isGoogle) {
                const oScript = document.createElement('script');
                oScript.type = 'text/javascript';
                oScript.title = 'googlemap';
                oScript.src = 'https://maps.googleapis.com/maps/api/js?&key=AIzaSyAmlmO-F6MbG3qvpUn41eiOP_jkIiJJhI4&v=beta';
                document.body.appendChild(oScript);
                this.isGoogle = false;
            }
        },
        googleLoad(logNum,latNum,storeName) {
            let that = this;
            clearInterval(this.googletimer)
            this.googletimer = null;
            this.googletimer = setInterval(() => {
                if(that.isGoogle == false) {
                    clearInterval(that.googletimer)
                    that.googletimer = null;
                    that.$nextTick(() => {
                        setTimeout(()=> {
                            that.initGoogle(logNum,latNum,storeName)
                        },2000)
                    })
                }else {
                    that.createGoogle()
                }
            },1000)
        },
        initGoogle(logNum,latNum,storeName) {
            var that = this;
            const mapOptions = {
                zoom: 17,
                center: { lat: parseFloat(latNum), lng: parseFloat(logNum) },
                gestureHandling: 'greedy',//"none", //禁止缩放
                disableDefaultUI: true
            }

            let googleMap = document.getElementById('googleMap');
            let map = new google.maps.Map(googleMap, mapOptions);
            new google.maps.Marker({
            position: { lat: parseFloat(latNum), lng: parseFloat(logNum) },
                map,
                title: storeName
            });
        },
        getTakewayTable() {
            getFiveStoreList().then(res => {
                if(res.data.code == 1) {
                    // this.storeData = res.data.data;
                    this.storeData = [
                        {"storeId":"1481883219968749570","storeName":"Wok Warrior - Grange Farm","address":"45 Singleton Drive, Grange Farm, Milton Keynes, Buckinghamshire","purchase":"73800","rent":"283.85","yield":"20.00%","lnglat":"-0.8072724,52.0217761","imageUrl":"https://image.foodo2o.com/store/2022/10/26/4b87ee99ea2b49c086b8413b7b53e2b5.jpeg"},
                        {"storeId":"1562418001185206273","storeName":"Wok Warrior - Aylesbury","address":"117 Cambridge St, Aylesbury HP20 1BT, UK","purchase":"150000","rent":"576.92","yield":"20.00%","lnglat":"-0.80896094441414,51.81931005720597","imageUrl":"https://image.foodo2o.com/store/2022/10/26/f4f1f570f6014e30ac737bea7c3feae6.jpeg"},
                        {"storeId":"1485932900487098369","storeName":"Dragon Boat Bletchley","address":"8 Duncombe St, Bletchley, Milton Keynes MK2 2LY, UK","purchase":"50000","rent":"192.31","yield":"20.00%","lnglat":"-0.73507010936737,51.99334660368338","imageUrl":"https://image.foodo2o.com/store/2022/1/25/f54da3b86a4b4a53901bc7fff8a5fbfd.png"},
                        {"storeId":"1501501562480582657","storeName":"DragonBoat NB","address":"28 High St, New Bradwell, Milton Keynes MK13 0BT","purchase":"65000","rent":"250","yield":"20.00%","lnglat":"-0.7909215,52.0650556","imageUrl":"https://image.foodo2o.com/store/2022/10/26/572941065be54ad4a195889a4a98f37d.jpeg"},
                        {"storeId":"1501503511858864130","storeName":"Cheng Du","address":"8 MEADOW WALK, BUCKINGHAM MK18 1RS","purchase":"116000","rent":"446.15","yield":"20.00%","lnglat":"-0.9853825,52.0006722","imageUrl":"https://image.foodo2o.com/store/2022/6/3/250c4c76d7e54c2b960680c784f99a9c.jpeg"},
                        {"storeId":"1532657787263868929","storeName":"Rice Bowl","address":"132 Watling St, Towcester NN12 6BT, UK","purchase":"85000","rent":"326.92","yield":"20.00%","lnglat":"-0.9898931,52.1332472","imageUrl":"https://image.foodo2o.com/store/2022/6/6/8ff27b2ae03549b797f0e1d0a774b136.png"}
                    ]
                    this.getStore(this.storeData[0])
                    // this.storeSize = res.data.data.length;
                }
            })
        },
        getSevenData() {
            let parmas = {
                start: this.timeStr(this.weekLimit.start),
                end: this.timeStr(this.weekLimit.end),
            }
            this.lineData2 = {
                ebithaList:[],
                foodList:[],
                labourList:[],
                xdata:[]
            }
            getSevenData(parmas).then(res=> {
                let result = res.data.data;
                result.list.map(item => {
                    this.lineData2.ebithaList.push(item.ebitha)
                    this.lineData2.foodList.push(item.food)
                    this.lineData2.labourList.push(item.labour)
                    this.lineData2.xdata.push(item.date)
                })
                this.pieData2.ebitha = result.ebitha;
                this.pieData2.food = result.food;
                this.pieData2.labour = result.labour;
                let sum = this.pieData2.ebitha + this.pieData2.food  + this.pieData2.labour;
                this.piePercentage2 = [Math.round(this.pieData2.food/sum*10000)/100,Math.round(this.pieData2.labour/sum*10000)/100,Math.round(this.pieData2.ebitha/sum*10000)/100]
                setTimeout(()=> {
                    this.initLine2()
                    this.initPie2()
                },1000)
            })
        },
        getMonthData() {
            this.lineData1 = {
                ebithaList:[],
                foodList:[],
                labourList:[]
            }
            getCostStatistics().then(res => {
                let result = res.data.data;
                result.list.map(item => {
                    this.lineData1.ebithaList.push(item.ebitha)
                    this.lineData1.foodList.push(item.food)
                    this.lineData1.labourList.push(item.labour)
                })
                this.pieData1.ebitha = result.ebitha;
                this.pieData1.food = result.food;
                this.pieData1.labour = result.labour;
                let sum = this.pieData1.ebitha + this.pieData1.food  + this.pieData1.labour;
                this.piePercentage1 = [Math.round(this.pieData1.food/sum*10000)/100,Math.round(this.pieData1.labour/sum*10000)/100,Math.round(this.pieData1.ebitha/sum*10000)/100]
                setTimeout(()=> {
                    this.initLine1()
                    this.initPie1()
                },1000)
            })
        },
        initLine1() {
            var chartFold = document.getElementById('line1');
            let myFold  = echarts.init(chartFold);
            myFold.showLoading({
                text: 'loading',
                color: '#409eff',
                textColor: '#fff',
                maskColor: 'rgba(0, 0, 0, 0.1)',
                zlevel: 0,
            });;
            setTimeout(() => {
                    // setOption前隐藏loading事件 
                    myFold.hideLoading();
                    myFold.setOption(option);
            },1000)
            var option;
            option = {
                color:['#e78801','#28b1ff','#2C6BF8'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    },
                    textStyle:{
                        align:'left'
                    }
                },
                legend: {
                    top: '5%',
                    right: '0%',
                    itemWidth:10,
                    itemHeight:10,
                    itemStyle:{
                        borderWidth:0,
                    },
                    textStyle:{
                        color:'#fff',
                        fontSize:10
                    },
                    icon:'circle'
                },
                grid: {
                    top:'20%',
                    left: '5%',
                    right: '5%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff',
                        }
                    },
                    axisLabel:{
                        fontSize:"0.125rem",
                    },
                    
                    data: ['Week 1', 'Week 2', 'Week 3', 'Week 4']
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:false
                    },
                },
                dataZoom:{
                    type:'inside'
                },
                series: [
                    {
                        data: this.lineData1.ebithaList,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2, 
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#e78801' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#e78801', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"The food cost",
                        // stack: 'Total',
                        showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#e78801' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#e78801', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        
                    },
                    {
                        data: this.lineData1.labourList,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2,
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#28b1ff' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#0072ff', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"Labour",
                        // stack: 'Total',
                        showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#28b1ff' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#0072ff', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        }
                    },
                    {
                        data:  this.lineData1.foodList,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2,
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#28b1ff' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#0072ff', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"Ebitha",
                        // stack: 'Total',
                        showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#2C6BF8' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#6B12CC', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        }
                    }
                ]
            };
            option && myFold.setOption(option);
        },
        initPie1() {
            var that = this;
            var chartPie = document.getElementById('pie1');
            let  myPie  = echarts.init(chartPie);
            var option;
            option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    x:'center',
                    y:'center', 
                    left:'55%',
                    itemWidth:10,
                    itemHeight:10,
                    itemStyle:{
                        borderWidth:0,
                    },
                    textStyle:{
                        rich:{
                            a:{
                                color: '#E78801',
                                fontSize:24
                            },
                            b:{
                                color:'#fff',
                                fontSize:17,
                            }
                        }
                    },
                    formatter: function(name) {
                        var index = 0;
                        var clientlabels = ['The food cost','Labour','EBITHA'];
                        var clientcounts = that.piePercentage1;
                        clientlabels.forEach(function(value,i){
                            if(value == name){
                                index = i;
                            }
                        });
                        return '{b|' + name + '}' + "  " +  '{a|'+ clientcounts[index] +'%}';
                    }
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        center: ["25%", "50%"], 
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false,
                            length: 15,
                            length2: 0,
                            maxSurfaceAngle: 80
                        },
                        data: [
                            { 
                                value: this.pieData1.food, 
                                name: 'The food cost' ,
                                itemStyle: {
                                    normal: {//颜色渐变
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0, color: '#2C6BF8' // 0% 处的颜色
                                            }, {
                                                offset: 1, color: '#6B12CC' // 100% 处的颜色
                                            }],
                                            global: false // 缺省为 false
                                        }
                                    }
                                }
                            },
                            { 
                                value: this.pieData1.labour, 
                                name: 'Labour' ,
                                itemStyle: {
                                    normal: {//颜色渐变
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0, color: '#2B8AFF' // 0% 处的颜色
                                            }, {
                                                offset: 1, color: '#28B1FF' // 100% 处的颜色
                                            }],
                                            global: false // 缺省为 false
                                        }
                                    }
                                }
                            },
                            { 
                                value: this.pieData1.ebitha,
                                name: 'EBITHA' ,
                                itemStyle: {
                                    normal: {//颜色渐变
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0, color: '#FED701' // 0% 处的颜色
                                            }, {
                                                offset: 1, color: '#E78801' // 100% 处的颜色
                                            }],
                                            global: false // 缺省为 false
                                        }
                                    }
                                }
                            },
                        ]
                    }
                ]
            };
            option && myPie.setOption(option);
        },
        initLine2() {
            var chartFold = document.getElementById('line2');
            let myFold  = echarts.init(chartFold);
            myFold.showLoading({
                text: 'loading',
                color: '#409eff',
                textColor: '#fff',
                maskColor: 'rgba(0, 0, 0, 0.1)',
                zlevel: 0,
            });;
            setTimeout(() => {
                    // setOption前隐藏loading事件 
                    myFold.hideLoading();
                    myFold.setOption(option);
            },1000)
            var option;
            option = {
                color:['#e78801','#28b1ff','#2C6BF8'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    top: '5%',
                    right: '0%',
                    itemWidth:10,
                    itemHeight:10,
                    itemStyle:{
                        borderWidth:0
                    },
                    textStyle:{
                        color:'#fff',
                        fontSize:10
                    },
                    icon:'circle'
                },
                grid: {
                    top:'20%',
                    left: '5%',
                    right: '5%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff',
                        }
                    },
                    axisLabel:{
                        fontSize:"0.125rem",
                    },
                    
                    data: this.lineData2.xdata
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:false
                    },
                },
                dataZoom:{
                    type:'inside'
                },
                series: [
                    {
                        data: this.lineData2.ebithaList,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2, 
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#e78801' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#e78801', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"The food cost",
                        // stack: 'Total',
                        showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#e78801' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#e78801', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        
                    },
                    {
                        data: this.lineData2.labourList,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2,
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#28b1ff' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#0072ff', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"Labour",
                        // stack: 'Total',
                        showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#28b1ff' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#0072ff', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        }
                    },
                    {
                        data:  this.lineData2.foodList,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2,
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#28b1ff' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#0072ff', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"Ebitha",
                        // stack: 'Total',
                        showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#2C6BF8' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#6B12CC', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        }
                    }
                ]
            };
            option && myFold.setOption(option);
        },
        initPie2() {
            var that = this;
            var chartPie = document.getElementById('pie2');
            let myPie  = echarts.init(chartPie);
            var option;
            option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    x:'center',
                    y:'center', 
                    left:'55%',
                    itemWidth:10,
                    itemHeight:10,
                    itemStyle:{
                        borderWidth:0,
                    },
                    textStyle:{
                        rich:{
                            a:{
                                color: '#E78801',
                                fontSize:24
                            },
                            b:{
                                color:'#fff',
                                fontSize:17,
                            }
                        }
                    },
                    formatter: function(name) {
                        var index = 0;
                        var clientlabels = ['The food cost','Labour','EBITHA'];
                        var clientcounts = that.piePercentage2;
                        clientlabels.forEach(function(value,i){
                            if(value == name){
                                index = i;
                            }
                        });
                        return '{b|' + name + '}' + "  " +  '{a|'+ clientcounts[index] +'%}';
                    }
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        center: ["25%", "50%"], 
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false,
                            length: 15,
                            length2: 0,
                            maxSurfaceAngle: 80
                        },
                        data: [
                            { 
                                value: this.pieData2.food, 
                                name: 'The food cost' ,
                                itemStyle: {
                                    normal: {//颜色渐变
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0, color: '#2C6BF8' // 0% 处的颜色
                                            }, {
                                                offset: 1, color: '#6B12CC' // 100% 处的颜色
                                            }],
                                            global: false // 缺省为 false
                                        }
                                    }
                                }
                            },
                            { 
                                value: this.pieData2.labour, 
                                name: 'Labour' ,
                                itemStyle: {
                                    normal: {//颜色渐变
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0, color: '#2B8AFF' // 0% 处的颜色
                                            }, {
                                                offset: 1, color: '#28B1FF' // 100% 处的颜色
                                            }],
                                            global: false // 缺省为 false
                                        }
                                    }
                                }
                            },
                            { 
                                value: this.pieData2.ebitha,
                                name: 'EBITHA' ,
                                itemStyle: {
                                    normal: {//颜色渐变
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0, color: '#FED701' // 0% 处的颜色
                                            }, {
                                                offset: 1, color: '#E78801' // 100% 处的颜色
                                            }],
                                            global: false // 缺省为 false
                                        }
                                    }
                                }
                            },
                        ]
                    }
                ]
            };
            option && myPie.setOption(option);
        },
        backMap() {
            this.$store.commit('changeFoldshow',false)
        },
        changeStart1(time) {
            let t = new Date(time.getTime())
            const maxTime = new Date(t).setMonth(new Date(t).getMonth() + 1);
            this.monthLimit.end = new Date(maxTime)
            this.getMonthData()
        },
        changeEnd1(time) {
            let t = new Date(time.getTime())
            const minTime = new Date(t).setMonth(new Date(t).getMonth() - 1);
            this.monthLimit.start = new Date(minTime)
            this.getMonthData()
        },
        changeStart2(time) {
            let timeRange = 7 * 24 * 60 * 60 * 1000 // 7天
            const maxTime = time.getTime() + timeRange;
            this.weekLimit.end  = new Date(maxTime)
            this.getSevenData()
        },
        changeEnd2(time) {
            let timeRange = 7 * 24 * 60 * 60 * 1000 // 7天
            const minTime = time.getTime() - timeRange;
            this.weekLimit.start  = new Date(minTime)
            this.getSevenData()
        },
        timeStr(dataStr){
            var date = new Date(dataStr);
            var y = date.getFullYear();

            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;

            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;

            // var h = date.getHours();
            // h = h < 10 ? ('0' + h) : h;

            // //获得分
            // var mm = date.getMinutes()
            // mm = mm < 10 ? ('0' + mm) : mm;

            // //获得秒
            // var ss = date.getSeconds()
            // ss = ss < 10 ? ('0' + ss) : ss;

            // return y+'-'+m+'-'+d+' '+h+':'+mm+':'+ss
            return y+'-'+m+'-'+ d;
        },


        weekRowclick(item) {
            this.clickGetStore(item)
            this.storeRowindex = this.getArrayIndex(this.storeData, item);
        },
        tableRowClassNameweek({row, rowIndex}) {
            if (rowIndex === this.storeRowindex) {
                return 'highlight-row'
            }
            return '';
        },
        // week_current_change(page){
        //     this.weekLimit.page = page;
        //     this.storeRowindex = 0;
        //     this.getTakewayTable()
        // },
        getStore(item) {
            this.shopImg = '';
            this.shopImg = item.imageUrl;
            let lonalat = item.lnglat.split(',');
            this.googleLoad(lonalat[0],lonalat[1],item.storeName);
            // let that = this;
            // get52WeekYieldList({storeId: item.storeId}).then((res => {
            //     if(res.data.code == 1) {
            //         that.columnData = res.data.data;
            //         // that.inintColumn()
            //     }
            // }))
        },
        clickGetStore(item) {
            this.shopImg = '';
            this.shopImg = item.imageUrl;
            let lonalat = item.lnglat.split(',');
            this.initGoogle(lonalat[0],lonalat[1],item.storeName);
        },
        inintColumn() {
            let data = []
            let xdata= []
            this.columnData.map(item => {
                data.push(item.amount)
                xdata.push(item.date)
                // let str = ''
                // let year = new Date().getFullYear()
                // str = this.weekGetDate(year,item.week)
                // var monthAry = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                // xdata.push(item.week+'/'+monthAry[str]+' '+year)
            })

            let chartDom = document.getElementById('takeawayColumn');
            let  myChart = echarts.init(chartDom);
            myChart.showLoading({
                text: 'loading',
                color: '#409eff',
                textColor: '#fff',
                maskColor: 'rgba(0, 0, 0, 0.1)',
                zlevel: 0,
            });
            let option;
            setTimeout(() => {
                    // setOption前隐藏loading事件 
                    myChart.hideLoading();
                    myChart.setOption(option);
            },1000)
            option = {
                title: {
                    // text: "Sales £'000",
                    text: "Daily Sales",
                    textStyle:{
                        color: '#fff',
                        fontSize: 20
                    }
                    
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'none'
                    },
                    formatter: function(params) {
                        var result = ``
                        var dotHtml =  `<div><div style="text-align:center">${params[0].name}</div><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#28b1ff"></span>`
                            result = dotHtml +'Sales' + `<span style="margin-left:10px;">￡</span>` + params[0].data + "</div>"
                        return result
                    }
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '10%',
                    top:"15%",
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: xdata,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                    // axisLabel:{
                    //     formatter: function (value) {
                    //         let texts = '';
                    //         let ary = [];
                    //         ary = value.split('/')
                    //         texts = ary[0] + '\r\n'+ ary[1]
                    //         return texts;
                    //     }
                    // }
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:false
                    },
                    axisLabel:{
                        formatter: function (value) {
                            let texts = '';
                            texts = Math.floor(value/1000*1000)/1000;
                            return texts;
                        }
                    }
                },
                dataZoom: [
                    {
                        type: 'inside'
                    }
                ],
                series: [
                    {
                        name:'Sales',
                        type: 'bar',
                        large:true,
                        barWidth : 30,
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: '#28b1ff' // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: '#0072ff' // 0% 处的颜色
                                },
                                // {
                                //     offset: 0.9,
                                //     color: '#000' // 0% 处的颜色
                                // },
                                // {
                                //     offset: 1,
                                //     color: '#000' // 100% 处的颜色
                                // }
                            ],
                            global: false // 缺省为 false
                        },
                        data: data
                    }
                ]
            };
        },
        getArrayIndex(arr, obj) {
            var i = arr.length;
            while (i--) {
                if (arr[i] === obj) {
                return i;
                }
            }
            return -1;
        },
        weekGetDate(year,weeks){
            var date = new Date(year,"0","1"); 
            // 获取当前星期几,0:星期一 
            var time = date.getTime(); 
            //当这一年的1月1日为周日时则本年有54周,否则没有54周,没有则去除第54周的提示       
            var _week = date.getDay();   
 
            if(_week!=0){//一年53周情况 
                if(weeks==54){    
                    return ;    
                }    
                var cnt=0;// 获取距离周末的天数    
                if(_week==0){    
                    cnt = 7;    
                }else if(_week==1){    
                    cnt = 6;    
                }else if(_week==2){    
                    cnt = 5;    
                }else if(_week==3){    
                    cnt = 4;    
                }else if(_week==4){    
                    cnt = 3;    
                }else if(_week==5){    
                    cnt = 2;    
                }else if(_week==6){    
                    cnt = 1;    
                }    
                cnt += 1;//加1表示以星期一为一周的第一天    // 将这个长整形时间加上第N周的时间偏移    
                time += cnt*24*3600000; //第2周开始时间 
                var nextYear = new Date(parseInt(year,10)+1,"0","1");    
                var nextWeek = nextYear.getDay();    
                var lastcnt = 0;//获取最后一周开始时间到周末的天数    
                if(nextWeek==0){    
                    lastcnt = 6;    
                }else if(nextWeek==1){    
                    lastcnt = 0;    
                }else if(nextWeek==2){    
                    lastcnt = 1;    
                }else if(nextWeek==3){ 
                    lastcnt = 2;    
                }else if(nextWeek==4){    
                    lastcnt = 3;    
                }else if(nextWeek==5){    
                    lastcnt = 4;    
                }else if(nextWeek==6){    
                    lastcnt = 5;    
                }    
                if(weeks==1){//第1周特殊处理    // 为日期对象 date 重新设置成时间 time
                    var start = date.Format("M");
                    // date.setTime(time-24*3600000);
                    // return start + "-----" + date;
                    return start;
                }else if(weeks==53){//第53周特殊处理  
                    //第53周开始时间      
                    var start = time+(weeks-2)*7*24*3600000; 
                    //第53周结束时间
                    // var end = time+(weeks-2)*7*24*3600000 + lastcnt*24*3600000 - 24*3600000;
                    date.setTime(start);    
                    var _start = date.Format("M"); 
                    // date.setTime(end);    
                    // var _end = date.Format("MM"); 
                    // return _start + "---"  + _end;
                    return _star;
                }else{    
                    var start = time+(weeks-2)*7*24*3600000; //第n周开始时间    
                    // var end = time+(weeks-1)*7*24*3600000 - 24*3600000; //第n周结束时间
                    date.setTime(start);    
                    var _start = date.Format("M");     
                    // date.setTime(end);    
                    // var _end = date.Format("MM");
                    // return _start + "---"  + _end;
                    return _start;
                } 
            }else{//一年54周情况    
                var cnt=0;// 获取距离周末的天数    
                if(_week==0 && weeks==1){//第一周    
                    cnt = 0;    
                }else if(_week==0){    
                    cnt = 7;    
                }else if(_week==1){    
                    cnt = 6;    
                }else if(_week==2){    
                    cnt = 5;    
                }else if(_week==3){    
                    cnt = 4;    
                }else if(_week==4){    
                    cnt = 3;    
                }else if(_week==5){    
                    cnt = 2;    
                }else if(_week==6){    
                    cnt = 1;    
                }    
                cnt += 1;//加1表示以星期一为一周的第一天    
                // 将这个长整形时间加上第N周的时间偏移    
                time += 24*3600000; //第2周开始时间    
                var nextYear = new Date(parseInt(year,10)+1,"0","1");    
                var nextWeek = nextYear.getDay();    
                var lastcnt = 0;//获取最后一周开始时间到周末的天数    
                if(nextWeek==0){    
                    lastcnt = 6;    
                }else if(nextWeek==1){    
                    lastcnt = 0;    
                }else if(nextWeek==2){ 
                    lastcnt = 1;    
                }else if(nextWeek==3){    
                    lastcnt = 2;    
                }else if(nextWeek==4){    
                    lastcnt = 3;    
                }else if(nextWeek==5){    
                    lastcnt = 4;    
                }else if(nextWeek==6){    
                    lastcnt = 5;    
                }    
                if(weeks==1){//第1周特殊处理
                    var start = date.Format("M"); 
                    // date.setTime(time-24*3600000);
                    // return _start + "---"  + date;
                    return _start;
                }else if(weeks==54){//第54周特殊处理   
                    //第54周开始时间    
                    var start = time+(weeks-2)*7*24*3600000; 
                    //第53周结束时间 
                    // var end = time+(weeks-2)*7*24*3600000 + lastcnt*24*3600000 - 24*3600000; 
                    date.setTime(start);    
                    var _start = date.Format("M"); 
                    // date.setTime(end);    
                    // var _end = date.Format("yyyy-MM-dd");
                    // return _start + "---"  + _end;
                    return _start;
                }else{    
                    var start = time+(weeks-2)*7*24*3600000; //第n周开始时间    
                    // var end = time+(weeks-1)*7*24*3600000 - 24*3600000; //第n周结束时间
                    date.setTime(start);    
                    var _start = date.Format("M"); 
                    // date.setTime(end);    
                    // var _end = date.Format("yyyy-MM-dd");
                    // return _start + "---"  + _end;
                    return _start;
                }    
            }
        }
    }
}
</script>
<style scoped>
    .displayBox{
        display: flex;
        justify-items: center;
        justify-content: center;
        align-items: center;
        width:100%;
        height: 94%;
    }
.containers {
        width: 100%;
        height: 100%;
        padding: .25rem 1.25rem;
        box-sizing: border-box;
    }
    .container-main {
        width: 100%;
        height: 100%;
        background: url('../../assets/images/analysis.png') no-repeat;
        background-size: 100% 100%;
    }
    .container_bg {
        width: 100%;
        height: 100%;
        padding: .375rem 1.5rem;
        box-sizing: border-box;
    }
    .title {
        display: flex;
        justify-content:space-between;
        align-items:center;
        font-size: .4rem;
        font-weight: 500;
        color: #fff;
        position: relative;
        /* padding-left: 1.25rem; */
        /* padding-right: 1.25rem; */
    }
    /* .login img {
        width: .7375rem;
        height: .7375rem;
        vertical-align: middle;
    } */
    .back {
        width: .4625rem;
        height: .375rem;
    }
    .main {
        width: 100%;
        height:100%;
        margin-top: .375rem;
    }
    .main-top{
        height: 5.3875rem;
        width: 100%;
        background: url('../../assets/images/analysis_topbg.png') no-repeat;
        background-size: 100% 100%;
    }
    #line1 {
        height: 5rem;
    }
    #pie1 {
        height: 5rem;
    }
    #line2 {
        height: 5rem;
    }
    #pie2 {
        height: 5rem;
    }
    .main-line {
        position: relative;
        padding: .25rem .625rem;
    }
    .main-pie{
        padding: .25rem .625rem;
    }
    .linetime {
        z-index: 10000;
        position: absolute;
        top: .475rem;
        left: 15%;
        width: 40%;
        background: rgba(250, 250, 250, 0.1);
        border: none;
    }
    /deep/ .el-range-editor--mini .el-range-input {
        background: rgba(250, 250, 250, 0);
        color: #fff;
    }
    /deep/ .el-range-editor--mini .el-range-separator {
        color: #fff;
    }
    .main-bottom {
        height: 5.3875rem;
        width: 100%;
        background: url('../../assets/images/analysis_bottombg.png') no-repeat;
        background-size: 100% 100%;
    }

    .linetime1 {
        z-index: 10000;
        width: 47%;
        border: none;
        position: absolute;
        left: 10%;
        top: 8%;
    }
    /deep/ .el-date-editor .el-input__inner {
       background: rgba(250, 250, 250, 0.1);
        color: #fff;
        border: 0;
        text-align: center;
    }
    /deep/ .el-date-editor.el-input{
        width: 100%;
    }
    .logo_img {
        width: 2.5rem;
        height: .875rem;
        vertical-align: middle;
        text-align: left;
        display: inline-block;
    }
    .main-top1 {
        height: 5.3875rem;
        width: 100%;
        background: url('../../assets/images/analysis_bottombg.png') no-repeat;
        background-size: 100% 100%;
    }
    .main_center {
        padding: .5625rem 1.0625rem;
        text-align: center;
        margin: 0  auto;
        position: relative;
    }
    /deep/ .el-table td.el-table__cell {
        border-bottom: 0;
        color: #fff;
        background-color: #091c38;
    }
    /deep/ .el-table::before {
        width: 0;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td{
        background-color: #235dc8 !important;
    }
    /deep/ .el-pagination button:disabled {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination button {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager .number{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager li.active{
        color: #409EFF !important;
    }
    /deep/ .el-table__empty-block {
        background: #091c38;
    }
    /deep/ .el-table__body-wrapper {
        background: #091c38;
    }
    /deep/ .el-table__header-wrapper {
        background: #091c38;
    }
    /deep/ .el-pager li.btn-quicknext {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pager li.btn-quickprev{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    .main-bottom_center {
        padding: .375rem 1.0625rem;
        text-align: center;
        margin: 0  auto;
    }
    #takeawayColumn {
        height: 5rem;
    }
</style>