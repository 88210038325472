<template>
    <div class="main2">
        <div class="rank_title2">
            <el-row>
                <el-col :span="12"  class="rank_title_left2">{{progressData.title}}</el-col>
                <el-col :span="11"  class="rank_title_right2">{{progressData.title1}}</el-col>
            </el-row>
        </div>
        
        <div class="rank_main2">
            <el-row v-for="(item,index) in progressData.list" :key="index"  class="rank_main_list">
                <el-col :span="15" class="rank_main_top_left">{{htmlDecodeByRegExp(item.dishesName)}}</el-col>
                <el-col :span="9" class="rank_main_top_right">
                    <span class="rank_main_top_right_value">
                        <countTo :startVal='0' :endVal='item.sales?item.sales:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                    </span>
                    <span v-if="index < 3" class="img">
                        <img src="../../assets/images/greenup.png" alt="" v-if="item.falge == 'up'" class="rank_img">
                        <img src="../../assets/images/reddown.png" alt="" v-if="item.falge == 'down'" class="rank_img">
                        <img src="../../assets/images/balance.png" alt="" v-if="item.falge == 'balance'" class="rank_img">
                    </span>
                    <div v-else class="img">
                        <span class="hiddenimg"></span>
                    </div>
                </el-col>
                <el-col class="rank_main_bottom" :span="22">
                    <el-progress :percentage="item.sales/(progressData.list[0].sales+20)*100" :show-text="false" style="width:100%;"></el-progress>
                </el-col>
            </el-row>
            <div class="top2"></div>
            <div class="bottom2"></div>
        </div>
    </div>
</template>
<script>
import countTo from 'vue-count-to';
export default {
    components: { countTo },
    props:{
        progressData:{
            type:Object,
            default() {
                return {}
            }
        } 
    },
    watch:{
        'progressData':{
            handler: function (newValue, oldValue) {
               this.progressData = newValue;
            },
            deep: true
        }
    },
    data() {
        return {
            
        }
    },
    created() {
    },
    methods:{
        htmlDecodeByRegExp(str) {
            var s = "";
            if (str.length == 0) return "";
            s = str.replace(/&amp;/g, " ");
            s = s.replace(/&lt;/g, "<");
            s = s.replace(/&gt;/g, ">");
            s = s.replace(/&nbsp;/g, " ");
            s = s.replace(/'/g, "\'");
            s = s.replace(/"/g, "\"");
            return s;
        }
    }
}
</script>
<style scoped>
    .rank_main2{
        padding: 0.42rem 0.225rem 0.325rem 0.325rem !important;
    }
    .main {
        position: relative;
        width: 100%;
        padding: 0.25rem .1125rem .3625rem .225rem;
        background-color: rgba(0,0,0,0.4);
        color: #fff;
        border-radius: .1875rem;
        z-index: 10;
        box-sizing: border-box;
    }
    .rank_title{
        font-size: .25rem;
        font-weight: bold;
        text-align: left;
    }
    .rank_title_left {
        text-align: left;
    }
    .rank_title_right {
        text-align: right;
    }
    .rank_main {
        margin-top: .25rem;
    }
    .rank_main_list {
        height: .525rem;
        line-height: .4rem;
    }
    /* .rank_main_top {
        width: 100%;
    }
    .rank_main_bottom {
        width: 90%;
    } */
    .rank_main_top_left {
        text-align: left;
        font-size: .2rem;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
    }
    .rank_main_top_right {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
    }
    /* .rank_main_top_right_value {
        margin-right: 10px;
    } */
    /deep/ .el-progress-bar__outer{
        background: #182142;
    }
    /deep/ .el-progress-bar__inner {
        background: linear-gradient(90deg, #3D8FFF 0%, #00F0FF 100%);
    }
    .img {
        width: .325rem;
        height: .375rem;
        display: inline-block;
        text-align: right;
    }
    .rank_img {
        width:.1875rem;
        height: .1875rem;
    }
    .hiddenimg{
        width:.1875rem;
        height: .1875rem;
        display: inline-block;
    }
</style>