<template>
    <div class="containers">
        <div class="container-main">
            <div class="container_bg">
                <div class="title">
                    <div class="login">
                        <img src="../../assets/images/logo.png" alt="" class="logo_img">
                        <!-- <span> Pacific Foods</span> -->
                    </div>
                    <div>
                        <span> The data analysis  </span>
                    </div>
                    <div class="back" @click="backMap">
                        <img src="../../assets/images/quit.png" alt="">
                    </div>
                </div>
                <div class="main">
                    <div class="main-top">
                        <el-col :span="24" class="main-line">
                            <div id="line1"></div>
                        </el-col>
                    </div>
                    <div class="main-bottom">
                        <el-col :span="24" class="main-line">
                            <div id="line2"></div>
                        </el-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import {getBusinessGmv,getAppGmv} from '../../api/map/index'
import {getGoodsMonth6} from '../../api/business'
import {getWebuserList} from '../../api/user'
import {toThousands} from "../../utils/utils"
export default {
    data() {
        return {
            lineData1:{
                gmv:[],
                users:[],
                orders:[],
                xdata:[]
            },
            lineData2:{
                gmv:[],
                users:[],
                orders:[],
                xdata:[]
            },
            urlType:''
        }
    },
    created() {
        this.urlType = window.location.hostname;
    },
    mounted() {
        // this.getData()
        // this.getAppData()
        this.initFunction()
    },
    methods:{
        initFunction() {
            this.getGoodsMonth6()
            this.line2DataFunction()
        },
        getGoodsMonth6() {
            getGoodsMonth6({code:''}).then(res => {
                this.columnData = [];
                this.columnData = res.data.data;
                this.initLine1()
            })
        },
        line2DataFunction() {
            let that = this;
            getWebuserList({"beginDate":"2021-10","endDate":"2022-12"}).then(res => {
                let datas = JSON.parse(JSON.stringify(res.data.data));
                // datas.map(item => {
                //     this.lineData2.users.push(item.userCount)
                //     this.lineData2.xdata.push(item.mont)
                // })

                datas.map(item=> { //写的死数据
                    if(item.mont == 'Oct-21'){
                        item.waitingCustomer = 0;
                        item.existingCustomer = 91;
                        item.userCount = 91;
                    }else if(item.mont == 'Nov-21'){
                        item.waitingCustomer = 0;
                        item.existingCustomer = 188;
                        item.userCount = 188;
                    }else if(item.mont == 'Oct-22'){
                        item.waitingCustomer = 269;
                        item.existingCustomer = 237;
                        item.userCount = 506;
                    }
                    else if(item.mont == 'Nov-22') {
                        item.waitingCustomer = 273;
                        item.existingCustomer = 239;
                        item.userCount = 512;
                    }else if(item.mont == 'Dec-22') {
                        item.waitingCustomer = 290;
                        item.existingCustomer = 239;
                        item.userCount = 529;
                    }else {
                        item = item;
                    }
                    this.lineData2.users.push(item.userCount)
                    this.lineData2.xdata.push(item.mont)
                })
                setTimeout(()=> {
                    that.initLine2()
                },100)
            })
            // this.lineData2.users = [18,56,105,159,233,321]
            // this.lineData2.xdata = ['Jan-22','Feb-22','Mar-22','Apr-22','May-22','Jun-22']
            
        },
        getData() {
            getBusinessGmv().then(res => {
                let result = res.data.data;
                result.map(item => {
                    this.lineData1.gmv.push(item.gmvTotal)
                    this.lineData1.users.push(item.userTotal)
                    this.lineData1.orders.push(item.orderTotal)
                    this.lineData1.xdata.push(item.dt)
                })
                setTimeout(()=> {
                    this.initLine1()
                },1000)
            })
        },
        getAppData() {
            getAppGmv().then(res => {
                let result = res.data.data;
                if(result.orderAmountList) {
                    result.orderAmountList.map(item => {
                        this.lineData2.gmv.push(item.num*1)
                        this.lineData2.xdata.push(item.date)
                    })
                }
                if(result.orderNumList) {
                    result.orderNumList.map(item => {
                        this.lineData2.orders.push(item.num*1)
                    })
                }
                if(result.userNumList) {
                    result.userNumList.map(item => {
                        this.lineData2.users.push(item.num*1)
                    })
                }
                setTimeout(()=> {
                    this.initLine2()
                },1000)
            })
        },
        initLine1() {
            var that = this;
            this.lineData1.gmv = [];
            this.lineData1.xdata = [];
            this.columnData.map(item => {
                that.lineData1.gmv.push(item.amount)
                that.lineData1.xdata.push(item.date)
            })
            var chartFold = document.getElementById('line1');
            let  myFold  = echarts.init(chartFold);
            var option;
            option = {
                color:['#FDD401','#02E7FA','#FDAD60'],
                title: {
                    text: "To Business",
                    textStyle:{
                        color: '#fff',
                        fontSize: 15
                    },
                    top:'4%',
                    left:'1%'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    },
                    textStyle:{
                        align:'left'
                    }
                },
                legend: {
                    top: '4%',
                    right: '1%',
                    itemWidth:10,
                    itemHeight:10,
                    itemStyle:{
                        borderWidth:0,
                    },
                    textStyle:{
                        color:'#fff',
                        fontSize:15
                    },
                    icon:'rect'
                },
                grid: {
                    top:'16%',
                    left: '2%',
                    right: '2%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff',
                        }
                    },
                    axisLabel:{
                        fontSize:"0.125rem",
                    },
                    
                    data: that.lineData1.xdata
                },
                yAxis: [
                    // {
                    //     type: 'value',
                    //     name:"(each)",
                    //     axisLine:{
                    //         show:true,
                    //         lineStyle:{
                    //             type: [5, 10],
                    //             dashOffset: 5,
                    //             color:'#fff'
                    //         }
                    //     },
                    //     splitLine:{
                    //         show:false
                    //     },
                    //     nameTextStyle:{
                    //         align:'right'
                    //     },
                    //     position: 'left'
                    // },
                    {
                        type: 'value',
                        // name:"(pound)",
                        axisLine:{
                            show:true,
                            lineStyle:{
                                type: [5, 10],
                                dashOffset: 5,
                                color:'#fff'
                            }
                        },
                        splitLine:{
                            show:false
                        },
                        nameTextStyle:{
                            align:'left'
                        },
                        position: 'left',
                        axisLabel:{
                            formatter: function (value) {
                                let texts = '';
                                texts = "£"+toThousands(Math.floor(value/1000*1000));
                                return texts;
                            }
                        }
                    },
                ],
                dataZoom:{
                    type:'inside'
                },
                series: [
                    {
                        data:  that.lineData1.gmv,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2, 
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#FDD401' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#082952', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"GMV",
                        // stack: 'Total',
                        showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#FDD401' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#FDD401', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        yAxisIndex: 0,
                    },
                    // {
                    //     data: that.lineData1.users,
                    //     type: 'line',
                    //     smooth: true,
                    //     areaStyle:{
                    //         opacity:0.2,
                    //         color:{
                    //             type: 'linear',
                    //             x: 0,
                    //             y: 0,
                    //             x2: 0,
                    //             y2: 1,
                    //             colorStops: [
                    //                 {
                    //                     offset: 0,
                    //                     color: '#02E7FA' // 0% 处的颜色
                    //                 },
                    //                 {
                    //                     offset: 1,
                    //                     color: '#082952', // 0% 处的颜色,
                    //                 },
                    //             ],
                    //             global: false // 缺省为 false
                    //         }
                    //     },
                    //     name:"Total Users",
                    //     // stack: 'Total',
                    //     showSymbol: false,
                    //     lineStyle:{
                    //         color:{
                    //             type: 'linear',
                    //             x: 0,
                    //             y: 0,
                    //             x2: 0,
                    //             y2: 1,
                    //             colorStops: [
                    //                 {
                    //                     offset: 0,
                    //                     color: '#02E7FA' // 0% 处的颜色
                    //                 },
                    //                 {
                    //                     offset: 1,
                    //                     color: '#02E7FA', // 0% 处的颜色,
                    //                 },
                    //             ],
                    //             global: false // 缺省为 false
                    //         }
                    //     },
                    //     yAxisIndex: 0,
                    // },
                    // {
                    //     data:  that.lineData1.orders,
                    //     type: 'line',
                    //     smooth: true,
                    //     areaStyle:{
                    //         opacity:0.2,
                    //         color:{
                    //             type: 'linear',
                    //             x: 0,
                    //             y: 0,
                    //             x2: 0,
                    //             y2: 1,
                    //             colorStops: [
                    //                 {
                    //                     offset: 0,
                    //                     color: '#FDAD60' // 0% 处的颜色
                    //                 },
                    //                 {
                    //                     offset: 1,
                    //                     color: '#082952', // 0% 处的颜色,
                    //                 },
                    //             ],
                    //             global: false // 缺省为 false
                    //         }
                    //     },
                    //     name:"Total Orders",
                    //     // stack: 'Total',
                    //     showSymbol: false,
                    //     lineStyle:{
                    //         color:{
                    //             type: 'linear',
                    //             x: 0,
                    //             y: 0,
                    //             x2: 0,
                    //             y2: 1,
                    //             colorStops: [
                    //                 {
                    //                     offset: 0,
                    //                     color: '#FDAD60' // 0% 处的颜色
                    //                 },
                    //                 {
                    //                     offset: 1,
                    //                     color: '#FDAD60', // 0% 处的颜色,
                    //                 },
                    //             ],
                    //             global: false // 缺省为 false
                    //         }
                    //     },
                    //     yAxisIndex: 0,
                    // }
                ]
            };
            option && myFold.setOption(option);
        },
        initLine2() {
            var that = this;
            var chartFold = document.getElementById('line2');
            let  appline  = echarts.init(chartFold);
            var option;
            option = {
                color:['#FDD401','#02E7FA','#FDAD60'],
                title: {
                    text: 'To Business',
                    textStyle:{
                        color: '#fff',
                        fontSize: 15
                    },
                    top:'2%',
                    left:'1%'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    },
                    textStyle:{
                        align:'left'
                    }
                },
                legend: {
                    top: '2%',
                    right: '1%',
                    itemWidth:10,
                    itemHeight:10,
                    itemStyle:{
                        borderWidth:0,
                    },
                    textStyle:{
                        color:'#fff',
                        fontSize:15
                    },
                    icon:'rect'
                },
                grid: {
                    top:'16%',
                    left: '2%',
                    right: '2%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff',
                        }
                    },
                    axisLabel:{
                        fontSize:"0.125rem",
                    },
                    
                    data: that.lineData2.xdata
                },
                yAxis: [
                    {
                        type: 'value',
                        // name:"(each)",
                        axisLine:{
                            show:true,
                            lineStyle:{
                                type: [5, 10],
                                dashOffset: 5,
                                color:'#fff'
                            }
                        },
                        splitLine:{
                            show:false
                        },
                        nameTextStyle:{
                            align:'right'
                        },
                        position: 'left'
                    },
                    // {
                    //     type: 'value',
                    //     name:"(pound)",
                    //     axisLine:{
                    //         show:true,
                    //         lineStyle:{
                    //             type: [5, 10],
                    //             dashOffset: 5,
                    //             color:'#fff'
                    //         }
                    //     },
                    //     splitLine:{
                    //         show:false
                    //     },
                    //     nameTextStyle:{
                    //         align:'left'
                    //     },
                    //     position: 'right'
                    // },
                ],
                dataZoom:{
                    type:'inside'
                },
                series: [
                    // {
                    //     data:  that.lineData2.gmv,
                    //     type: 'line',
                    //     smooth: true,
                    //     areaStyle:{
                    //         opacity:0.2, 
                    //         color:{
                    //             type: 'linear',
                    //             x: 0,
                    //             y: 0,
                    //             x2: 0,
                    //             y2: 1,
                    //             colorStops: [
                    //                 {
                    //                     offset: 0,
                    //                     color: '#FDD401' // 0% 处的颜色
                    //                 },
                    //                 {
                    //                     offset: 1,
                    //                     color: '#082952', // 0% 处的颜色,
                    //                 },
                    //             ],
                    //             global: false // 缺省为 false
                    //         }
                    //     },
                    //     name:"Total GMV",
                    //     // stack: 'Total',
                    //     showSymbol: false,
                    //     lineStyle:{
                    //         color:{
                    //             type: 'linear',
                    //             x: 0,
                    //             y: 0,
                    //             x2: 0,
                    //             y2: 1,
                    //             colorStops: [
                    //                 {
                    //                     offset: 0,
                    //                     color: '#FDD401' // 0% 处的颜色
                    //                 },
                    //                 {
                    //                     offset: 1,
                    //                     color: '#FDD401', // 0% 处的颜色,
                    //                 },
                    //             ],
                    //             global: false // 缺省为 false
                    //         }
                    //     },
                    //     yAxisIndex: 1,
                    // },
                    {
                        data: that.lineData2.users,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2,
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#02E7FA' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#082952', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"Users",
                        // stack: 'Total',
                        showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#02E7FA' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#02E7FA', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        yAxisIndex: 0,
                    },
                    // {
                    //     data:  that.lineData2.orders,
                    //     type: 'line',
                    //     smooth: true,
                    //     areaStyle:{
                    //         opacity:0.2,
                    //         color:{
                    //             type: 'linear',
                    //             x: 0,
                    //             y: 0,
                    //             x2: 0,
                    //             y2: 1,
                    //             colorStops: [
                    //                 {
                    //                     offset: 0,
                    //                     color: '#FDAD60' // 0% 处的颜色
                    //                 },
                    //                 {
                    //                     offset: 1,
                    //                     color: '#082952', // 0% 处的颜色,
                    //                 },
                    //             ],
                    //             global: false // 缺省为 false
                    //         }
                    //     },
                    //     name:"Total Orders",
                    //     // stack: 'Total',
                    //     showSymbol: false,
                    //     lineStyle:{
                    //         color:{
                    //             type: 'linear',
                    //             x: 0,
                    //             y: 0,
                    //             x2: 0,
                    //             y2: 1,
                    //             colorStops: [
                    //                 {
                    //                     offset: 0,
                    //                     color: '#FDAD60' // 0% 处的颜色
                    //                 },
                    //                 {
                    //                     offset: 1,
                    //                     color: '#FDAD60', // 0% 处的颜色,
                    //                 },
                    //             ],
                    //             global: false // 缺省为 false
                    //         }
                    //     },
                    //     yAxisIndex: 0,
                    // }
                ]
            };
            option && appline.setOption(option);
        },
        backMap() {
           this.$store.commit('changeTotal',false)
        },
        fomartData(ary){
            let list = []
            for(let i = 0;i<ary.length;i++){
                if(i == 0) {
                    list.push(ary[i])
                }else {
                    list.push(ary[i]+list[i-1])
                }
            }
            return list;
        }
    }
}
</script>
<style scoped>
    .containers {
        width: 100%;
        height: 100%;
        padding: .25rem 1.25rem;
        box-sizing: border-box;
    }
    .container-main {
        width: 100%;
        height: 100%;
        background: url('../../assets/images/analysis.png') no-repeat;
        background-size: 100% 100%;
    }
    .container_bg {
        width: 100%;
        height: 100%;
        padding: .375rem 1.5rem;
        box-sizing: border-box;
    }
    .title {
        display: flex;
        justify-content:space-between;
        align-items:center;
        font-size: .4rem;
        font-weight: 500;
        color: #fff;
        /* padding-left: 1.25rem; */
        /* padding-right: 1.25rem; */
    }
    /* .login img {
        width: .7375rem;
        height: .7375rem;
        vertical-align: middle;
    } */
    .back {
        width: .4625rem;
        height: .375rem;
    }
    .main {
        width: 100%;
        height:100%;
        margin-top: .375rem;
    }
    .main-top{
        height: 5.3875rem;
        width: 100%;
        background: url('../../assets/images/analysis_topbg.png') no-repeat;
        background-size: 100% 100%;
    }
    #line1 {
        height: 5rem;
    }
    #pie1 {
        height: 5rem;
    }
    #line2 {
        height: 5rem;
    }
    #pie2 {
        height: 5rem;
    }
    .main-line {
        position: relative;
        padding: .25rem .625rem;
    }
    .main-pie{
        padding: .25rem .625rem;
    }
    .linetime {
        z-index: 10000;
        position: absolute;
        top: .475rem;
        left: 15%;
        width: 40%;
        background: rgba(250, 250, 250, 0.1);
        border: none;
    }
    /deep/ .el-range-editor--mini .el-range-input {
        background: rgba(250, 250, 250, 0);
        color: #fff;
    }
    /deep/ .el-range-editor--mini .el-range-separator {
        color: #fff;
    }
    .main-bottom {
        height: 5.3875rem;
        width: 100%;
        background: url('../../assets/images/analysis_bottombg.png') no-repeat;
        background-size: 100% 100%;
    }

    .linetime1 {
        z-index: 10000;
        width: 47%;
        border: none;
        position: absolute;
        left: 10%;
        top: 8%;
    }
    /deep/ .el-date-editor .el-input__inner {
       background: rgba(250, 250, 250, 0.1);
        color: #fff;
        border: 0;
        text-align: center;
    }
    /deep/ .el-date-editor.el-input{
        width: 100%;
    }
    .logo_img {
        width: 2.5rem;
        height: .875rem;
        vertical-align: middle;
        text-align: left;
        display: inline-block;
    }
</style>