

import http from '@/utils/http'
//七日
export function getSevenData(query) {
    return http({
      url: '/api/board/cost/getDayCostStatistics',
      method: 'get',
      params:query,
      headers:{
        Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
      }
    })
}


export function getFiveStoreList(query) {
  return http({
    url: '/api/board/yield/getFiveStoreList',
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}


export function get52WeekYieldList(query) {
  return http({
    url: '/api/board/yield/get52WeekYieldList',
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}